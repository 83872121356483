import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';

export type MerchantCategoryCodeType = { available_services: OptionType[] };

export function useMerchantCategoryCode() {
  return useQuery({
    queryKey: merchantKeys.merchantCategoryCode(),
    queryFn: () =>
      Services.get<MerchantCategoryCodeType>(`merchants/mcc_list/`, true),
  });
}
