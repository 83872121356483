import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import axios from 'axios';
import { showToastError } from 'utils/showToastError';

type MerchantImageResponse = {
  id: number;
};

type MerchantImageType = {
  data: { [key: string]: File };
  merchantId: number;
};

export function useUpdateMerchantImage() {
  const queryClient = useQueryClient();

  return useMutation<MerchantImageResponse, Error, MerchantImageType>({
    mutationFn: ({ data, merchantId }) =>
      Services.patchForm<MerchantImageType['data'], MerchantImageResponse>(
        data,
        `merchants/${merchantId}/`,
        true
      ),
    onSuccess: (data) => {
      toast.success('Merchant photo has been changed');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.merchant('' + data?.id),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}
