import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import LegalDocStatus from 'constants/LegalDocStatus';

export type LegalDocumentsType = {
  id: number;
  bank_installment_attachment: string;
  accept_contract: string;
  rent_contract_or_utility_bill: string;
  signatory_national_id: string;
  national_id: string;
  company_registration_document: string;
  company_tax_card: string;
  contact_person_authority: string;
  company_contact_mobile_number: string;
  company_contact_email_address: string;
  company_name_in_arabic_and_english: string;
  company_registration_address: string;
  approved: boolean;
  approved_at: string;
  created_at: string;
  reviewer: string;
  review_status: keyof typeof LegalDocStatus;
};

type LegalDocumentsListType = {
  count: number;
  next: string;
  previous: string;
  results: LegalDocumentsType[];
};

export function useLegal(merchantId: string) {
  const query = useQuery({
    queryKey: merchantKeys.legal(merchantId),
    queryFn: () =>
      Services.get<LegalDocumentsListType>(
        `merchants/${merchantId}/legal/`,
        true
      ),
  });

  return { ...query, data: query?.data?.results! };
}
