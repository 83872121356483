const TimeLineTabs = [
  {
    name: 'General',
    link: '',
  },
  {
    name: 'SMS Report',
    link: 'sms_report',
  },
  {
    name: 'Calls Report',
    link: 'calls_report',
  },
  {
    name: 'Call Center NPS',
    link: 'call_center_nps',
  },
  {
    name: 'Visit NPS',
    link: 'visit_nps',
  },
];

export default TimeLineTabs;
