import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
export type BillsType = {
  count: number;
  results: [
    {
      id: string;
      integration_id: string;
      service_name: string;
      amount_cents: number;
      accept_fees_cents: number;
      pending: boolean;
      success: boolean;
      created_at: string;
    }
  ];
};
type args = {
  merchantId: string;
  page: string | number;
  searchText: string | number;
  status: string | number;
  date: { from?: string; to?: string };
  sort: string | number;
  selectedKey: string;
};
export function useBills(args: args) {
  const { merchantId, page, searchText, status, date, sort, selectedKey } =
    args;

  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';
  const searchKey = selectedKey === '' ? 'id' : selectedKey;

  return useQuery({
    queryKey: merchantKeys.bills(args),
    queryFn: () =>
      Services.get<BillsType>(
        `merchants/${merchantId}/bill_transactions/?page=${page}&${searchKey}=${searchText}&${status}${dateFormat}${sort}`,
        true
      ),
  });
}
