import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { MerchantDetailsType } from 'pages/main/merchants/marchent/useMerchant';
type MerchantResponse = {
  id: number;
};
type MerchantType = {
  data: MerchantDetailsType;
  merchantId: number;
};
type Error = {
  response: { data: { message: string } };
};
export function useUpdateMerchantDetails() {
  const queryClient = useQueryClient();

  return useMutation<MerchantResponse, Error, MerchantType>({
    mutationFn: ({ data, merchantId }) =>
      Services.patch(data, `merchants/${merchantId}/`, true),
    onSuccess: (data) => {
      toast.success('Merchant details has been updated');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.merchant('' + data?.id),
      });
    },
    onError: (err) => {
      const message = err?.response?.data?.message ?? 'Something went wrong';
      toast.error(message);
    },
  });
}
