import { Modal } from '@mui/material';

import ViewDocumentPages from 'components/viewDocumentPages';

const documentContainerStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  width: '70%',
};

interface IViewDocumentProps {
  openModal: boolean;
  onCloseModal: () => void;
  filePath: string;
  file: string;
  skeleton: JSX.Element;
}

const ViewDocument = ({
  openModal,
  onCloseModal,
  filePath,
  file,
  skeleton,
}: IViewDocumentProps) => {
  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="view-document-title"
      aria-describedby="view-document-description"
    >
      <ViewDocumentPages
        filePath={filePath}
        file={file ?? ''}
        skeleton={skeleton}
        documentContainerStyle={documentContainerStyle}
      />
    </Modal>
  );
};

export default ViewDocument;
