import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { styled } from '@mui/system';

import Text from 'components/text';
import Button from 'components/button/Button';
import Select from 'components/input/Select';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import {
  useBanks,
  useCreateIntegrationRequest,
  useMerchantTypes,
  useOptions,
  usePlatforms,
  useRates,
  useRiskPrevention,
  useServices,
} from './useNewIntegrationRequest';

const BpIcon = styled('span')({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: '1px solid #DEDEDE;',
});

const BpCheckedIcon = styled(BpIcon)({
  border: '1px solid #1782FB;',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23137cbd'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const integrationRequestSchemaValidation = z
  .object({
    type: z.string().min(1, { message: 'Merchant type is required' }),
    integration_service: z
      .number()
      .min(1, { message: 'Integration service is required' }),
    bank: z.number().min(1, { message: 'Bank is required' }),
    platform: z.string().optional().or(z.literal('')),
    integration_option: z.number().optional().or(z.literal('')),
    risk_prevention: z.number().optional().or(z.literal('')),
    is_psp: z.boolean().optional(),
    rate: z.number().optional().or(z.literal('')),
    status: z.string().min(1, { message: 'Status is required' }),
  })
  .refine((data) => data.is_psp || data.rate, {
    message: 'Please either select a rate or check Is PSP',
    path: ['rate_isPSP'],
  });

export type IntegrationRequestSchemaFormType = z.infer<
  typeof integrationRequestSchemaValidation
>;

const NewIntegrationRequestDetails = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const { mutate: createRequest, isLoading: isCreatingRequest } =
    useCreateIntegrationRequest();
  const { data: { merchant_types = [] } = {} } = useMerchantTypes();
  const { data: { available_services = [] } = {} } = useServices();
  const { data: { available_banks = [] } = {} } = useBanks();
  const { data: { platforms = [] } = {} } = usePlatforms();
  const { data: { integration_options = [] } = {} } = useOptions();
  const { data: { available_risk_preventions = [] } = {} } =
    useRiskPrevention();
  const { data: { available_rates = [] } = {} } = useRates();
  const STATUSES = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Approved', value: 'APPROVED' },
  ];

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<IntegrationRequestSchemaFormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(integrationRequestSchemaValidation),
  });

  const displayTooltip = watch([
    'type',
    'integration_service',
    'bank',
    'status',
  ]).every((field) => field !== undefined);

  const handleCancel = () => {
    reset();
    navigate('/underwriting/requests/pending');
  };

  const handleForm: SubmitHandler<IntegrationRequestSchemaFormType> = (
    values
  ) => {
    const data = Object.entries(values).reduce<any>((acc, [key, value]) => {
      if (value !== '' && value !== undefined) acc[key] = value;
      return acc;
    }, {}) as IntegrationRequestSchemaFormType;

    createRequest(
      { ...data, merchant_id: +merchantId! },
      {
        onSuccess: () => {
          reset();
          navigate('/underwriting/requests/pending');
        },
      }
    );
  };

  return (
    <div className={styles.details}>
      <header>
        <Text text="New Integration Request" />
      </header>
      <form noValidate onSubmit={handleSubmit(handleForm)}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={4}
          alignItems="center"
          sx={{ py: 2, px: 4, '& .MuiFormControl-root': { width: '100%' } }}
        >
          <Grid item xs={12} md={6}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label="Select merchant type"
                  data={merchant_types}
                  placeholder="type"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="integration_service"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label="Select integration service"
                  data={available_services}
                  placeholder="service"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="bank"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label="Select bank"
                  data={available_banks}
                  placeholder="bank"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label="Select status"
                  data={STATUSES}
                  placeholder="status"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="platform"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Text text="Select platform" fs={10} />
                      <Text
                        text="(optional)"
                        fs={10}
                        sx={{ color: 'var(--clr-gray)' }}
                      />
                    </Box>
                  }
                  data={[{ label: 'N/A', value: '' }, ...platforms]}
                  placeholder="platform"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="integration_option"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Text text="Select integration option" fs={10} />
                      <Text
                        text="(optional)"
                        fs={10}
                        sx={{ color: 'var(--clr-gray)' }}
                      />
                    </Box>
                  }
                  data={[{ label: 'N/A', value: '' }, ...integration_options]}
                  placeholder="option"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="risk_prevention"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Text text="Select risk prevention" fs={10} />
                      <Text
                        text="(optional)"
                        fs={10}
                        sx={{ color: 'var(--clr-gray)' }}
                      />
                    </Box>
                  }
                  data={[
                    { label: 'N/A', value: '' },
                    ...available_risk_preventions,
                  ]}
                  placeholder="risk prevention"
                  have_error={error?.message ?? ''}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="rate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  label="Select rate"
                  data={[{ label: 'N/A', value: '' }, ...available_rates]}
                  placeholder="rate"
                  have_error={error?.message ?? ''}
                  {...field}
                  sx={{ minWidth: 'unset' }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              label="Is PSP"
              control={
                <Controller
                  name="is_psp"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      sx={{ '&:hover': { bgcolor: 'transparent' } }}
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
              }
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="space-around"
            xs={12}
            sx={{ my: 2 }}
          >
            <Button
              title={
                displayTooltip && !isValid
                  ? 'Please either select a rate or check Is PSP'
                  : !isValid
                  ? 'Please select required inputs'
                  : ''
              }
              text="Create request"
              disabled={isCreatingRequest || !isValid}
            />
            <Button
              type="button"
              onClick={handleCancel}
              text="Cancel"
              style={{
                backgroundColor: '#fff',
                color: 'var(--clr-red)',
                fontSize: 12,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default NewIntegrationRequestDetails;
