import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Skeleton } from '@mui/material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Text from 'components/text';
import { Result, useIntegrationRequests } from './useIntegrationRequests';
import { useIntegrationRequestDetails } from './useIntegrationRequests';

const IntegrationRequestsKey = 'IntegrationRequests';

interface IIntegrationRequestsProps {
  expanded: string | false;
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
  selectedContent: { key: string; id?: string };
}

const IntegrationRequests = ({
  expanded,
  onExpanded,
  onSelectedContent,
  selectedContent,
}: IIntegrationRequestsProps) => {
  const { merchantId, requestId } = useParams();
  const { data: selectedRequest, isFetching: isFetchingSelectedRequest } =
    useIntegrationRequestDetails(requestId);
  const {
    data: requests,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useIntegrationRequests({
    merchantId,
    statuses: ['UPDATED', 'PENDING'],
    enable: !isFetchingSelectedRequest,
  });
  const isExpanded = expanded === IntegrationRequestsKey;

  let content: Result[] = [];

  if (requests?.pages?.[0]?.results?.length) {
    selectedRequest && content.push(selectedRequest);

    for (const page of requests.pages) {
      content = [
        ...content,
        ...page.results.filter((request) => request.id !== selectedRequest?.id),
      ];
    }
  }

  let loadMoreText = '';
  if (isFetchingNextPage) loadMoreText = 'Loading more...';
  else if (hasNextPage) loadMoreText = 'Load More';

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onExpanded(IntegrationRequestsKey)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`IntegrationRequests-content`}
        id={`IntegrationRequests-header`}
        sx={{ py: 1, px: 3, backgroundColor: isExpanded ? '#fff' : '' }}
      >
        <Text text="Requested Services" />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, maxHeight: 230, overflow: 'auto' }}>
        {isFetching && (
          <Box sx={{ height: 165 }}>
            {[1, 2, 3].map((val) => (
              <Skeleton
                key={val}
                sx={{ mb: 1 }}
                variant="rectangular"
                width="100%"
                height={50}
              />
            ))}
          </Box>
        )}
        {!isFetching &&
          content?.map((request) => (
            <Box
              key={request.id}
              onClick={() =>
                onSelectedContent({
                  key: IntegrationRequestsKey,
                  id: request?.id + '',
                })
              }
              sx={{
                p: 2,
                px: 4,
                '&:hover': {
                  backgroundColor: '#FAFAFA',
                  cursor: 'pointer',
                },
                backgroundColor:
                  (selectedContent?.key !== expanded &&
                    selectedRequest?.id === request?.id) ||
                  selectedContent?.id === request?.id + ''
                    ? '#F4F9FD'
                    : '',
              }}
            >
              <Text text={`Request ${request?.id}`} fs={11} />
            </Box>
          ))}

        {loadMoreText && (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
            <Button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              <Text
                text={loadMoreText}
                component="p"
                fs={12}
                style={{ color: hasNextPage ? 'var(--clr-lightBlue)' : '' }}
              />
            </Button>
          </Box>
        )}

        {!isFetching && !content?.length && (
          <Box sx={{ p: 2, px: 4 }}>
            <Text text="No pending requested services available" fs={11} />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default IntegrationRequests;
