import React, { CSSProperties, forwardRef, Fragment, ReactNode } from 'react';
import { Box, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import Text from 'components/text';
import { useTranslation } from 'react-i18next';
import Search from 'assets/images/icons/search.svg';

export interface CustomInputProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  have_error?: string;
  placeholder?: string;
  label?: string | ReactNode;
  isSearch?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  sx?: SxProps<Theme>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Input = forwardRef<HTMLInputElement, CustomInputProps>(function Input(
  {
    onChange,
    onBlur,
    name,
    have_error,
    placeholder,
    label,
    isSearch,
    onKeyDown,
    value,
    disabled,
    defaultValue,
    style,
    type,
    sx,
  },
  ref
) {
  const { t } = useTranslation();

  return (
    <Fragment>
      {typeof label === 'string' ? <Text text={label} fs={10} /> : label}
      <TextField
        disabled={disabled}
        placeholder={t(placeholder ?? '')}
        type={type}
        ref={ref}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: isSearch && (
            <InputAdornment position="start">
              <img src={Search} alt="search icon" />
            </InputAdornment>
          ),
        }}
        sx={{
          '&.MuiFormControl-root': {
            ...style,
          },
          '& .MuiOutlinedInput-root': {
            paddingBlock: 1,
            fontSize: 'var(--fs-10)',
            minWidth: 220,
            fontFamily: 'var(--ff-GBook)',
            border: '1px solid #DEDEDE',
            borderRadius: 1,
            height: 42,
          },
          '@media(max-width:600px)': {
            width: '100%',
          },
          ...sx,
        }}
      />
      <Box sx={{ fontSize: 12, color: 'red', p: 0.5, pb: 0 }}>
        {have_error || '\u00A0'}
      </Box>
    </Fragment>
  );
});

export default Input;
