import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEditMerchantStatus } from './useEditMerchantStatus';
import { toast } from 'react-hot-toast';
import { ChangeEvent, MouseEvent } from 'react';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 30,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#4BD595',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#4BD595',
        width: 25,
        height: 25,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#EA6060',
    width: 25,
    height: 25,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#EBC3C3',
    borderRadius: 20 / 2,
  },
}));
interface MerchantStatusProps {
  isLive: boolean;
  isActive?: boolean;
  merchantId: number;
}

export default function MerchantStatus({
  isLive,
  isActive,
  merchantId,
}: MerchantStatusProps) {
  const { t } = useTranslation();
  const { mutate: editMerchantStatus } = useEditMerchantStatus();

  const liveStatus = isLive ? 'Live' : 'NOT LIVE';
  const activeStatus = isActive ? 'Active' : 'BLOCKED';

  const handleToggleIsLive = (e: ChangeEvent<HTMLInputElement>) => {
    editMerchantStatus(
      {
        merchantId,
        is_live: e.target.checked,
      },
      {
        onSuccess: () => {
          toast.success(
            e.target.checked ? 'Merchant is not live' : 'Merchant is live'
          );
        },
      }
    );
  };

  const handleUnblockMerchant = (e: MouseEvent<HTMLElement>) => {
    editMerchantStatus(
      {
        merchantId,
        active: true,
      },
      {
        onSuccess: () => toast.success('Merchant is activated'),
      }
    );
  };

  return (
    <Box sx={{ display: 'flex', gap: 6, alignItems: 'center' }}>
      <FormControl component="fieldset" variant="standard">
        <FormControlLabel
          control={
            <MaterialUISwitch
              onChange={handleToggleIsLive}
              checked={!!isLive}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t(liveStatus)}
          labelPlacement="start"
        />
      </FormControl>
      {isActive !== undefined && !isActive && (
        <Tooltip
          title={isActive ? 'Merchant is Activated' : 'Unblock Merchant'}
        >
          <span>
            <Button
              disabled={isActive}
              onClick={handleUnblockMerchant}
              color="error"
            >
              {activeStatus}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );
}
