import { axiosPrivate } from 'apis/AxiosConfig';

type FormType = { [key: string]: File | string };

export const Services = {
  get: async <T>(path: string, isAuth = false): Promise<T> => {
    return await axiosPrivate.get('/' + path);
  },
  set: async <T, K>(values: T, path: string, isAuth = false): Promise<K> => {
    return await axiosPrivate.post('/' + path, values);
  },
  remove: async <T>(path: string, isAuth = false): Promise<T> => {
    return await axiosPrivate.delete('/' + path);
  },
  patch: async <T, K>(values: T, path: string, isAuth = false): Promise<K> => {
    return await axiosPrivate.patch('/' + path, values);
  },
  patchForm: async <T extends FormType, K>(
    values: T,
    path: string,
    isAuth = false
  ): Promise<K> => {
    const data = new FormData();

    Object.keys(values).forEach((key) => {
      if (key === 'phones' || key === 'user') {
        data.append(key, JSON.stringify(values[key]));
      } else {
        data.append(key, values[key]);
      }
    });

    return await axiosPrivate.patch('/' + path, data);
  },
  setForm: async (values: any, path: string, isAuth = false) => {
    const data = new FormData();

    Object.keys(values).forEach((key) => {
      data.append(key, values[key]);
    });

    return await axiosPrivate.post('/' + path, data);
  },
};
