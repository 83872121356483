import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
type BillDetailsType = {
  id: number;
  order_id: number;
  amount_cents: number;
  integration_id: number;
  gateway_type: string;
  integration_type: string;
  transaction_type: string;
  transaction_status: string;
  is_refund: boolean;
  is_refunded: boolean;
  is_void: boolean;
  is_voided: boolean;
  is_auth: boolean;
  is_capture: boolean;
  is_captured: boolean;
  has_parent_transaction: boolean;
  parent_transaction?: string;
  child_transactions?: string[] | string;
  chargeback_status?: string;
  created_at: string;
  source_data: SourceData;
  installment_info: InstallmentInfo;
  accept_fees_cents: number;
  vat_cents: number;
  bank_related?: string;
  terminal?: string;
  api_source: string;
  success: boolean;
  pending: boolean;
  service_name: string;
};
type SourceData = {
  type: string;
  pan: string;
  subtype: string;
  merchant_id: number;
};
type InstallmentInfo = {
  items?: string[];
  administrative_fees?: string[];
  down_payment?: string;
  tenure?: string;
  finance_amount?: string;
};

export function useBillDetails(
  merchantId: string | number,
  transactionId: string | number
) {
  return useQuery({
    queryKey: merchantKeys.billDetails(merchantId, transactionId),
    queryFn: () =>
      Services.get<BillDetailsType>(
        `merchants/${merchantId}/bill_transactions/${transactionId}/`,
        true
      ),
  });
}
