import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { format, parseISO } from 'date-fns';

import Container from 'components/layouts/Container';
import Text from 'components/text';
import Arrow from 'assets/images/icons/left-arrow.png';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Img from 'assets/images/logo.svg';
import MerchantStatus from 'components/merchant-status/MerchantStatus';
import Documents from '../documents';
import MerchantInformation from '../merchantInformation';
import IntegrationRequests from '../integrationRequests';
import MerchantDetails from '../merchantInformation/details';
import IntegrationRequestDetails from '../integrationRequests/details';
import MerchantDocsDetails from '../documents/merchantDocs/details';
import BusinessOwnersInfoDetails from '../documents/businessOwnersInfo/details';
import { useMerchant } from 'pages/main/merchants/marchent/useMerchant';
import NewIntegrationRequest from '../newIntegrationRequest';
import NewIntegrationRequestDetails from '../newIntegrationRequest/details';
import BankDetails from '../merchantInformation/bank/details';

const contentList: { [key: string]: (id: string) => JSX.Element } = {
  MerchantDetails: () => <MerchantDetails />,
  BankDetails: () => <BankDetails />,
  IntegrationRequests: (id: string) => <IntegrationRequestDetails id={id} />,
  MerchantDocs: (id: string) => <MerchantDocsDetails id={id} />,
  BusinessOwnersInfo: (id: string) => <BusinessOwnersInfoDetails id={id} />,
  NewIntegrationRequest: () => <NewIntegrationRequestDetails />,
};

const RequestDetails = () => {
  const location = useLocation();
  const { merchantId } = useParams();
  const { data: merchant } = useMerchant(merchantId!);
  const [expanded, setExpanded] = useState<string | false>(
    'MerchantInformation'
  );
  const [selectedContent, setSelectedContent] = useState<{
    key: string;
    id?: string;
  }>({
    key: 'MerchantDetails',
  });

  const content = contentList[selectedContent.key](selectedContent.id || '');

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleContentChange = (content: { key: string; id?: string }) => {
    setSelectedContent(content);
  };

  return (
    <div id={styles.request_Details}>
      <div className={styles.header}>
        <Container>
          <header>
            <Link to={location?.state?.from || '../'}>
              <img
                src={Arrow}
                alt="arrow icon"
                className="details_arrow"
                style={{ width: '14px', height: '14px' }}
              />
            </Link>

            <img src={Img} alt="profile page" className="profile_img" />
            <div style={{ marginInlineEnd: 35 }}>
              <Text text={merchant?.full_name ?? '-'} component="h3" fs={14} />
              <Text
                text="Joined"
                variables={{
                  0: merchant?.created_at
                    ? format(parseISO(merchant?.created_at), 'yyyy-MM-dd')
                    : '-',
                }}
                fs={10}
              />
            </div>

            <MerchantStatus
              isLive={merchant?.is_live!}
              isActive={merchant?.active!}
              merchantId={merchant?.id!}
            />
          </header>
        </Container>
      </div>

      <Container>
        <Box sx={{ pt: 4, flexGrow: 1, minHeight: 'calc(100vh - 230px)' }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <MerchantInformation
                expanded={expanded}
                onExpanded={handleAccordionChange}
                onSelectedContent={handleContentChange}
                selectedContent={selectedContent}
              />
              <Documents
                expanded={expanded}
                onExpanded={handleAccordionChange}
                onSelectedContent={handleContentChange}
                selectedContent={selectedContent}
              />
              <IntegrationRequests
                expanded={expanded}
                onExpanded={handleAccordionChange}
                onSelectedContent={handleContentChange}
                selectedContent={selectedContent}
              />
              <NewIntegrationRequest
                onExpanded={handleAccordionChange}
                onSelectedContent={handleContentChange}
              />
            </Grid>
            <Grid item xs={8}>
              {content}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default RequestDetails;
