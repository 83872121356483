import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

export type VisitsResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: VisitType[];
};

type VisitType = {
  id: number;
  visit_reason: string;
  visit_type: string;
  agent: string;
  agent_name?: string;
  merchant: number;
  merchant_phones: string[];
  terminal: number;
  notes: string;
  latitude: string;
  longitude: string;
  freshdesk_id: string;
  created_at: string;
  updated_at: string;
};

interface IUseVisits {
  merchantId?: string;
  page: number;
  searchText: string;
  status: string;
  date: { from?: string; to?: string };
  sort: string;
  selectedKey: string;
}

export function useVisits(args: IUseVisits) {
  const { merchantId, page, searchText, status, date, sort, selectedKey } =
    args;

  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';
  const searchKey = selectedKey === '' ? 'id' : selectedKey;

  const { isLoading, data, error, isError, isFetching } = useQuery({
    queryKey: merchantKeys.visits(args),
    queryFn: () =>
      Services.get<VisitsResponse>(
        `merchants/${merchantId}/visits/?page=${page}&${searchKey}=${searchText}&live=${status}&${dateFormat}${sort}`,
        true
      ),
  });

  return { isLoading, data, error, isError, isFetching };
}
