const TransfersStatus = {
  0: "Pending",
  1: "Successful",
  2: "Denied by Bank",
  3: "Rejected for Account number not valid",
  4: "Rejected for a closed account",
  5: "Rejected for account not valid",
  6: "Rejected for account number error",
  7: "Rejected for invalid credit instruction",
};

export default TransfersStatus;
