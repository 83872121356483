import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import Text from 'components/text';
import MerchantDocsFiles from './MerchantDocsFiles';
import MerchantDrawer from '../MerchantDrawer';
import DocumentDetails from './DocumentDetails';
import {
  IBusinessOwnerDetailsResponse,
  useBusinessOwners,
} from 'pages/main/underwriting/documents/businessOwnersInfo/useBusinessOwner';
import {
  IDocumentDetailsResponse,
  useDocuments,
} from 'pages/main/underwriting/documents/merchantDocs/useDocuments';
import { useParams } from 'react-router-dom';

export type documentType = IBusinessOwnerDetailsResponse &
  IDocumentDetailsResponse & { fileKey: string };

const initialDocuments = {
  'National ID': {
    fileKey: 'signatory_national_id',
    document_label: 'National ID',
  } as documentType,
  'Company Registration Document': {
    fileKey: 'company_registration_document',
    document_label: 'Company Registration Document',
  } as documentType,
  'Bank Installment Attachment': {
    fileKey: 'bank_installment_attachment',
    document_label: 'Bank Installment Attachment',
  } as documentType,
  'Accept Contract': {
    fileKey: 'accept_contract',
    document_label: 'Accept Contract',
  } as documentType,
  'Rent Contract or Utility Bill': {
    fileKey: 'rent_contract_or_utility_bill',
    document_label: 'Rent Contract or Utility Bill',
  } as documentType,
  'Company Tax Card': {
    fileKey: 'company_tax_card',
    document_label: 'Company Tax Card',
  } as documentType,
};

const MerchantDocuments = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showDocDetails, setShowDocDetails] = useState(false);
  const { merchantId } = useParams();
  const { data: businessOwners, isFetching: isFetchingBusinessOwner } =
    useBusinessOwners({
      merchantId,
      filters: 'not_primary=False',
    });
  const { data: merchantDocs, isFetching: isFetchingDocuments } =
    useDocuments(merchantId);
  const [currentDocument, setCurrentDocument] = useState<documentType | null>(
    null
  );
  const [viewDocument, setViewDocument] = useState<string | null>(null);
  const [documents, setDocuments] = useState<{
    [key: string]: documentType;
  }>(initialDocuments);

  useEffect(() => {
    if (isDrawerOpen) return;
    setShowDocDetails(false);
  }, [isDrawerOpen]);

  useEffect(() => {
    setCurrentDocument(viewDocument ? documents[viewDocument] : null);
  }, [viewDocument, documents]);

  useEffect(() => {
    if (isFetchingBusinessOwner || isFetchingDocuments) return;

    setDocuments(initialDocuments);

    if (businessOwners?.results?.[0]) {
      const document = {
        ...businessOwners.results[0],
        document_label: 'National ID',
        document_file: businessOwners.results[0].signatory_national_id,
      } as documentType;

      setDocuments((documents) => ({
        ...documents,
        [document.document_label]: {
          ...documents[document.document_label],
          ...document,
        },
      }));
    }

    merchantDocs?.results?.forEach((merchantDoc) => {
      setDocuments((documents) => ({
        ...documents,
        [merchantDoc.document_label]: {
          ...documents[merchantDoc.document_label],
          ...merchantDoc,
        },
      }));
    });
  }, [
    isFetchingBusinessOwner,
    isFetchingDocuments,
    businessOwners?.results,
    merchantDocs?.results,
  ]);

  return (
    <MerchantDrawer
      title="Edit merchant documents"
      subtitle="Edit documents"
      setDrawerOpen={setDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <section className="content">
        <Box sx={{ p: 1, pt: 0 }}>
          {showDocDetails ? (
            <DocumentDetails
              goBack={setShowDocDetails}
              document={currentDocument}
            />
          ) : (
            <Box>
              <header>
                <Text text="Documents files" fs={12} />
              </header>
              <MerchantDocsFiles
                setShowDocDetails={setShowDocDetails}
                viewDocument={setViewDocument}
                documents={documents}
                isFetching={isFetchingBusinessOwner || isFetchingDocuments}
              />
            </Box>
          )}
        </Box>
      </section>
    </MerchantDrawer>
  );
};

export default MerchantDocuments;
