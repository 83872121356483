import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { TransactionsType } from '../transactions/useTransactions';

type TransferTransactionsResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: TransactionsType[];
};

interface IUseTransferTransactions {
  merchantId?: string;
  page: number;
  transferId?: string;
}

export function useTransferTransactions({
  merchantId,
  page,
  transferId,
}: IUseTransferTransactions) {
  return useQuery({
    queryKey: merchantKeys.transferTransactions(merchantId, page, transferId),
    queryFn: () =>
      Services.get<TransferTransactionsResponse>(
        `merchants/${merchantId}/transactions/?page=${page}&transfer=${transferId}`,
        true
      ),
    // Services.get(
    //   `merchants/${merchantId}/transfers?id=${id}`,
    //   true
    // )
  });
}
