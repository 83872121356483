import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Services } from 'apis/services/Services';
import { showToastError } from 'utils/showToastError';
import { PhoneSchemaType as UpdatePhoneNumbeRequestType } from './PhoneNumberForm';

export function useUpdatePhoneNumber(merchantId: string) {
  const { mutate: updatePhoneNumber, isLoading } = useMutation<
    unknown,
    Error,
    UpdatePhoneNumbeRequestType
  >({
    mutationFn: ({ phone_number }) =>
      Services.patch<UpdatePhoneNumbeRequestType, unknown>(
        { phone_number },
        `merchants/${merchantId}/phone_number/request_change/`,
        true
      ),
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });

  return { isLoading, updatePhoneNumber };
}
