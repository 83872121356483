export const merchantKeys = {
  merchants: (args) => ['merchants', args],
  merchant: (merchantId) => ['merchant', merchantId],
  merchantLogo: (merchantId, filePath) => [
    ...merchantKeys.merchant(merchantId),
    'merchantLogo',
    filePath,
  ],
  balance: (merchantId) => ['balance', merchantId],
  legal: (merchantId) => ['legal', merchantId],
  generateAchPdf: (merchantId) => ['generateAchPdf', merchantId],
  contactPersonAuthorities: () => ['contactPersonAuthorities'],
  salesOwner: () => ['salesOwner'],
  accountManagers: () => ['accountManagers'],
  acceptBalanceDetails: () => ['acceptBalanceDetails'],
  governorates: () => ['governorates'],
  cities: (gov) => ['cities', gov],
  neighborhoods: (city) => ['neighborhoods', city],
  merchantCategoryCode: () => ['merchantCategoryCode'],
  salesAgentMCC: () => ['salesAgentMCC'],
  activities: (args) => ['activities', args],
  smsCommunication: (args) => ['smsCommunication', args],
  smsCommunicationRecords: (merchantId, status, dateFormat) => [
    'smsCommunicationRecords',
    merchantId,
    status,
    dateFormat,
  ],
  callsReport: (args) => ['callsReport', args],
  callCenterNPS: (args) => ['callCenterNPS', args],
  visitNPS: (args) => ['visitNPS', args],
  services: (merchantId) => ['services', merchantId],
  bank: () => ['bank'],
  banks: (args) => [...merchantKeys.bank(), 'banks', args],
  banksStatuses: () => ['banksStatuses'],
  banksCurrencies: () => ['banksCurrencies'],
  pos: (args) => ['pos', args],
  posContract: (merchantId) => ['posContract', merchantId],
  posDetails: (merchantId, terminalId) => [
    'posDetails',
    merchantId,
    terminalId,
  ],
  posVersions: (merchantId) => ['posVersions', merchantId],
  onlineMethods: (merchantId, category, page) => [
    'onlineMethods',
    merchantId,
    category,
    page,
  ],
  transactions: (args) => ['transactions', args],
  transactionsGatewayTypes: (merchantId) => [
    'transactionsGatewayTypes',
    merchantId,
  ],
  transactionsChargebackStatuses: (merchantId) => [
    'transactionsChargebackStatuses',
    merchantId,
  ],
  transactionsReport: (merchantId, status, dateFormat) => [
    'transactionReport',
    merchantId,
    status,
    dateFormat,
  ],
  transactionDetails: (merchantId, transactionId) => [
    'transactionDetails',
    merchantId,
    transactionId,
  ],
  bills: (args) => ['bills', args],
  billDetails: (merchantId, transactionId) => [
    'billDetails',
    merchantId,
    transactionId,
  ],
  transfers: (args) => ['transfers', args],
  transferTransactions: (merchantId, page, transferId) => [
    'transferTransactions',
    merchantId,
    page,
    transferId,
  ],
  transferDetails: (merchantId, transferId) => [
    'transferDetails',
    merchantId,
    transferId,
  ],
  transfersRecords: (merchantId, status, dateFormat) => [
    'transfersRecords',
    merchantId,
    status,
    dateFormat,
  ],
  transfersPDF: (merchantId, transferId) => [
    'transfersPDF',
    merchantId,
    transferId,
  ],
  smsReport: (args) => ['smsReport', args],
  visits: (args) => ['visits', args],
  visitsReport: (merchantId, status, dateFormat) => [
    'visitsReport',
    merchantId,
    status,
    dateFormat,
  ],
};
export const underwritingKeys = {
  filters: (args) => ['filters', args],
  integrationRequest: () => ['integrationRequest'],
  integrationRequests: (args) => [
    ...underwritingKeys.integrationRequest(),
    'integrationRequests',
    args,
  ],
  integrationRequestDetail: () => ['integrationRequestDetail'],
  integrationRequestDetails: (requestId) => [
    ...underwritingKeys.integrationRequestDetail(),
    requestId,
  ],
  documents: (merchantId) => ['documents', merchantId],
  documentDetails: ({ merchantId, ...args }) => [
    ...underwritingKeys.documents(merchantId),
    'documentDetails',
    args,
  ],
  documentFile: (args) => ['documentFile', args],
  businessOwner: (merchantId) => ['businessOwner', merchantId],
  businessOwners: ({ merchantId, ...args }) => [
    ...underwritingKeys.businessOwner(merchantId),
    'businessOwners',
    args,
  ],
  businessOwnerDetails: ({ merchantId, ...args }) => [
    ...underwritingKeys.businessOwner(merchantId),
    'businessOwnerDetails',
    args,
  ],
  rejectionReasons: () => ['rejectionReasons'],
  integrationMerchantTypes: () => ['integrationMerchantTypes'],
  integrationBanks: () => ['integrationBanks'],
  integrationOptions: () => ['integrationOptions'],
  integrationPlatforms: () => ['integrationPlatforms'],
  integrationRates: () => ['integrationRates'],
  integrationRiskPrevention: () => ['integrationRiskPrevention'],
  integrationServices: () => ['integrationServices'],
  legalBanks: () => ['legalBanks'],
  legalBank: (args) => [...underwritingKeys.legalBanks(), args],
  legalBankList: () => ['legalBankList'],
  documentStamp: (args) => ['documentStamp', args],
};
