import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
type MerchantStatusResponse = {
  id: number;
};
type MerchantDataType = {
  merchantId: number;
  is_live?: boolean;
  active?: boolean;
};
export function useEditMerchantStatus() {
  const queryClient = useQueryClient();

  return useMutation<MerchantStatusResponse, Error, MerchantDataType>({
    mutationFn: ({ merchantId, ...data }) =>
      Services.patch<
        Omit<MerchantDataType, 'merchantId'>,
        MerchantStatusResponse
      >(data, `merchants/${merchantId}/`, true),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: merchantKeys.merchant('' + data?.id),
      });
    },
  });
}
