import React, { CSSProperties, forwardRef, Fragment, ReactNode } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Select as MuiSelect,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import Text from 'components/text';
import { OptionType } from 'components/filters/Filters';

interface SelectProps {
  data: { name: string; value: string | number }[] | OptionType[];
  placeholder: string;
  onChange?: (e: SelectChangeEvent<any>) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  name?: string;
  label?: string | ReactNode;
  value?: unknown;
  onSelectChange?: (value: unknown) => void;
  have_error?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  inputStyle?: CSSProperties;
  defaultValue?: string | number;
}

const names = ['Active', 'Not Active'];
const Select = forwardRef<unknown, SelectProps>(function Select(
  {
    onChange = () => {},
    onBlur,
    name,
    placeholder,
    label,
    value,
    data,
    onSelectChange = () => {},
    have_error,
    disabled,
    sx,
    inputStyle,
    defaultValue = null,
  },
  ref
) {
  const handleChange = (event: SelectChangeEvent<any>) => {
    const value = event?.target?.value ?? '';
    onSelectChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Fragment>
      {typeof label === 'string' ? <Text text={label} fs={10} /> : label}
      <FormControl
        sx={{
          minWidth: 220,
          '@media(max-width:600px)': {
            width: '100%',
          },
          ...sx,
        }}
      >
        <MuiSelect
          displayEmpty
          disabled={disabled}
          ref={ref}
          name={name}
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            handleChange(e);
          }}
          input={
            <OutlinedInput
              sx={{
                '& .MuiSelect-select': {
                  paddingBlock: 1,
                  border: '1px solid #DEDEDE',
                  textAlign: 'start !important',
                  ...inputStyle,
                },
              }}
            />
          }
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* @ts-ignore */}
          <MenuItem disabled value={null}>
            <em>
              <Text text={placeholder} fs={10} />
            </em>
          </MenuItem>
          {data
            ? data?.map((item) => (
                <MenuItem
                  key={'name' in item ? item.name : item.label}
                  value={item.value}
                >
                  <Text
                    text={'name' in item ? item.name : item.label}
                    fs={10}
                  />
                </MenuItem>
              ))
            : names.map((name) => (
                <MenuItem key={name} value={name}>
                  <Text text={name} fs={10} />
                </MenuItem>
              ))}
        </MuiSelect>
        <Box sx={{ fontSize: 12, color: 'red', p: 0.5, pb: 0 }}>
          {have_error || '\u00A0'}
        </Box>
      </FormControl>
    </Fragment>
  );
});

export default Select;
