import { CSSProperties, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
} from '@mui/material';
import { styled } from '@mui/system';

import Select from 'components/input/Select';
import Input from 'components/input/Input';
import Text from 'components/text';
import Button from 'components/button/Button';
import { useDimensions } from 'hooks/useDimensions';
import { useCreateActivity } from 'components/modals/newActivity/useCreateActivity';
import { removeEmptyFields } from 'utils/removeEmptyFields';

const newActivitySchemaValidation = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  tags: z.string().array().optional(),
  comment: z
    .string()
    .optional()
    .transform((val) => val?.trim()),
});

export type NewActivitySchemaType = z.infer<typeof newActivitySchemaValidation>;

const Tags = [
  'Service addition',
  'Complaint',
  'Request',
  'Information',
  'Sales related',
];

const BpIcon = styled('span')({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: '1px solid #DEDEDE;',
});

const BpCheckedIcon = styled(BpIcon)({
  border: '1px solid #1782FB;',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23137cbd'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 470,
  backgroundColor: 'var(--clr-light)',
};

const headerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--clr-mainBlue)',
  paddingInline: 33,
  paddingBlock: 18,
};

const contentStyle = {
  paddingInline: 33,
};

const tagsStyle = {
  marginBlock: 30,
};

export default function NewActivity() {
  const { merchantId } = useParams();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const down900 = useDimensions();
  const { createActivity, isCreatingActivity } = useCreateActivity();

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { isDirty, isValid, errors },
  } = useForm<NewActivitySchemaType>({
    resolver: zodResolver(newActivitySchemaValidation),
  });

  const handleForm: SubmitHandler<NewActivitySchemaType> = (data) => {
    if (!merchantId) return;

    createActivity(
      { data: removeEmptyFields(data) as NewActivitySchemaType, merchantId },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const isTagCheck = (
    values: NewActivitySchemaType['tags'],
    itemValue: string
  ) => {
    return values?.some((existingValue) => existingValue === itemValue);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'end' }}>
        <Button text="Log new activity" handleClick={() => handleOpen()} />
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="New Activity">
        <div style={{ ...style, width: down900 ? '90%' : 470 }}>
          <header style={headerStyle}>
            <Text text="Log new activity" fs={10} style={{ color: 'white' }} />
            <div onClick={handleClose}>
              <Text
                text="Cancel"
                fs={10}
                style={{ color: 'white', cursor: 'pointer' }}
              />
            </div>
          </header>
          <div style={contentStyle}>
            <form onSubmit={handleSubmit(handleForm)}>
              <div style={tagsStyle}>
                <Text text="Source" fs={10} style={{ marginBottom: 10 }} />
                <Select
                  {...register('title', {
                    required: 'Field is required',
                  })}
                  have_error={errors.title?.message}
                  placeholder="Select from list"
                  data={[
                    { name: 'Inbound', value: 'inbound' },
                    { name: 'Outbound', value: 'outbound' },
                    { name: 'Whatsapp', value: 'whatsapp' },
                  ]}
                />
              </div>

              <div style={tagsStyle}>
                <Text text="Tags" fs={10} />
                <Text
                  text="You may select multiple"
                  fs={10}
                  style={{ color: '#9F9D9D' }}
                />
                <FormControl
                  sx={{ my: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Controller
                      name="tags"
                      control={control}
                      render={({ field }) => (
                        <>
                          {Tags.map((item) => (
                            <FormControlLabel
                              key={item}
                              label={t(item)}
                              control={
                                <Checkbox
                                  sx={{
                                    '&:hover': { bgcolor: 'transparent' },
                                  }}
                                  checkedIcon={<BpCheckedIcon />}
                                  icon={<BpIcon />}
                                  value={item}
                                  checked={isTagCheck(field.value, item)}
                                  onChange={(event, checked) => {
                                    if (checked) {
                                      field.onChange([
                                        ...(field.value ?? []),
                                        event.target.value,
                                      ]);
                                    } else {
                                      field.onChange(
                                        field.value?.filter(
                                          (value) =>
                                            value !== event.target.value
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                            />
                          ))}
                        </>
                      )}
                    />
                  </FormGroup>
                </FormControl>
              </div>

              <div style={tagsStyle}>
                <Input {...register('comment')} label="Comment" />
              </div>

              <div style={{ textAlign: 'center', marginBottom: 30 }}>
                <Button
                  text="Save activity"
                  disabled={isCreatingActivity || !isDirty || !isValid}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
