import { CSSProperties, Fragment, MouseEvent, useState } from 'react';
import { Box, Button, Menu, MenuItem, Modal, Fade } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import Text from 'components/text';
import Paymob from 'assets/images/logo.svg';
import Bars from 'assets/images/icons/bars.svg';
import Close from 'assets/images/icons/close.svg';
import { useTranslation } from 'react-i18next';
import Logout from 'assets/images/icons/logout.svg';
import Merchants from 'assets/images/icons/merchant.svg';
import UnderWritting from 'assets/images/icons/underwritting.svg';
import { useAuthContext } from 'contexts/AuthContext';
import { useDimensions } from 'hooks/useDimensions';

const style: CSSProperties = {
  position: 'absolute',
  top: '0%',
  left: '0%',
  width: '100%',
  height: '100%',
  backgroundColor: 'var(--clr-light)',
};

const headerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--clr-mainBlue)',
  paddingInline: '2.5%',
  paddingBlock: 14,
};

const contentStyle: CSSProperties = {
  padding: 33,
  height: '80%',
};

export default function NavModal() {
  const { accounts } = useMsal();
  const { signOut, userToken } = useAuthContext();
  const down900 = useDimensions();

  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const oopen = Boolean(anchorEl);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => signOut();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (language: 'en' | 'ar') => {
    i18n.changeLanguage(language);
    setLanguage(language);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div
        style={{ textAlign: 'end', cursor: 'pointer' }}
        onClick={() => handleOpen()}
      >
        <img src={Bars} width={21} alt="Bars icon" />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="NavModal"
        closeAfterTransition
      >
        <Fade in={open}>
          <div style={style}>
            <header style={headerStyle}>
              <Box sx={{ '& img': { width: down900 ? '50%' : '6.250vw' } }}>
                <img src={Paymob} alt="paymob logo" />
              </Box>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <img src={Close} width={21} alt="Bars icon" />
              </div>
            </header>
            <div style={contentStyle}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {userToken && (
                    <Fragment>
                      <Text
                        text={accounts[0]?.name ?? ''}
                        fs="16"
                        sx={{ color: '#707070' }}
                      />
                      {/* <Text text="firstlast@paymob.com" component="h5" /> */}
                    </Fragment>
                  )}
                </div>
                <div>
                  <Button
                    onClick={handleClick}
                    sx={{ cursor: 'pointer' }}
                    size="small"
                  >
                    <Text text={language} component="h5" />
                  </Button>
                </div>
              </div>

              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={oopen}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => handleChangeLanguage('en')}>
                  <Text text="EN" component="h5" />
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('ar')}>
                  <Text text="AR" component="h5" />
                </MenuItem>
              </Menu>

              {userToken && (
                <Box
                  sx={{
                    paddingBlock: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      '& >': {
                        a: {
                          color: '#707070',
                          '&:hover': { color: 'var(--clr-lightBlue)' },
                        },
                      },
                    }}
                  >
                    <Link
                      to={'/'}
                      onClick={() => handleClose()}
                      style={{
                        marginBlock: 15,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={Merchants}
                        alt={'Merchants icon'}
                        style={{ marginInlineEnd: 10 }}
                      />
                      <Text text="Merchants" fs={18} />
                    </Link>
                    <Link
                      to={'/underwriting'}
                      onClick={() => handleClose()}
                      style={{
                        marginBlock: 15,
                        display: 'flex',
                      }}
                    >
                      <img
                        src={UnderWritting}
                        alt={'Underwriting icon'}
                        style={{ marginInlineEnd: 10 }}
                      />
                      <Text text="Underwriting" fs={18} />
                    </Link>
                  </Box>
                  <div
                    onClick={() => handleLogout()}
                    style={{
                      marginBlock: 15,
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src={Logout}
                      alt={'logout icon'}
                      style={{ marginInlineEnd: 10 }}
                    />
                    <Text
                      text="Logout"
                      fs={18}
                      sx={{
                        color: '#707070',
                        '&:hover': { color: 'var(--clr-lightBlue)' },
                      }}
                    />
                  </div>
                </Box>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
}
