import { CSSProperties, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Text from 'components/text';
import Button from 'components/button/Button';
import { useDimensions } from 'hooks/useDimensions';
import { useUpdatePhoneNumber } from './useUpdatePhoneNumber';
import { MuiPhoneInput } from 'components/muiPhoneInput/MuiPhoneInput';
import { useVerifyOTP } from './useVerifyOTP';
import Input from 'components/input/Input';

const phoneSchemaValidation = z.object({
  phone_number: z
    .string()
    .min(10, { message: 'Phone numbers are a minimum of 10 digits' })
    .transform((val) => val.replace(/\s/g, '')),
});

export type PhoneSchemaType = z.infer<typeof phoneSchemaValidation>;

const otpSchemaValidation = z.object({
  otp: z
    .string()
    .min(1, { message: 'OTP is required' })
    .transform((val) => val.replace(/\s/g, '')),
});

export type OTPSchemaType = z.infer<typeof otpSchemaValidation>;

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 470,
  backgroundColor: 'var(--clr-light)',
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--clr-mainBlue)',
  paddingInline: 33,
  paddingBlock: 18,
};

interface PhoneNumberFormProps {
  hasPhoneNumber: boolean;
}

export default function PhoneNumberForm({
  hasPhoneNumber,
}: PhoneNumberFormProps) {
  const { merchantId } = useParams();
  const [open, setOpen] = useState(false);
  const down900 = useDimensions();
  const [step, setStep] = useState(1);
  const { updatePhoneNumber, isLoading: isUpdating } = useUpdatePhoneNumber(
    merchantId ?? ''
  );
  const { verifyOTP, isLoading: isVerifyOTP } = useVerifyOTP(merchantId ?? '');

  const {
    reset: resetPhone,
    handleSubmit: handleSubmitPhone,
    control: controlPhone,
    formState: {
      isDirty: isPhoneDirty,
      isValid: isPhoneValid,
      errors: phoneErrors,
    },
    getValues: getPhoneValues,
  } = useForm<PhoneSchemaType>({
    resolver: zodResolver(phoneSchemaValidation),
    mode: 'onChange',
  });

  const {
    reset: resetOTP,
    handleSubmit: handleSubmitOTP,
    register: registerOTP,
    formState: { isDirty: isOTPDirty, isValid: isOTPValid, errors: otpErrors },
  } = useForm<OTPSchemaType>({
    resolver: zodResolver(otpSchemaValidation),
    mode: 'onChange',
  });

  const onPhoneNumberSubmit: SubmitHandler<PhoneSchemaType> = (data) => {
    updatePhoneNumber(
      { phone_number: data.phone_number },
      {
        onSuccess: () => {
          setStep(2);
        },
      }
    );
  };

  const onOTPSubmit: SubmitHandler<OTPSchemaType> = (data) => {
    verifyOTP(
      { otp: data.otp },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setStep(1);
    resetPhone();
    resetOTP();
    setOpen(false);
  };

  const loadingCircular = (
    <Box sx={{ paddingInlineStart: 3, marginInlineStart: -5 }}>
      <CircularProgress sx={{ color: 'white' }} size={24} />
    </Box>
  );

  return (
    <div>
      <div onClick={() => handleOpen()}>
        <Text
          text={hasPhoneNumber ? 'Change phone number' : 'Add phone number'}
          fs={10}
          className="btn"
        />
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="New Activity">
        <div style={{ ...style, width: down900 ? '90%' : 470 }}>
          <header style={headerStyle}>
            <Text text="New Phone Number" fs={10} style={{ color: 'white' }} />
            <div onClick={handleClose}>
              <Text
                text="Cancel"
                fs={10}
                style={{ color: 'white', cursor: 'pointer' }}
              />
            </div>
          </header>

          {step === 1 && (
            <form
              id="phone-form"
              onSubmit={handleSubmitPhone(onPhoneNumberSubmit)}
            >
              <Box
                sx={{ p: 5, display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <div>
                  <Text text="Enter Phone Number" fs={12} />
                  <Text
                    text="We will send OTP for the entered phone number"
                    fs={10}
                    style={{ color: '#9F9D9D' }}
                  />
                </div>
                <Controller
                  control={controlPhone}
                  name="phone_number"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <MuiPhoneInput
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                {phoneErrors.phone_number?.message && (
                  <div
                    style={{
                      fontSize: 12,
                      color: 'red',
                      marginTop: 2,
                    }}
                  >
                    {phoneErrors.phone_number?.message}
                  </div>
                )}
                <Button
                  text="Send OTP"
                  disabled={isUpdating || !isPhoneDirty || !isPhoneValid}
                  extra={isUpdating && loadingCircular}
                />
              </Box>
            </form>
          )}

          {step === 2 && (
            <form id="otp-form" onSubmit={handleSubmitOTP(onOTPSubmit)}>
              <Box
                sx={{ p: 5, display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <div>
                  <Text text="OTP Verification" fs={12} />
                  <Text
                    text="Enter the OTP sent to"
                    variables={{ 0: getPhoneValues('phone_number') }}
                    fs={10}
                    style={{ color: '#9F9D9D' }}
                  />
                </div>
                <Input
                  {...registerOTP('otp')}
                  have_error={otpErrors.otp?.message ?? ''}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 56,
                    },
                  }}
                />
                <Button
                  text="Verify OTP"
                  disabled={isVerifyOTP || !isOTPDirty || !isOTPValid}
                  extra={isVerifyOTP && loadingCircular}
                />
              </Box>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
}
