import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Container from 'components/layouts/Container';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Arrow from 'assets/images/icons/left-arrow.png';
import { usePOSDetails } from './usePOSDetails';
import { Box } from '@mui/material';
import POSVersions from 'components/modals/posVersions/POSVersions';

function ServiceDetail() {
  const { merchantId, id: terminalId } = useParams();
  const [index, setPage] = useState(0);
  const { isLoading, data } = usePOSDetails({ merchantId, terminalId });

  return (
    <div id={styles.services_Detail}>
      <Container>
        <header>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link to={`/${merchantId}/services`}>
              <img src={Arrow} alt="arrow icon" className="details_arrow" />
              <Text text="POS detail" component="h3" fs={14} />
            </Link>
            <POSVersions />
          </Box>
        </header>

        <div className={styles.details}>
          {/* <div className={styles.content}>
            <header>
              <Text text="REQUEST INFORMATION" />
            </header>
            <section>
              <div>
                <Text text="Transaction ID" className={styles.key} fs={11} />
                <Text text="-" className={styles.value} fs={12} />
              </div>
              <div>
                <Text text="Request ID" className={styles.key} fs={11} />
                <Text text="-" className={styles.value} fs={11} />
              </div>
            </section>

            <section>
              <div>
                <Text text="Integration ID" className={styles.key} fs={11} />
                <Text text="-" className={styles.value} fs={11} />
              </div>
              <div>
                <Text text="Request date" className={styles.key} fs={11} />
                <Text text="-" fs={11} />
              </div>
            </section>

            <section>
              <div>
                <Text text="Going live date" className={styles.key} fs={11} />
                <Text
                  text="-"
                  variables={{ 0: "15.00" }}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Status" className={styles.key} fs={11} />
                <Text text="SUCCESS" className="status--active" fs={8} />
              </div>
            </section>

            <section>
              <div>
                <Text text="Order ID" className={styles.key} fs={11} />
                <Text text="-" className={styles.value} fs={11} />
              </div>
            </section>

            <section>
              <div>
                <Text text="Transaction type" className={styles.key} fs={11} />
                <Text text="-" className={styles.value} fs={11} />
              </div>
            </section>
          </div> */}

          <div className={styles.content}>
            <header>
              <Text text="TERMINAL DETAILS" />
            </header>
            <section>
              <div>
                <Text text="IMEI" className={styles.key} fs={11} />
                {/* <Text text={`#${data?.imei}`} className={styles.value} fs={12} /> */}
                {data?.imei ? (
                  <Text
                    text={`#${data?.imei}`}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text={`-`} className={styles.value} fs={11} />
                )}
              </div>
              <div>
                <Text text="Android ID" className={styles.key} fs={11} />
                {data?.android_id ? (
                  <Text
                    text={`#${data?.android_id}`}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text={`-`} className={styles.value} fs={11} />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="SIM serial number" className={styles.key} fs={11} />
                {/* <Text text={`#${data?.sim_serial}`} className={styles.value} fs={11} /> */}
                {data?.sim_serial ? (
                  <Text
                    text={`#${data?.sim_serial}`}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text={`-`} className={styles.value} fs={11} />
                )}
              </div>
              <div>
                <Text text="POS serial number" className={styles.key} fs={11} />
                {/* <Text text={`#${data?.serial_no}`}  fs={11} /> */}
                {data?.serial_no ? (
                  <Text
                    text={`#${data?.serial_no}`}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text={`-`} className={styles.value} fs={11} />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="POS version" className={styles.key} fs={11} />
                <Text
                  text="Version"
                  variables={{ 0: data?.version ?? '-' }}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Terminal ID" className={styles.key} fs={11} />
                <Text text={`#${data?.id}`} fs={11} />
              </div>
            </section>
          </div>
        </div>

        <Table
          // hidePagination
          isLoading={isLoading}
          page={index}
          changePage={setPage}
          count={(data?.integrations?.length ?? 0) - 10}
          bgForHeadOnly
          headers={[
            'PAYMENT GATEWAY',
            'INTEGRATION ID',
            'ACTIVATION DATE',
            'STATUS',
          ]}
        >
          {data?.integrations
            ?.slice(index * 10, index * 10 + 10)
            ?.map((item) => {
              return (
                <StyledTableRow key={item.id} bgForHeadOnly>
                  <StyledTableCell component="th" scope="row">
                    <div className={styles.row}>
                      {/* <img src={Bank} width="24" alt="Bank Installments" /> */}
                      <Text text={item.gateway_type} fs={10} />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text text={`#${item.id}`} fs={10} />
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  <Text text="-" fs={10} />
                </StyledTableCell> */}
                  <StyledTableCell>
                    {item.created_at && (
                      <Text
                        text={format(
                          parseISO(item.created_at),
                          'yyyy-MM-dd hh:mm a'
                        )}
                        fs={10}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      className={
                        item.is_live ? 'status--active' : 'status--inactive'
                      }
                    >
                      <Text
                        text={item?.is_live ? 'LIVE' : 'NOT LIVE'}
                        fs={10}
                      />
                    </div>
                    {/* <Text text="PENDING" fs={10} className="status--pending" /> */}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  <header style={{ padding: 0 }}>
                    <div>
                      <Contract />
                    </div>
                  </header>
                </StyledTableCell> */}
                </StyledTableRow>
              );
            })}
        </Table>
      </Container>
    </div>
  );
}

export default ServiceDetail;
