import Check from 'assets/images/icons/check.svg';
import Text from 'components/text';
import { format, parseISO } from 'date-fns';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import { MerchantDetailsType } from 'pages/main/merchants/marchent/useMerchant';
import { IIntegrationRequestDetailsResponse } from '../integrationRequests/useIntegrationRequests';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
interface contentProps {
  merchant?: MerchantDetailsType;
  integrationRequest?: IIntegrationRequestDetailsResponse;
}

const Content = ({ merchant, integrationRequest }: contentProps) => {
  const handelCopyContent = (text?: string) => {
    if (text?.length) {
      navigator.clipboard.writeText(text);
    }
  };
  return (
    <div className={styles.content}>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ margin: '-10px 0px 12px' }}
      >
        <Grid item xs={6}>
          <Text text="Full Name" fs={11} className={styles.key} />
          <Text
            text={merchant?.full_name ?? `-`}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Status" fs={11} className={styles.key} />
          <Text
            text={merchant?.is_live ? 'LIVE' : 'NOT LIVE'}
            className={
              merchant?.is_live ? 'status--active' : 'status--inactive'
            }
            sx={{ py: 0.2, ml: -0.5 }}
            fs={10}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Date of Joining" fs={11} className={styles.key} />
          <Text
            text={
              merchant?.created_at
                ? format(parseISO(merchant.created_at), 'MMM dd, yyyy')
                : `-`
            }
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Authority of Merchant" fs={11} className={styles.key} />
          <Text
            text={merchant?.legal_documents?.contact_person_authority ?? `-`}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text
            text="Company Contact Mobile Number"
            fs={11}
            className={styles.key}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text
              text={merchant?.primary_phone_number ?? `-`}
              fs={11}
              className={styles.value}
            />
            {merchant?.primary_phone_verified && (
              <Tooltip title="Verified">
                <img
                  src={Check}
                  alt="Check"
                  width={12}
                  height={12}
                  style={{ marginInlineStart: 5 }}
                />
              </Tooltip>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <Text
            text="Company Contact Email Address"
            fs={11}
            className={styles.key}
          />
          <Text
            text={
              merchant?.company_emails
                ? Object.values(merchant.company_emails)[0]
                : `-`
            }
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Company Name in Arabic" fs={11} className={styles.key} />
          <Text
            text={merchant?.company_name ?? `-`}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Company Name in English" fs={11} />
          <Text
            text={merchant?.company_name ?? `-`}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text
            text="Company Registration Address"
            fs={11}
            className={styles.key}
          />
          <Text
            text={
              merchant?.legal_documents?.company_registration_address ?? `-`
            }
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Sales Agent" fs={11} className={styles.key} />
          <Text
            text={merchant?.sales_owner_label ?? `-`}
            fs={11}
            className={styles.value}
          />
        </Grid>
        {!!integrationRequest?.complex_rates_ids?.length && (
          <Grid item xs={12}>
            <div>
              <Text text="Complex Cases Id's" fs={11} className={styles.key} />
              <Text
                text={'#' + integrationRequest?.complex_rates_ids.join(', #')}
                fs={11}
                className={styles.value}
              />
            </div>
          </Grid>
        )}

        <Grid item xs={6}>
          <Text text="Merchant Category Code" fs={11} className={styles.key} />
          <Text
            text={merchant?.legal_documents?.mcc_description ?? '-'}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Text text="GPS Link for POS" fs={11} className={styles.key} />
            {merchant?.legal_documents?.gps_link ? (
              <IconButton
                onClick={() =>
                  handelCopyContent(merchant?.legal_documents?.gps_link)
                }
              >
                <ContentCopyIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>

          <Text
            text={merchant?.legal_documents?.gps_link || '-'}
            fs={11}
            className={styles.value}
          />
        </Grid>

        <Grid item xs={6}>
          <Text text="Sales Agent MCC" fs={11} className={styles.key} />
          <Text
            text={merchant?.legal_documents?.sales_mcc_name ?? '-'}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="Company URL" fs={11} className={styles.key} />
          {merchant?.legal_documents?.company_website ? (
            <a
              href={`${merchant?.legal_documents?.company_website}`}
              target={'_blank'}
              rel="noreferrer"
            >
              <Typography fontSize={17} sx={{ wordBreak: 'break-all' }}>
                {merchant?.legal_documents?.company_website}
              </Typography>
            </a>
          ) : (
            <Text text={'-'} fs={11} className={styles.value} />
          )}
        </Grid>

        <Grid item xs={6}>
          <Text text="PF Merchant Name" fs={11} className={styles.key} />
          <Text
            text={merchant?.legal_documents?.pf_merchant_name ?? '-'}
            fs={11}
            className={styles.value}
          />
        </Grid>
        <Grid item xs={6}>
          <Text text="POS Merchant Name" fs={11} className={styles.key} />
          <Text
            text={merchant?.legal_documents?.pos_merchant_name ?? '-'}
            fs={11}
            className={styles.value}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Content;
