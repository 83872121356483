import axios from 'axios';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

import { Services } from 'apis/services/Services';
import {
  merchantKeys,
  underwritingKeys,
} from 'apis/react-query/queryKeyFactory';
import { showToastError } from 'utils/showToastError';
import { AddBankAccountSchemaFormType } from './AddAccountForm';
import { EditBankAccountSchemaFormType } from './EditAccountForm';

interface IList<T> {
  label: string;
  value: T;
}

interface IStatusesList {
  statuses: IList<string>[];
}

interface ICurrenciesList {
  currencies: IList<string>[];
}

interface IBanksList {
  available_banks: IList<number>[];
}

export interface IBankAccountEdit extends EditBankAccountSchemaFormType {
  id: number;
}

export function useEditAccount(merchantId: string) {
  const queryClient = useQueryClient();

  return useMutation<IBankAccountEdit, Error, IBankAccountEdit>({
    mutationFn: (data) =>
      Services.patch<IBankAccountEdit, IBankAccountEdit>(
        data,
        `merchants/${merchantId}/bank/${data.id}/`,
        true
      ),
    onSuccess: () => {
      toast.success('Bank account has been updated');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.bank(),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useMakeAccountDefault(merchantId?: string) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, number>({
    mutationFn: (bankId: number) =>
      Services.get<unknown>(
        `merchants/${merchantId}/bank/${bankId}/mark_as_default/`,
        true
      ),
    onSuccess: () => {
      toast.success('Bank account has been updated');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.bank(),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useAddAccount(merchantId?: string) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, AddBankAccountSchemaFormType>({
    mutationFn: (data) =>
      Services.set<AddBankAccountSchemaFormType, unknown>(
        data,
        `merchants/${merchantId}/bank/`,
        true
      ),
    onSuccess: () => {
      toast.success('Bank account has been added');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.bank(),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useBankStatuses(merchantId?: string) {
  return useQuery({
    enabled: !!merchantId,
    queryKey: merchantKeys.banksStatuses(),
    queryFn: () =>
      Services.get<IStatusesList>(
        `merchants/${merchantId}/bank/statuses/`,
        true
      ),
  });
}

export function useBankCurrencies(merchantId?: string) {
  return useQuery({
    enabled: !!merchantId,
    queryKey: merchantKeys.banksCurrencies(),
    queryFn: () =>
      Services.get<ICurrenciesList>(
        `merchants/${merchantId}/bank/currencies/`,
        true
      ),
    select: (data) => {
      data['currencies'] = data['currencies'].map((currency) => ({
        ...currency,
        label: currency.label + ' ',
      }));
      return data;
    },
  });
}

export function useBanks() {
  return useQuery({
    queryKey: underwritingKeys.integrationBanks(),
    queryFn: () =>
      Services.get<IBanksList>(`integration_requests/banks_list/`, true),
  });
}
