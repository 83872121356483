import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import Text from 'components/text';
import Container from 'components/layouts/Container';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import styles from 'styles/main/merchants/index.module.scss';
import Filters from 'components/filters/Filters';
import EmptyTable from 'components/Table/EmptyTable';
import Check from 'assets/images/icons/check.svg';
import { useMerchants } from './useMerchants';
import { useTranslation } from 'react-i18next';

function Merchants() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const { t } = useTranslation();

  const filters = { page, searchText, status, sort, selectedKey };
  const { data, isLoading } = useMerchants(filters);

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value.toString());
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value.toString());
  };

  return (
    <div id={styles.merchants}>
      <Container>
        <header>
          <Text text="Merchants" />
        </header>

        <Filters
          inputLabel="Search for merchant"
          onSearchChange={FilteredBySearch}
          onStatusChange={FilteredByStatus}
          searchSelectors={[
            { label: t('MID'), value: '' },
            { label: t('Username'), value: 'user__username' },
            { label: t('Phone Number'), value: 'phone_number' },
            { label: t('National ID'), value: 'national_id' },
          ]}
          onSelectedKey={setSelectedKey}
          inputPlaceholder="Search by name, merchant ID"
          selectPlaceholder="Filter by merchant status"
          onSortChange={FilteredBySort}
        />

        <Table
          page={page}
          changePage={setPage}
          count={data?.count ?? 0}
          isLoading={isLoading}
          headers={[
            'MID',
            'NAME',
            'USERNAME',
            'COMPANY NAME',
            'PHONE NO.',
            'EMAIL',
            'STATUS',
          ]}
        >
          {data?.results?.map((item) => (
            <StyledTableRow key={item?.id}>
              <StyledTableCell component="th" scope="row">
                <Link to={`/${item?.id}`}>{item?.id}</Link>
              </StyledTableCell>
              <StyledTableCell>
                <Link to={`/${item?.id}`}>
                  <Text text={item?.full_name ?? ''} component="span" fs={10} />
                </Link>
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text={item?.user?.username ?? ''}
                  component="span"
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text={item?.company_name ?? ''}
                  component="span"
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text={`${item?.primary_phone_number ?? ''}`}
                  component="span"
                  fs={10}
                />
                {item?.primary_phone_verified && (
                  <Tooltip title="Verified">
                    <img
                      src={Check}
                      alt="Check"
                      width={12}
                      height={12}
                      style={{ marginInlineStart: 5 }}
                    />
                  </Tooltip>
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text={
                    item?.company_emails
                      ? `${Object.values(item?.company_emails)?.join(' , ')}`
                      : '-'
                  }
                  component="span"
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <div
                  className={
                    item?.is_live ? 'status--active' : 'status--inactive'
                  }
                >
                  <Text text={item?.is_live ? 'LIVE' : 'NOT LIVE'} fs={10} />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}

          {data?.results?.length === 0 && <EmptyTable />}
        </Table>
      </Container>
    </div>
  );
}

export default Merchants;
