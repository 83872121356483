import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type POSDetailsResponse = {
  id: number;
  android_id: string;
  imei: string;
  serial_no: string;
  sim_serial: string;
  version: string;
  active: boolean;
  created_at: string;
  contract_link: string;
  integrations: IntegrationType[];
};

type IntegrationType = {
  id: number;
  gateway_type: string;
  created_at: string;
  is_live: boolean;
};

interface IUsePOSDetails {
  merchantId?: string;
  terminalId?: string;
}

export function usePOSDetails({ merchantId, terminalId }: IUsePOSDetails) {
  return useQuery({
    queryKey: merchantKeys.posDetails(merchantId, terminalId),
    queryFn: () =>
      Services.get<POSDetailsResponse>(
        `merchants/${merchantId}/terminals/${terminalId}/`,
        true
      ),
  });
}
