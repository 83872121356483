import Loading from 'components/loading/Loading';
import Navbar from 'components/navbar';
import { useAuthContext } from 'contexts/AuthContext';
import { Outlet, ScrollRestoration } from 'react-router-dom';

function MainLayout() {
  const { isLoading } = useAuthContext();

  return (
    <main>
      {isLoading && <Loading />}
      <Navbar />
      <Outlet />
      <ScrollRestoration />
    </main>
  );
}

export default MainLayout;
