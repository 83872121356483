import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Img from 'assets/images/logo.svg';
import LegalDocStatus from 'constants/LegalDocStatus';
import Check from 'assets/images/icons/check.svg';
import { Box, Button, Tooltip } from '@mui/material';
import { useServices } from './useServices';
import { useBanks } from './useBanks';
import { useLegal } from './useLegal';
import { useMerchant, useMerchantLogo } from '../useMerchant';
import MerchantDetails from 'components/modals/merchant-drawer/merchant-details/MerchantDetails';
import MerchantDocuments from 'components/modals/merchant-drawer/merchant-documents/MerchantDocuments';
import { downloadFile } from 'utils/downloadFile';
import PhoneNumberForm from 'components/modals/phoneNumberForm/PhoneNumberForm';
import BankAccountDetails from 'components/modals/bank-account-details/EditAccount';
import DefaultIcon from 'assets/images/icons/default.svg';
import {
  IDocumentDetailsResponse,
  useDocuments,
} from 'pages/main/underwriting/documents/merchantDocs/useDocuments';
import { statusClasses } from 'components/modals/merchant-drawer/merchant-documents/DocumentDetails';
import {
  IBusinessOwnerDetailsResponse,
  useBusinessOwners,
} from 'pages/main/underwriting/documents/businessOwnersInfo/useBusinessOwner';

interface DocumentSecType {
  document: IDocumentDetailsResponse | IBusinessOwnerDetailsResponse;
}

const DocumentSec = ({ document }: DocumentSecType) => {
  const { created_at, reviewed_by, status } = document;
  let label = '';
  let file = '';
  let documentID = '';

  if ('document_label' in document) {
    label = document.document_label;
    file = document.document_file;
  } else {
    label = document.national_id
      ? 'National ID'
      : document.passport
      ? 'Passport ID'
      : '';
    file = document.signatory_national_id || document.passport_id_file;
    documentID = document.national_id || document.passport;
  }

  if (!label) return null;

  const isGeneratedContract = label === 'Generated Contract';

  const handleDownload = () => {
    if (!file) return;
    const fileName = file?.split('/')?.at(-1)?.split('.')?.[0];
    downloadFile(file, fileName ?? 'document');
  };

  return (
    <section>
      <div>
        <Text text={label} className={styles.key} fs={11} />
        {documentID && (
          <Text
            text={`${label} number: ${documentID}`}
            className={styles.value}
            fs={11}
          />
        )}

        {created_at && (
          <Text
            text="Uploaded on"
            variables={{
              0: format(parseISO(created_at), 'dd MMM yyyy'),
            }}
            className={styles.value}
            fs={11}
          />
        )}
        {!isGeneratedContract && (
          <Text
            text="Reviewed by"
            variables={{ 0: reviewed_by || '-' }}
            className={styles.value}
            fs={11}
          />
        )}
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 1,
        }}
      >
        {!isGeneratedContract && (
          <Text
            text={LegalDocStatus[status as keyof typeof LegalDocStatus]}
            fs={8}
            className={
              statusClasses[status as keyof typeof statusClasses] ??
              statusClasses.default
            }
          />
        )}

        {file && (
          <Button onClick={handleDownload} size="small">
            <Text text="Download" fs={10} />
          </Button>
        )}
      </Box>
    </section>
  );
};

function Overview() {
  const { merchantId } = useParams();
  const { data: services } = useServices(merchantId!);
  const { data: banks } = useBanks({ merchantId, isDefault: true });
  const { data: documents } = useLegal(merchantId!);
  const { data: businessOwner } = useBusinessOwners({
    merchantId,
    filters: 'not_primary=False',
  });
  const { data: merchantDocs } = useDocuments(merchantId);
  const { data: merchantDetails } = useMerchant(merchantId!);
  const { data: merchantLogo = Img } = useMerchantLogo(
    merchantId,
    merchantDetails?.logo
  );

  return (
    <div id={styles.overview}>
      <div className={styles.details}>
        <header>
          <Text text="Merchant details" />
          <div style={{ textAlign: 'end' }}>
            <MerchantDetails merchantDetails={merchantDetails!} />
          </div>
        </header>
        <div className={styles.content}>
          <section>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {merchantDetails?.logo ? (
                <div style={{ width: 60 }}>
                  <img
                    src={
                      merchantDetails?.logo.includes('https')
                        ? merchantDetails?.logo
                        : merchantLogo
                    }
                    style={{
                      objectFit: 'contain',
                    }}
                    alt="profile page"
                    width={34}
                    height={34}
                  />
                </div>
              ) : (
                <div style={{ width: 60 }}>
                  <img src={Img} alt="profile page" width={34} height={34} />
                </div>
              )}
              <div>
                <Text text="Full name" className={styles.key} fs={11} />
                <Text
                  text={merchantDetails?.full_name ?? ''}
                  className={styles.value}
                  fs={11}
                />
              </div>
            </div>
            <div>
              <Text
                text="Authority of merchant"
                className={styles.key}
                fs={11}
              />
              <Text
                text={documents?.[0]?.contact_person_authority ?? ''}
                className={styles.value}
                fs={11}
              />
            </div>
          </section>

          <section>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.img}></div>
              <div>
                <Text text="Phone number" className={styles.key} fs={11} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 0.5,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text
                      text={
                        merchantDetails?.primary_phone_number || 'Not provided'
                      }
                      className={styles.value}
                      fs={11}
                    />
                    {merchantDetails?.primary_phone_verified && (
                      <Tooltip title="Verified">
                        <img
                          src={Check}
                          alt="Check"
                          width={12}
                          height={12}
                          style={{ marginInlineStart: 5 }}
                        />
                      </Tooltip>
                    )}
                  </div>

                  <PhoneNumberForm
                    hasPhoneNumber={!!merchantDetails?.primary_phone_number}
                  />
                </Box>
              </div>
            </div>
            <div>
              <Text text="Email address" className={styles.key} fs={11} />
              <Text
                text={merchantDetails?.user?.email || ''}
                className={styles.value}
                fs={11}
              />
            </div>
          </section>

          <section>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.img}></div>
              <div>
                <Text text="Company name" className={styles.key} fs={11} />
                <Text
                  text={merchantDetails?.company_name || ''}
                  className={styles.value}
                  fs={11}
                />
              </div>
            </div>
            <div>
              <Text text="Company address" className={styles.key} fs={11} />
              <Text
                text={`${
                  merchantDetails?.legal_documents
                    ?.company_registration_address ?? ''
                }`}
                className={styles.value}
                fs={12}
              />
              <Text
                text={`${
                  merchantDetails?.legal_documents?.the_governorate_name ?? ''
                } ${merchantDetails?.legal_documents?.city_name ?? ''}`}
                className={styles.value}
                fs={12}
              />
              <Text
                text={`${
                  merchantDetails?.legal_documents?.neighborhood_name ?? ''
                }, ${merchantDetails?.country}`}
                className={styles.value}
                fs={11}
              />
            </div>
          </section>

          <section>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.img}></div>
              <div>
                <Text text="Sales agent info" className={styles.key} fs={11} />
                {/* name/number of sales missing */}
                <Text
                  text={merchantDetails?.sales_owner_label || ''}
                  className={styles.value}
                  fs={11}
                />
                {/* <Text
                  text="+2 012 2722 3456"
                  className={styles.value}
                  fs={11}
                /> */}
              </div>
            </div>
            <div>
              <Text text="Account manager" className={styles.key} fs={11} />
              <Text
                text={merchantDetails?.account_manager_label || ''}
                className={styles.value}
                fs={11}
              />
              {/* <Text text="+2 012 2722 3456" className={styles.value} fs={11} /> */}
            </div>
          </section>

          <section>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.img}></div>
              <div>
                <Text
                  text="Legacy phone numbers"
                  className={styles.key}
                  fs={11}
                />
                {merchantDetails?.phones?.map((phone) => (
                  <Text text={phone} className={styles.value} fs={11} />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className={styles.documents}>
        <header>
          <Text text="Merchant documents" />
          <div style={{ textAlign: 'end' }}>
            <MerchantDocuments />
          </div>
        </header>
        <div className={styles.content}>
          {businessOwner?.results?.map((document) => (
            <DocumentSec key={document.id} document={document} />
          ))}

          {merchantDocs?.results?.map((document) => (
            <DocumentSec key={document.id} document={document} />
          ))}

          {merchantDocs?.results?.length === 0 &&
            !(
              businessOwner?.results?.[0]?.national_id ||
              businessOwner?.results?.[0]?.passport
            ) && (
              <div
                style={{
                  paddingBlock: 21,
                  textAlign: 'center',
                }}
              >
                <Text text={'No Data to show'} component="p" fs={18} />
              </div>
            )}
        </div>
      </div>

      <div className={styles.details}>
        <header>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Text text="Bank account details" />
            <Text text="(default)" sx={{ color: 'var(--clr-gray)' }} />
          </Box>
          <div style={{ textAlign: 'end' }}>
            {banks?.results?.map(
              (account) =>
                account.is_default && (
                  <BankAccountDetails
                    key={account.id}
                    bankAccount={account}
                    merchantId={merchantId}
                  />
                )
            )}
          </div>
        </header>
        <div className={styles.content}>
          {banks?.results?.map(
            (item) =>
              item.is_default && (
                <div key={`${item.id}_banks`}>
                  <section>
                    <div>
                      <Text
                        text="Account holder"
                        className={styles.key}
                        fs={11}
                      />
                      <Text
                        text={item.account_name}
                        className={styles.value}
                        fs={12}
                      />
                    </div>
                    <div>
                      <Text text="Bank" className={styles.key} fs={11} />
                      <Text
                        text={item.bank_name}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                  </section>

                  <section>
                    <div>
                      <Text
                        text="Account number"
                        className={styles.key}
                        fs={11}
                      />
                      <Text
                        text={item.account_number}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                    <div>
                      <Text text="Branch" className={styles.key} fs={11} />
                      <Text
                        text={item.bank_branch ?? '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                  </section>

                  <section>
                    <div>
                      <Text text="IBAN" className={styles.key} fs={11} />
                      <Text
                        text={item.iban ?? '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                    <div>
                      <Text text="Bank Swift" className={styles.key} fs={11} />
                      <Text
                        text={item.bank_swift ?? '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                  </section>

                  <section>
                    <div>
                      <Text text="Mobile" className={styles.key} fs={11} />
                      <Text
                        text={item.contact_mobile_number ?? '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                    <div>
                      <Text text="Email" className={styles.key} fs={11} />
                      <Text
                        text={item.contact_email ?? '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                  </section>

                  <section>
                    <div>
                      <Text text="Source bank" className={styles.key} fs={11} />
                      <Text
                        text={item.source_bank_name || '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>
                    <div>
                      {item.is_active ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            text={'Active account'}
                            style={{
                              marginInlineEnd: 5,
                            }}
                            fs={10}
                          />
                          <img src={DefaultIcon} alt="icon" />
                        </div>
                      ) : (
                        <Text
                          text="Account not active"
                          className={styles.key}
                          fs={10}
                        />
                      )}
                    </div>
                  </section>
                  <section>
                    <div>
                      <Text text="Currency" className={styles.key} fs={11} />
                      <Text
                        text={item.currency ? item.currency + ' ' : '-'}
                        className={styles.value}
                        fs={11}
                      />
                    </div>

                    <div>
                      <Text text="Status" className={styles.key} fs={11} />
                      <Text
                        text={item.status}
                        className="status--active"
                        fs={8}
                      />
                    </div>
                  </section>
                </div>
              )
          )}
          {banks?.results?.length === 0 && (
            <div
              style={{
                paddingBlock: 21,
                textAlign: 'center',
              }}
            >
              <Text text={'No Default account to show'} component="p" fs={18} />
            </div>
          )}
          <Link to={`/${merchantDetails?.id}/banks`}>
            <Text text="View more" fs={11} />
          </Link>
        </div>
      </div>

      <div className={styles.documents}>
        <header>
          <Text text="Services" />
          <div style={{ textAlign: 'end' }}>
            <Link to={`/${merchantDetails?.id}/services`}>
              <Text text="View" fs={10} />
            </Link>
          </div>
        </header>
        <div className={styles.content}>
          {services?.map((item, index) => (
            <section key={`${index}_services`}>
              <div>
                <Text text={item.gateway_type} className={styles.key} fs={11} />
              </div>
              <div style={{ textAlign: 'end' }}>
                <div
                  className={
                    item.is_live ? 'status--active' : 'status--inactive'
                  }
                >
                  <Text text={item.is_live ? 'ACTIVE' : 'NOT ACTIVE'} fs={8} />
                </div>
              </div>
            </section>
          ))}
          {services?.length === 0 && (
            <div
              style={{
                paddingBlock: 21,
                textAlign: 'center',
              }}
            >
              <Text text={'No Data to show'} component="p" fs={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
