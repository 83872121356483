import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from 'components/layouts/MainLayout';
import Login from 'pages/auth/login';
import DashboardLayout from 'components/layouts/DashboardLayout';
import Merchants from 'pages/main/merchants';
import Banks from 'pages/main/merchants/marchent/overview/banks';
import TransactionDetail from 'pages/main/merchants/marchent/transactions/details';
import TransferDetail from 'pages/main/merchants/marchent/transfers/details';
import ServiceDetail from 'pages/main/merchants/marchent/services/details';
import MerchantLayout from 'components/layouts/MerchantLayout';
import Overview from 'pages/main/merchants/marchent/overview';
import TimeLine from 'pages/main/merchants/marchent/timeline';
import General from 'pages/main/merchants/marchent/timeline/General';
import SMSReport from 'pages/main/merchants/marchent/timeline/SMSReport';
import CallsReport from 'pages/main/merchants/marchent/timeline/CallsReport';
import CallCenterNPS from 'pages/main/merchants/marchent/timeline/CallCenterNPS';
import VisitNPS from 'pages/main/merchants/marchent/timeline/VisitNPS';
import Service from 'pages/main/merchants/marchent/services';
import Transactions from 'pages/main/merchants/marchent/transactions';
import Transfers from 'pages/main/merchants/marchent/transfers';
import SMSCommunication from 'pages/main/merchants/marchent/sms-communication';
import Visits from 'pages/main/merchants/marchent/visits';
import BillsBalance from 'pages/main/merchants/marchent/bills-balance';
import BillsBalanceDetails from 'pages/main/merchants/marchent/bills-balance/details';
import Underwriting from 'pages/main/underwriting';
import RequestsLayout from 'pages/main/underwriting/requests';
import RequestDetails from 'pages/main/underwriting/requests/details';
import RequestsList from 'pages/main/underwriting/requests/requestsList';
import NewRequest from 'pages/main/underwriting/requests/newRequest';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute';

const Router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'auth/login', element: <Login /> },
      {
        path: '',
        element: (
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        ),
        children: [
          { index: true, element: <Merchants /> },
          {
            path: ':merchantId',
            children: [
              { path: 'banks', element: <Banks /> },
              { path: 'transactions/:id', element: <TransactionDetail /> },
              { path: 'bills-balance/:id', element: <BillsBalanceDetails /> },
              { path: 'transfers/:id', element: <TransferDetail /> },
              { path: 'services/:id', element: <ServiceDetail /> },
              {
                path: '',
                element: <MerchantLayout />,
                children: [
                  { index: true, element: <Overview /> },
                  {
                    path: 'timeline',
                    element: <TimeLine />,
                    children: [
                      { index: true, element: <General /> },
                      { path: 'sms_report', element: <SMSReport /> },
                      { path: 'calls_report', element: <CallsReport /> },
                      { path: 'call_center_nps', element: <CallCenterNPS /> },
                      { path: 'visit_nps', element: <VisitNPS /> },
                    ],
                  },
                  { path: 'services', element: <Service /> },
                  { path: 'transactions', element: <Transactions /> },
                  { path: 'transfers', element: <Transfers /> },
                  { path: 'sms-communication', element: <SMSCommunication /> },
                  { path: 'visits', element: <Visits /> },
                  { path: 'bills-balance', element: <BillsBalance /> },
                ],
              },
            ],
          },
          {
            path: 'underwriting',
            element: <Underwriting />,
            children: [
              {
                index: true,
                element: <Navigate to="requests/pending" replace />,
              },
              {
                path: 'requests',
                element: <RequestsLayout />,
                children: [
                  { path: 'pending', element: <RequestsList /> },
                  { path: 'closed', element: <RequestsList /> },
                  { path: 'new', element: <NewRequest /> },
                ],
              },
              {
                path: ':merchantId/request/:requestId?',
                element: <RequestDetails />,
              },
            ],
          },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);

export default Router;
