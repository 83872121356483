import { useQuery } from '@tanstack/react-query';
import { DocumentViewURL } from 'apis/AxiosConfig';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { fetchImageAsBase64 } from 'utils/fetchImageAsBase64';

export type MerchantDetailsType = {
  id?: number;
  full_name?: string;
  user?: User;
  company_name?: string;
  company_emails?: { [key: string]: string } | string[];
  primary_phone_number?: string;
  primary_phone_verified?: boolean;
  sales_owner?: number;
  account_manager?: number;
  sales_owner_label?: string;
  account_manager_label?: string;
  country?: string;
  is_live?: boolean;
  active?: boolean;
  logo?: string;
  created_at?: string;
  legal_documents?: LegalDocuments;
  phones?: string[];
};

export type LegalDocuments = {
  contact_person_authority?: string;
  the_governorate?: string[];
  the_governorate_name?: string;
  city?: string;
  city_name?: string;
  neighborhood?: string[];
  neighborhood_name?: string;
  company_registration_address?: string;
  merchant_category_code?: string;
  mcc_description?: string;
  gps_link?: string;
  sales_agent_mcc?: string;
  sales_mcc_name?: string;
  company_website?: string;
  pf_merchant_name?: string;
  pos_merchant_name?: string;
};

type User = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
};

export function useMerchant(merchantId: string) {
  return useQuery({
    queryKey: merchantKeys.merchant(merchantId),
    queryFn: () =>
      Services.get<MerchantDetailsType>(`merchants/${merchantId}/`, true),
  });
}

export function useMerchantLogo(
  merchantId?: string | number,
  filePath?: string
) {
  return useQuery<string, Error>({
    queryFn: () => fetchImageAsBase64(`${DocumentViewURL}${filePath}`),
    enabled: !!filePath,
    queryKey: merchantKeys.merchantLogo(merchantId, filePath),
  });
}
