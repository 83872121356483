import { blobToBase64 } from './blobToBase64';

export function fetchImageAsBase64(url: string) {
  return new Promise<string>((resolve) => {
    let token = sessionStorage.getItem('token');

    // Make a headers object that we can add to the request
    const headers = new Headers({
      authorization: 'Bearer ' + token,
    });

    let isPDF = url.includes('pdf') ? true : false;

    // Make the request and wait for the response
    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => blobToBase64(blob, isPDF))
      .then((base64) => resolve(base64));
  });
}
