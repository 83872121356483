import { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Text from 'components/text';
import { useDimensions } from 'hooks/useDimensions';
import { useTranslation } from 'react-i18next';

import Logout from 'assets/images/icons/logout.svg';
import Merchants from 'assets/images/icons/merchant.svg';
import UnderWritting from 'assets/images/icons/underwritting.svg';
import { useAuthContext } from 'contexts/AuthContext';

const drawerWidth = '14.063vw';
type MainProps = {
  open: boolean;
  down1200: boolean;
};
const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'down1200',
})<MainProps>(({ theme, open, down1200 }) => ({
  flexGrow: 1,
  paddingBlock: 71,
  boxShadow: '0px 3px 13px #77737326',
  zIndex: 1,
  width: down1200 ? '100%' : `85vw`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: down1200 ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function DashboardLayout() {
  const down1200 = useDimensions({ breakpoint: 'lg' });
  const [open, setOpen] = useState(true);
  const { signOut, userToken } = useAuthContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    setOpen(!down1200);
  }, [down1200]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => signOut();

  return (
    <div style={{ display: down1200 ? 'unset' : 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #98909026',
            border: 0,
            padding: 3,
            boxSizing: 'border-box',
          },
        }}
        variant={down1200 ? 'temporary' : 'persistent'}
        onClose={handleDrawerClose}
        anchor={i18n.language === 'ar' ? 'right' : 'left'}
        open={open}
      >
        <Toolbar sx={{ minHeight: '72px !important' }} />
        <div
          style={{
            paddingBlock: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NavLink
              to={'/'}
              style={({ isActive }) =>
                isActive || !!+window.location.pathname.split('/')[1]
                  ? { color: 'var(--clr-lightBlue)', marginBlock: 10 }
                  : { color: '#707070', marginBlock: 10 }
              }
              end
            >
              <img
                src={Merchants}
                alt={'Merchants icon'}
                style={{ display: 'inline-block', marginInlineEnd: 10 }}
              />
              <Text
                text="Merchants"
                fs={12}
                style={{ display: 'inline-block' }}
              />
            </NavLink>
            <NavLink
              to={'/underwriting'}
              style={({ isActive }) =>
                isActive
                  ? { color: 'var(--clr-lightBlue)', marginBlock: 10 }
                  : { color: '#707070', marginBlock: 10 }
              }
            >
              <img
                src={UnderWritting}
                alt={'Underwriting icon'}
                style={{ display: 'inline-block', marginInlineEnd: 10 }}
              />
              <Text
                text="Underwriting"
                fs={12}
                style={{ display: 'inline-block' }}
              />
            </NavLink>
          </div>
          {userToken && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleLogout()}
            >
              <img
                src={Logout}
                alt={'logout icon'}
                style={{ marginInlineEnd: 10 }}
              />
              <Text
                text="Log out"
                fs={12}
                style={{ display: 'inline-block' }}
              />
            </div>
          )}
        </div>
      </Drawer>
      <Main open={open} down1200={down1200}>
        <Outlet />
      </Main>
    </div>
  );
}

export default DashboardLayout;
