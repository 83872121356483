import Text from 'components/text';
import { format, parseISO } from 'date-fns';
import styles from './Activity.module.scss';
import type { GeneralType } from 'pages/main/merchants/marchent/timeline/useTimeline';

interface ActivityProps {
  item: GeneralType;
}

function Activity({ item }: ActivityProps) {
  return (
    <div className={styles.activity_card}>
      <div className={styles.state} />
      <div className={styles.content}>
        <Text text={item.title} fs={10} component="h4" />
        <Text
          text={`${format(parseISO(item.created_at), 'dd MMM yyyy')} by ${
            item.agent
          }`}
          fs={10}
        />
        <Text text={`Comment: ${item.comment}`} fs={10} />
        <Text text={`Activity ID: #${item.id}`} fs={10} />
      </div>
    </div>
  );
}

export default Activity;
