import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';

type UpdatePOSVersionRequestType = {
  data: { pos_version_update: number; allow_update: boolean; password: string };
  merchantId: string;
  terminalId: string;
};

type UpdatePOSVersionResponseType = {
  allow_update: boolean;
  pos_version_update: number;
};

export function useUpdatePOSVersion() {
  const queryClient = useQueryClient();

  const { mutate: updatePOSVersion, isLoading } = useMutation<
    UpdatePOSVersionResponseType,
    Error,
    UpdatePOSVersionRequestType
  >({
    mutationFn: ({ data, merchantId, terminalId }) =>
      Services.patch<
        UpdatePOSVersionRequestType['data'],
        UpdatePOSVersionResponseType
      >(
        data,
        `merchants/${merchantId}/terminals/${terminalId}/update_apk/`,
        true
      ),
    onSuccess: () => {
      toast.success('Updated POS Version');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.activities({}).slice(0, 1),
      });
    },
    onError: () => {
      toast.error('Could Not Updated POS Version');
    },
  });

  return { isLoading, updatePOSVersion };
}
