import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box, SxProps, Theme } from '@mui/material';
import { Select as SelectAntD } from 'antd';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { RangeValue } from 'rc-picker/lib/interface';

import Search from 'components/input/Search';
import DSelect from 'components/input/DSelect';
import styles from './Filters.module.scss';
import MyDatePicker from 'components/datePicker/DatePicker';
import { useDimensions } from 'hooks/useDimensions';

const { RangePicker } = MyDatePicker;

export type OptionType = { label: string; value: string | number };

interface FiltersProps {
  inputLabel: string;
  inputPlaceholder: string;
  selectPlaceholder?: string;
  showDate?: boolean;
  onSearchChange?: (value: string | number) => void;
  onStatusChange?: (value: string | number) => void;
  statusData?: OptionType[];
  searchSelectors: OptionType[];
  onSelectedKey: React.Dispatch<React.SetStateAction<string>>;
  onDateChange?: (e: null | { from?: string; to?: string }) => void;
  onSortChange?: (value: string | number) => void;
  isNeedSorting?: boolean;
  extraFilters?: React.ReactNode;
  sx?: SxProps<Theme>;
}
function Filters({
  inputLabel,
  inputPlaceholder,
  selectPlaceholder,
  showDate,
  onSearchChange,
  onStatusChange,
  statusData,
  searchSelectors,
  onSelectedKey,
  onDateChange = () => {},
  onSortChange,
  isNeedSorting,
  extraFilters,
  sx,
}: FiltersProps) {
  const { t, i18n } = useTranslation();
  const [calendarDate, setCalendarDate] = useState<null | RangeValue<Date>>(
    null
  );
  const [value, setValue] = useState(null);
  const down600 = useDimensions({ breakpoint: 'sm' });

  const disabledDate = (current: Date): boolean => {
    const startDate = calendarDate?.[0];
    const endDate = calendarDate?.[1];
    const currentDateString = new Date(current).toDateString();

    return (
      (current && current.valueOf() >= Date.now()) ||
      (startDate && currentDateString === new Date(startDate).toDateString()) ||
      (endDate && currentDateString === new Date(endDate).toDateString()) ||
      false
    );
  };

  const onOpenChange = (open: boolean) => {
    open
      ? setCalendarDate([null, null] as unknown as null)
      : setCalendarDate(null);
  };

  const changeDate = (value: any) => {
    setValue(value);
    if (value) {
      onDateChange({
        from: format(value?.[0], 'yyyy-MM-dd'),
        to: format(value?.[1], 'yyyy-MM-dd'),
      });
    } else {
      onDateChange(null);
    }
  };

  return (
    <Box id={styles.filters} sx={{ gap: down600 ? 'unset' : '12px' }}>
      <div className={styles.search}>
        <Search
          label={t(inputLabel)}
          searchSelectors={searchSelectors}
          // isSearch
          placeholder={t(inputPlaceholder)}
          onTextChange={onSearchChange}
          onSelectedKey={onSelectedKey}
          isNeedSorting
        />
      </div>

      <Box
        className={styles.filter}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          gap: '12px',
          ...sx,
        }}
      >
        {showDate && (
          <div className={styles.datePicker}>
            <RangePicker
              value={calendarDate || value}
              disabledDate={disabledDate}
              onCalendarChange={(date) => setCalendarDate(date)}
              onChange={changeDate}
              onOpenChange={onOpenChange}
              locale={i18n?.language as unknown as PickerLocale}
            />
          </div>
        )}

        {onStatusChange && (
          <DSelect
            placeholder={t(selectPlaceholder || '')}
            onSelectChange={onStatusChange}
            data={
              statusData ?? [
                { label: t('All'), value: '' },
                { label: t('Live'), value: '&is_live=true' },
                { label: t('Not Live'), value: '&is_live=false' },
              ]
            }
          />
        )}

        {extraFilters}

        {isNeedSorting && (
          <SelectAntD
            onChange={onSortChange}
            placeholder={t('Sort with')}
            style={{
              minWidth: 100,
            }}
            bordered={false}
            options={[
              { value: '&ordering=-created_at', label: t('Newest') },
              { value: '&ordering=created_at', label: t('Oldest') },
            ]}
          />
        )}
      </Box>
    </Box>
  );
}

export default Filters;
