import { Fragment } from 'react';
import { Box, Divider, Grid, Skeleton } from '@mui/material';
import { format, parseISO } from 'date-fns';

import Button from 'components/button/Button';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import {
  IIntegrationRequestDetailsResponse,
  useIntegrationRequestDetails,
  useApproveRequest,
  useRejectRequest,
} from './useIntegrationRequests';

const statusClasses = {
  approved: 'status--active',
  rejected: 'status--inactive',
  pending: 'status--pending--yellow',
  updated: 'status--updated',
  default: 'status--pending',
};

interface IIntegrationRequestDetailsProps {
  id: string;
}

const IntegrationRequestDetails = ({ id }: IIntegrationRequestDetailsProps) => {
  const { data, isFetching } = useIntegrationRequestDetails(id);
  const { mutate: approveRequest } = useApproveRequest();
  const { mutate: rejectRequest } = useRejectRequest();

  const handleApproveService = () => approveRequest(id);

  const handleRejectService = () => rejectRequest(id);

  const handelRate = (item?: IIntegrationRequestDetailsResponse) => {
    const rateType = item?.integration_rate_type || '-';
    const fixedRate = item?.integration_rate_fixed || '0.0';
    const percentRate = item?.integration_rate_percentage || '0.0';

    switch (rateType) {
      case 'Fixed':
        return `${fixedRate} EGP`;
      case 'Percent':
        return `${percentRate}%`;
      case 'Percent & Fixed':
        return `${percentRate}% + ${fixedRate} EGP`;
      default:
        return rateType;
    }
  };

  const actionsContent = ['pending', 'updated'].includes(
    data?.status?.toLowerCase() || ''
  ) ? (
    <>
      <Divider variant="middle" sx={{ my: 2 }} />

      <section>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleApproveService}
            text="Approve request"
            style={{ backgroundColor: 'var(--clr-green)' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleRejectService}
            text="Reject request"
            style={{ backgroundColor: 'var(--clr-red)' }}
          />
        </Box>
      </section>
    </>
  ) : null;

  return (
    <div className={styles.details}>
      <header>
        <Text text="Request Service" />
      </header>
      {isFetching && (
        <Grid container rowSpacing={4} sx={{ p: 5 }}>
          {[1, 2, 3].map((val) => (
            <Fragment key={val}>
              <Grid item xs={4}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
      {!isFetching && (
        <div className={styles.content}>
          <section>
            <div>
              <Text text="Merchant ID" fs={11} className={styles.key} />
              <Text
                text={data?.merchant_id || `-`}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Integration Service" fs={11} className={styles.key} />
              <Text
                text={data?.integration_service || `-`}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Status" fs={11} className={styles.key} />
              <Text
                text={data?.status || `-`}
                fs={10}
                sx={{ py: 0.2, ml: -0.5 }}
                className={
                  statusClasses[
                    data?.status
                      ?.toLowerCase()
                      ?.split(' ')?.[0] as keyof typeof statusClasses
                  ] || statusClasses.default
                }
              />
            </div>
          </section>

          <section>
            <div>
              <Text text="Rate" fs={11} className={styles.key} />
              <Text text={handelRate(data)} fs={11} className={styles.value} />
            </div>
            <div>
              <Text text="Gateway Type" fs={11} className={styles.key} />
              <Text
                text={data?.gateway_type || `-`}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Integration Type" fs={11} className={styles.key} />
              <Text
                text={data?.integration_type || `-`}
                fs={11}
                className={styles.value}
              />
            </div>
          </section>

          <section>
            <div>
              <Text text="Created At" fs={11} className={styles.key} />
              <Text
                text={
                  data?.created_at
                    ? format(parseISO(data.created_at), 'MMM dd, yyyy')
                    : `-`
                }
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Updated At" fs={11} />
              <Text
                text={
                  data?.updated_at
                    ? format(parseISO(data.updated_at), 'MMM dd, yyyy')
                    : `-`
                }
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Reviewed By" fs={11} className={styles.key} />
              <Text
                text={data?.reviewed_by || `-`}
                fs={11}
                className={styles.value}
              />
            </div>
          </section>

          {actionsContent}
        </div>
      )}
    </div>
  );
};

export default IntegrationRequestDetails;
