import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ar from './locales/ar.json';

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

let defaultLang = localStorage.getItem('i18nextLng') ?? 'en';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  debug: false,
  fallbackLng: 'en',
});

const bootstrapAsync = async (lng: string) => {
  localStorage.setItem('i18nextLng', lng);
  let dir = lng === 'ar' ? 'rtl' : 'ltr';
  document.documentElement.setAttribute('lang', lng);
  document.body.setAttribute('dir', dir);
  if (document.getElementsByTagName('nav').length) {
    document?.querySelector('nav')?.setAttribute('dir', dir);
  }
};

i18n.on('languageChanged', (lng) => bootstrapAsync(lng));
bootstrapAsync(defaultLang);
export default i18n;
