import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import axios from 'axios';
import toast from 'react-hot-toast';
import { showToastError } from 'utils/showToastError';

type GeneratePdfRequestType = {
  data: { transfer_ids: number[] };
  merchantId: string;
};

type GeneratePdfResponseType = {
  message: string;
  incorrect_ids: number[];
  already_generated: number[];
  failed_ids: number[];
};

export function useGenerateAchPdf(merchantId: string) {
  const queryClient = useQueryClient();

  return useMutation<GeneratePdfResponseType, Error, GeneratePdfRequestType>({
    mutationFn: ({ data, merchantId }) =>
      Services.set<GeneratePdfRequestType['data'], GeneratePdfResponseType>(
        data,
        `merchants/${merchantId}/transfers/generate_pdf/`,
        true
      ),
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({
        queryKey: merchantKeys.generateAchPdf('' + merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      //const errorMsg = Object.keys(message).join(' , ').replaceAll('_', ' ');
      showToastError(message);
    },
  });
}
