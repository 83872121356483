import { fetchImageAsBase64 } from 'utils/fetchImageAsBase64';
import { privateURL } from 'apis/AxiosConfig';
import { showToastError } from './showToastError';

const download = (fileName: string, file: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = file;
  link.click();
  link.remove();
};

export const downloadFile = async (
  filePath: string,
  fileName: string,
  file?: string
) => {
  if (file) {
    download(fileName, file);
    return;
  }

  try {
    const base64String = await fetchImageAsBase64(
      `${privateURL}/document${filePath}`
    );
    download(fileName, base64String);
  } catch (error) {
    if (error instanceof Error) showToastError(error);
  }
};
