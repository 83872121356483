import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';
export type AccountManagersType = OptionType[];
export function useAccountManagers() {
  return useQuery({
    queryKey: merchantKeys.accountManagers(),
    queryFn: () =>
      Services.get<AccountManagersType>(`merchants/account_managers/`, true),
  });
}
