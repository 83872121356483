import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { showToastError } from 'utils/showToastError';
import { OTPSchemaType as VerifyOTPRequestType } from './PhoneNumberForm';

export function useVerifyOTP(merchantId: string) {
  const queryClient = useQueryClient();

  const { mutate: verifyOTP, isLoading } = useMutation<
    unknown,
    Error,
    VerifyOTPRequestType
  >({
    mutationFn: ({ otp }) =>
      Services.patch<VerifyOTPRequestType, unknown>(
        { otp },
        `merchants/${merchantId}/phone_number/confirm_change/`,
        true
      ),
    onSuccess: () => {
      toast.success('Phone number has been saved');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.merchant(merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });

  return { isLoading, verifyOTP };
}
