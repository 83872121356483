import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import NewService from 'components/modals/newService.js/NewService';
import Contract from 'components/modals/contract/Contract';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import EmptyTable from 'components/Table/EmptyTable';
import { usePOS } from './usePOS';
import { useOnlineMethods } from './useOnlineMethods';
import { usePOSContract } from './usePOSContract';
import Filters from 'components/filters/Filters';

interface HeaderProps {
  title: string;
  url?: string;
}

const Header = ({ title, url }: HeaderProps) => (
  <header>
    <div>
      <Text text={title} fs={14} />
      <Text
        text="APPROVED"
        fs={8}
        component="span"
        className="status--active"
      />
    </div>
    {url && <Contract url={url} />}
  </header>
);

interface OnlineMethodTableProps {
  title: string;
  category: string;
}

const OnlineMethodTable = ({ title, category }: OnlineMethodTableProps) => {
  const { merchantId } = useParams();
  const [page, setPage] = useState(1);
  const { isLoading, data } = useOnlineMethods({ merchantId, category, page });

  return (
    <Table
      page={page}
      changePage={setPage}
      count={data?.count ?? 0}
      isLoading={isLoading}
      bgForHeadOnly
      topHeader={<Header title={title} />}
      headers={[
        'PAYMENT GATEWAY',
        // "REQUEST ID",
        'INTEGRATION ID',
        'TRNX FEE',
        'DISCOUNT FEE',
        'ACTIVATION DATE',
        'STATUS',
        'CONTRACTS',
      ]}
    >
      {data?.results?.map((item) => (
        <StyledTableRow key={`_${item.id}_online`} bgForHeadOnly>
          <StyledTableCell component="th" scope="row">
            <div className={styles.row}>
              <Text text={item.gateway_type} fs={10} />
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <Text text={`#${item.id}`} fs={10} />
          </StyledTableCell>
          <StyledTableCell>
            {item.trx_fee ? (
              <Text text={`#${item.trx_fee}`} fs={10} />
            ) : (
              <Text text={'-'} fs={10} />
            )}
          </StyledTableCell>
          <StyledTableCell>
            {item.discount_fee ? (
              <Text text={`#${item.discount_fee}`} fs={10} />
            ) : (
              <Text text={'-'} fs={10} />
            )}
          </StyledTableCell>
          <StyledTableCell>
            <Text
              text={format(parseISO(item.created_at), 'yyyy-MM-dd')}
              fs={10}
            />
          </StyledTableCell>
          <StyledTableCell>
            <div
              className={item.is_live ? 'status--active' : 'status--inactive'}
            >
              <Text text={item?.is_live ? 'LIVE' : 'NOT LIVE'} fs={10} />
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <header style={{ padding: 0 }}>
              {item.contract_link ? (
                <a href={item.contract_link} target={'_blank'} rel="noreferrer">
                  <Contract url={item.contract_link} />
                </a>
              ) : (
                <Text text={'-'} fs={10} />
              )}
            </header>
          </StyledTableCell>
        </StyledTableRow>
      ))}
      {data?.results?.length === 0 && <EmptyTable />}
    </Table>
  );
};

const POSTable = () => {
  const { t } = useTranslation();
  const { merchantId } = useParams();
  const [page, setPage] = useState(1);
  const { data: contract } = usePOSContract(merchantId);
  const [selectedKey, setSelectedKey] = useState('id');
  const [searchText, setSearchText] = useState('');
  const { isLoading, data } = usePOS({
    merchantId,
    page,
    selectedKey,
    searchText,
  });

  const FilteredBySearch = (value: string | number) => {
    setSearchText(value.toString());
  };

  return (
    <>
      <Filters
        inputLabel="Search POS"
        inputPlaceholder="Type to search list"
        onSearchChange={FilteredBySearch}
        searchSelectors={[
          { label: t('Terminal ID'), value: 'id' },
          { label: t('Terminal IMEI'), value: 'imei' },
          { label: t('Sub-user'), value: 'user__username' },
        ]}
        onSelectedKey={setSelectedKey}
      />
      <Table
        bgForHeadOnly
        page={page}
        changePage={setPage}
        isLoading={isLoading}
        count={data?.count ?? 0}
        topHeader={<Header title="POS" url={contract?.accept_contract} />}
        headers={[
          'TERMINAL ID',
          'TERMINAL IMEI',
          'SUB-USER',
          'INTEGRATION ID',
          'POS VERSION',
          'ACTIVATION DATE',
          'STATUS',
        ]}
      >
        {data?.results?.map((item) => (
          <StyledTableRow key={`__${item.id}_pos`} bgForHeadOnly>
            <StyledTableCell component="th" scope="row">
              <Link to={`${item.id}`}>
                <Text text={`#${item.id}`} fs={10} />
              </Link>
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item.imei ? `#${item.imei}` : '-'} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item.user_username || '-'} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={
                  item.integrations?.length
                    ? `#${item.integrations?.map((i) => i.id)?.join(', #')}`
                    : '-'
                }
                fs={10}
                style={{
                  maxWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
              {/* <Text text="#098765432" fs={10} /> */}
            </StyledTableCell>
            <StyledTableCell>
              {item.version ? (
                <Text text="Version" variables={{ 0: item.version }} fs={10} />
              ) : (
                <Text text="-" fs={10} />
              )}
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={format(parseISO(item.created_at), 'yyyy-MM-dd hh:mm a')}
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              <div
                className={item.active ? 'status--active' : 'status--inactive'}
              >
                <Text text={item?.active ? 'ACTIVE' : 'NOT ACTIVE'} fs={10} />
              </div>
            </StyledTableCell>
          </StyledTableRow>
        ))}

        {data?.results?.length === 0 && <EmptyTable />}
      </Table>
    </>
  );
};

function Service() {
  return (
    <div id={styles.services}>
      <div style={{ marginBlock: 30, textAlign: 'end' }}>
        <NewService />
      </div>

      {/* pos */}
      <POSTable />

      {/* Online Payments  */}
      <OnlineMethodTable title="Online Payments" category="online" />

      {/* App Payments  */}
      <OnlineMethodTable title="Tap on phone" category="app" />
    </div>
  );
}

export default Service;
