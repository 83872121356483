import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { AcceptBalanceDetailsType } from 'components/modals/merchant-drawer/accept-balance-Details/acceptBalanceDetails';

export type BalanceResponse = {
  accept_net_amount: number;
  bills_net_amount: number;
  accept_chargeback_amount: number;
  results: AcceptBalanceDetailsType[];
};

export function useBalance(merchantId?: string) {
  return useQuery({
    queryKey: merchantKeys.balance(merchantId),
    queryFn: () =>
      Services.get<BalanceResponse>(`merchants/${merchantId}/balance/`, true),
  });
}
