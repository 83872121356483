import { CSSProperties, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

import Select from 'components/input/Select';
import Text from 'components/text';
import Button from 'components/button/Button';
import { usePOSVersions } from './usePOSVersions';
import { useUpdatePOSVersion } from './useUpdatePOSVersion';
import { useDimensions } from 'hooks/useDimensions';

const posVersionUpdateSchemaValidation = z.object({
  pos_version_update: z
    .number()
    .min(1, { message: 'POS Version Update is required' }),
});

export type POSVersionUpdateSchemaType = z.infer<
  typeof posVersionUpdateSchemaValidation
>;

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 470,
  backgroundColor: 'var(--clr-light)',
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--clr-mainBlue)',
  paddingInline: 33,
  paddingBlock: 18,
};

const contentStyle = {
  paddingInline: 33,
};

export default function POSVersions() {
  const { merchantId, id } = useParams();
  const [open, setOpen] = useState(false);
  const down900 = useDimensions();
  const { data, isFetching: isFetchingPOSVersions } = usePOSVersions(
    merchantId ?? ''
  );
  const { updatePOSVersion, isLoading: isUpdating } = useUpdatePOSVersion();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<POSVersionUpdateSchemaType>({
    resolver: zodResolver(posVersionUpdateSchemaValidation),
  });

  const handleForm: SubmitHandler<POSVersionUpdateSchemaType> = (values) => {
    if (!merchantId || !id) return;

    const data = {
      pos_version_update: values.pos_version_update,
      allow_update: true,
      password: '12345',
    };

    updatePOSVersion(
      { data, merchantId, terminalId: id },
      {
        onSuccess: () => {
          reset();
          handleClose();
        },
      }
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const posVersionsList =
    data?.map((pos) => ({
      name: pos?.label,
      value: pos?.value,
    })) ?? [];

  return (
    <div>
      <div style={{ textAlign: 'end' }}>
        <Button text="POS Version Update" handleClick={() => handleOpen()} />
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="New Activity">
        <div style={{ ...style, width: down900 ? '90%' : 470 }}>
          <header style={headerStyle}>
            <Text
              text="POS Version Update"
              fs={10}
              style={{ color: 'white' }}
            />
            <div onClick={handleClose}>
              <Text
                text="Cancel"
                fs={10}
                style={{ color: 'white', cursor: 'pointer' }}
              />
            </div>
          </header>
          <div style={contentStyle}>
            <form onSubmit={handleSubmit(handleForm)}>
              <Box
                sx={{
                  m: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Text text="POS Versions" fs={12} />
                <Select
                  {...register('pos_version_update', {
                    required: 'Field is required',
                  })}
                  have_error={errors.pos_version_update?.message}
                  placeholder="Select from list"
                  data={posVersionsList}
                />
              </Box>

              <div style={{ textAlign: 'center', marginBottom: 30 }}>
                <Button
                  text="Save"
                  disabled={
                    isFetchingPOSVersions || isUpdating || !isDirty || !isValid
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
