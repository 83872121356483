import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type ChargebackStatusesResponse = { label: string; value: number }[];

export function useTransactionsChargebackStatuses(merchantId: string) {
  return useQuery({
    queryKey: merchantKeys.transactionsChargebackStatuses(merchantId),
    queryFn: () =>
      Services.get<ChargebackStatusesResponse>(
        `merchants/${merchantId}/transactions/chargeback_status/`,
        true
      ),
  });
}
