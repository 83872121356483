import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';

type AccountManagerResponse = {
  id: number;
};
type AccountManagerType = {
  data: { account_manager: string };
  merchantId: number;
};
type Error = {
  response: { data: { message: string } };
};

export function useUpdateAccountManager() {
  const queryClient = useQueryClient();
  return useMutation<AccountManagerResponse, Error, AccountManagerType>({
    mutationFn: ({ merchantId, data }) =>
      Services.patch<AccountManagerType['data'], AccountManagerResponse>(
        data,
        `merchants/${merchantId}/account_managers_edit/`,
        true
      ),
    onSuccess: (data) => {
      toast.success('Account manager has been updated');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.merchant('' + data?.id),
      });
    },
    onError: (err) => {
      const message = err?.response?.data?.message ?? 'Something went wrong';
      toast.error(message);
    },
  });
}
