import { forwardRef, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { SizeMe } from 'react-sizeme';
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

import Button from 'components/button/Button';
import { downloadFile } from 'utils/downloadFile';

interface ViewDocumentProps {
  filePath: string;
  file: string;
  skeleton: JSX.Element;
  documentContainerStyle?: SxProps<Theme>;
}

const ViewDocumentPages = forwardRef<HTMLDivElement, ViewDocumentProps>(
  ({ filePath, file, skeleton, documentContainerStyle }, ref) => {
    const [allPageNumbers, setAllPageNumbers] = useState<number[]>();

    const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
      const allPageNumbers = [...Array(pdf.numPages + 1).keys()].slice(1);
      setAllPageNumbers(allPageNumbers);
    };

    const handleDownload = () => {
      if (!filePath) return;
      const fileName = filePath.split('/').at(-1)?.split('.')[0];
      downloadFile(filePath, fileName ?? 'document', file);
    };

    return (
      <Box
        ref={ref}
        tabIndex={-1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ px: 3, py: 2, alignSelf: 'flex-end' }}>
          <Button text="Download Document" handleClick={handleDownload} />
        </Box>

        {!filePath?.includes('.pdf') && file && (
          <Box
            sx={{
              ...documentContainerStyle,
              width: '40%',
              boxShadow: 0,
            }}
          >
            <img src={file} alt="Document file" height="auto" width="100%" />
          </Box>
        )}

        {filePath?.includes('.pdf') && file && (
          <Box sx={documentContainerStyle}>
            <SizeMe>
              {({ size }) => (
                <Document
                  file={file}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={skeleton}
                >
                  <Box
                    sx={{
                      height: '70vh',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      border: '2px solid lightgray',
                      borderRadius: '5px',
                    }}
                  >
                    {allPageNumbers
                      ? allPageNumbers.map((pn) => (
                          <Page
                            key={`page-${pn}`}
                            width={size.width ? size.width : 1}
                            pageNumber={pn}
                            loading={skeleton}
                          />
                        ))
                      : undefined}
                  </Box>
                </Document>
              )}
            </SizeMe>
          </Box>
        )}
      </Box>
    );
  }
);

export default ViewDocumentPages;
