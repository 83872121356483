import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Input from 'components/input/Input';
import Button from 'components/button/Button';
import Select from 'components/input/Select';
import {
  useAddAccount,
  useBankCurrencies,
  useBankStatuses,
  useBanks,
} from './useBankAccountDetails';
import { useParams } from 'react-router-dom';
import Text from 'components/text';
import { Box, FormControlLabel, Grid } from '@mui/material';
import Checkbox from 'components/checkbox';

const invalidCharacterCheck = (value: string) => /^[\w\-\s]+$/gi.test(value);
const invalidCharacterCheckMsg = 'Special characters are not allowed';

const bankAccountSchemaValidation = z.object({
  account_name: z
    .string()
    .max(70, 'Maximum 70 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Account name is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg),
  account_number: z
    .string()
    .max(20, 'Maximum 20 characters are allowed')
    .refine((value) => value.trim().length, 'Account number is required')
    .refine((value) => !!Number(value), 'Please, enter a valid account number'),
  bank_name: z
    .string()
    .max(50, 'Maximum 50 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Bank name is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg),
  bank_branch: z
    .string()
    .max(50, 'Maximum 50 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Bank branch is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg),
  bank_swift: z
    .string()
    .max(11, 'Maximum 11 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Bank swift is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg),
  contact_mobile_number: z
    .string()
    .max(15, 'Please, enter a valid mobile number')
    .refine((value) => value.trim().length, 'Mobile number is required')
    .refine((value) => !!Number(value), 'Please, enter a valid mobile number'),
  contact_email: z
    .string()
    .max(50, 'Maximum 50 characters are allowed')
    .email({
      message: 'Must be a valid email',
    })
    .refine((value) => value.trim().length > 1, 'Contact email is required'),
  iban: z
    .string()
    .max(34, 'Maximum 34 characters are allowed')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg)
    .optional()
    .or(z.literal('')),
  status: z.string().optional(),
  currency: z.string().optional(),
  source_bank: z.number().optional().or(z.literal('')),
  is_active: z.boolean().optional(),
});

export type AddBankAccountSchemaFormType = z.infer<
  typeof bankAccountSchemaValidation
>;

interface AddAccountFormProps {
  onClose: () => void;
}

function AddAccountForm({ onClose }: Readonly<AddAccountFormProps>) {
  const { merchantId } = useParams();
  const { mutate, isLoading } = useAddAccount(merchantId);
  const { data: { statuses = [] } = {} } = useBankStatuses(merchantId);
  const { data: { currencies = [] } = {} } = useBankCurrencies(merchantId);
  const { data: { available_banks = [] } = {} } = useBanks();

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<AddBankAccountSchemaFormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(bankAccountSchemaValidation),
  });

  const handleForm: SubmitHandler<AddBankAccountSchemaFormType> = (values) => {
    !values.currency && delete values.currency;
    !values.source_bank && delete values.source_bank;

    mutate(values, {
      onSuccess: () => {
        reset();
        onClose();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <Grid
        container
        columnSpacing={4}
        rowSpacing={1}
        alignItems="center"
        sx={{ py: 2, px: 4, '& .MuiFormControl-root': { width: '100%' } }}
      >
        <Grid item xs={12} lg={6}>
          <Controller
            name="account_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Account Holder"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name="bank_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Bank Name"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name="bank_branch"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Bank Branch"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="account_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Account Number"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="iban"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="IBAN" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="bank_swift"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="BANK Swift"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="contact_mobile_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Contact Mobile Number"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="contact_email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                have_error={error?.message ?? ''}
                label="Contact Email"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="currency"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Select currency" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                data={[{ label: 'N/A', value: '' }, ...currencies]}
                placeholder="Currency"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Select status" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                data={statuses}
                placeholder="Status"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Controller
            name="source_bank"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Select source bank" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                data={[{ label: 'N/A', value: '' }, ...available_banks]}
                placeholder="Source bank"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControlLabel
            label={
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Text text="Is Active" />
                <Text
                  text="(optional)"
                  fs={10}
                  sx={{ color: 'var(--clr-gray)' }}
                />
              </Box>
            }
            control={
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <Checkbox checked={field.value} {...field} />
                )}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center', py: 2 }}>
          <Button text="Save changes" disabled={isLoading || !isValid} />
        </Grid>
      </Grid>
    </form>
  );
}

export default AddAccountForm;
