import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Select from 'components/input/Select';
import { useSalesOwner } from './useSalesOwner';
import { useUpdateSalesOwner } from './useUpdateSalesOwner';
import Button from 'components/button/Button';
import { MerchantDetailsType } from 'pages/main/merchants/marchent/useMerchant';

const salesOwnerSchemaValidation = z.object({
  sales_owner: z.string(),
});

export type SalesOwnerSchemaType = z.infer<typeof salesOwnerSchemaValidation>;

interface SalesOwnerFormProps {
  merchantDetails: MerchantDetailsType;
}

function SalesOwnerForm({ merchantDetails }: SalesOwnerFormProps) {
  const { data: sales_owners = [] } = useSalesOwner();
  const { updateSalesOwner, isUpdatingSalesOwner } = useUpdateSalesOwner(
    merchantDetails?.id!
  );

  const salesOwner = sales_owners?.find(
    (item) => item.value === merchantDetails?.sales_owner
  )?.label;

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm<SalesOwnerSchemaType>({
    resolver: zodResolver(salesOwnerSchemaValidation),
    defaultValues: {
      sales_owner: salesOwner ?? '',
    },
  });

  const handleForm: SubmitHandler<SalesOwnerSchemaType> = (data) => {
    updateSalesOwner(data);
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <div className="row">
        <div>
          <Select
            {...register('sales_owner')}
            label="Sales agent"
            data={sales_owners?.map((item) => ({
              value: item?.label,
              label: item?.label,
            }))}
            have_error={errors.sales_owner?.message}
            placeholder="Sales agent"
          />
        </div>
      </div>
      <div className="row submitBtn">
        <Button
          text="Save"
          disabled={isUpdatingSalesOwner || !isDirty || !isValid}
        />
      </div>
    </form>
  );
}

export default SalesOwnerForm;
