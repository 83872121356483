import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { MerchantDetailsType } from './marchent/useMerchant';

type MerchantsResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: MerchantDetailsType[];
};

interface IUseMerchants {
  page: number;
  searchText: string;
  status: string;
  sort: string;
  selectedKey: string;
}

export function useMerchants(args: IUseMerchants) {
  const { page, searchText, status, sort, selectedKey } = args;

  let searchVal = searchText;

  if (selectedKey === 'phone_number') searchVal = encodeURIComponent(searchVal);

  const searchKey = searchVal
    ? selectedKey === ''
      ? '&id='
      : `&${selectedKey}=`
    : '';

  return useQuery({
    queryKey: merchantKeys.merchants(args),
    queryFn: () =>
      Services.get<MerchantsResponse>(
        `merchants/?page=${page}${searchKey}${searchVal}${status}${sort}`,
        true
      ),
  });
}
