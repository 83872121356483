import { useState } from 'react';

import MerchantDrawer from '../merchant-drawer/MerchantDrawer';
import BankAccountForm from './EditAccountForm';
import { IBankAccountEdit } from './useBankAccountDetails';

interface EditAccountProps {
  merchantId?: string;
  bankAccount: IBankAccountEdit;
  subtitle?: string;
}

const EditAccount = ({
  merchantId = '',
  bankAccount,
  subtitle = 'Edit details',
}: EditAccountProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClose = () => setIsDrawerOpen(false);

  return (
    <MerchantDrawer
      title="Edit bank account details"
      subtitle={subtitle}
      setDrawerOpen={setIsDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <BankAccountForm
        bankAccount={bankAccount}
        merchantId={merchantId}
        onClose={handleClose}
      />
    </MerchantDrawer>
  );
};
export default EditAccount;
