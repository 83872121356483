import pMemoize from 'p-memoize';
import ExpiryMap from 'expiry-map';
import { axiosPublic } from 'apis/AxiosConfig';
import { showToastError } from './showToastError';

const refreshToken = async (
  args: { signIn?: (token: string) => void } = {}
) => {
  const refreshTokenObj = { refresh: sessionStorage.getItem('refresh_token') };
  const { signIn } = args;

  try {
    const response = await axiosPublic.post('/token/refresh/', refreshTokenObj);
    const accessToken = response?.data?.access;

    if (!accessToken) throw new Error(response?.data?.detail?.message);

    signIn?.(accessToken);
    sessionStorage.setItem('token', accessToken);
    sessionStorage.setItem('lastRefreshToken', new Date().getTime().toString());

    return accessToken;
  } catch (error) {
    if (error instanceof Error) showToastError({ message: error.message });
    sessionStorage.clear();
    window.location.replace('/auth/login');
  }
};

const cache = new ExpiryMap(10000);
export const memoizedRefreshToken = pMemoize(refreshToken, { cache });
