import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { t } from 'i18next';
import i18n from 'i18n';
import { Select as SelectAntD } from 'antd';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';

import Search from 'components/input/Search';
import Text from 'components/text';
import styles from './formFilters.module.scss';
import { CITY, useUnderwritingFilters } from './useUnderwritingFilters';
import MyDatePicker from 'components/datePicker/DatePicker';
import { RangeValue } from 'rc-picker/lib/interface';
import CustomButton from 'components/button/Button';

const { RangePicker } = MyDatePicker;

export type UNDERWRITING_FILTERS_VALUES = {
  merchant_id?: string | number;
  company_name?: string;
  date?: any;
  status?: string | number;
  channel?: string;
  service?: string;
  governorate?: string;
  city?: string;
  neighborhood?: string;
};
const defaultValues: UNDERWRITING_FILTERS_VALUES = {
  merchant_id: undefined,
  company_name: undefined,
  date: undefined,
  status: undefined,
  channel: undefined,
  service: undefined,
  governorate: undefined,
  city: undefined,
  neighborhood: undefined,
};
interface MainFilterProps {
  isPendingRequests: boolean;
  onSubmit: (values: UNDERWRITING_FILTERS_VALUES) => void;
}
const FormFilters = ({ isPendingRequests, onSubmit }: MainFilterProps) => {
  const { data } = useUnderwritingFilters();
  const [searchKey, setSearchKey] = useState<string>('merchant_id');
  const [searchValue, setSearchValue] = useState<string>();
  const [citiesList, setCitiesList] = useState<CITY[]>();
  const [neighborhoodsList, setNeighborhoodsList] = useState<string[]>();
  const { handleSubmit, getValues, setValue, reset, watch } = useForm({
    defaultValues,
  });
  const [calendarDate, setCalendarDate] = useState<null | RangeValue<Date>>(
    null
  );

  const { city, governorate } = watch();

  const handelSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(searchKey as keyof typeof defaultValues, value);
    setSearchValue(value);
  };

  const handelSelectedKey = (key: string) => {
    setSearchKey(key);
    setSearchValue(undefined);
    setValue('company_name', defaultValues['company_name']);
    setValue('merchant_id', defaultValues['merchant_id']);
  };

  const handelChangeGovernorate = (val: string) => {
    setValue('governorate', val);
    setValue('city', undefined);
    setValue('neighborhood', undefined);
    const governorate = val?.split('=')?.[1];
    setCitiesList(
      data?.governorates?.find((item) => item.name === governorate)?.cities
    );
  };

  const handelChangeCity = (val: string) => {
    setValue('city', val);
    setValue('neighborhood', undefined);
    const city = val?.split('=')?.[1];
    setNeighborhoodsList(
      citiesList?.find((item) => item.name === city)?.neighborhoods
    );
  };

  useEffect(() => {
    reset(defaultValues);
    setSearchValue(undefined);
    setCalendarDate(null);
  }, [isPendingRequests, reset]);

  const onOpenChange = (open: boolean) => {
    open
      ? setCalendarDate([null, null] as unknown as null)
      : setCalendarDate(null);
  };

  const disabledDate = (current: Date): boolean => {
    const startDate = calendarDate?.[0];
    const endDate = calendarDate?.[1];
    const currentDateString = new Date(current).toDateString();

    return (
      (current && current.valueOf() >= Date.now()) ||
      (startDate && currentDateString === new Date(startDate).toDateString()) ||
      (endDate && currentDateString === new Date(endDate).toDateString()) ||
      false
    );
  };

  return (
    <form id={styles.filters} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
      >
        <Grid
          item
          sx={{
            '& .ant-space-compact': { width: '100%' },
            '& .ant-input': { width: '100%' },
          }}
        >
          <div className={styles.inputsGroup}>
            <div className={styles.input}>
              <Search
                // width={745}
                label={t('Search for merchant')}
                searchSelectors={[
                  { label: t('Merchant Id'), value: 'merchant_id' },
                  { label: t('Merchant Name'), value: 'company_name' },
                ]}
                placeholder={t('inputPlaceholder')}
                onChange={handelSearchOnChange}
                onSelectedKey={handelSelectedKey}
                value={searchValue}
              />
            </div>
          </div>
          <div className={styles.inputsGroup}>
            <div className={styles.input}>
              <RangePicker
                onCalendarChange={(val) => setCalendarDate(val)}
                onChange={(value) => setValue('date', value)}
                onOpenChange={onOpenChange}
                locale={i18n?.language as unknown as PickerLocale}
                placeholder={[t('Start date'), t('End date')]}
                value={getValues().date}
                disabledDate={disabledDate}
                placement="bottomLeft"
              />
            </div>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={(val) => setValue('channel', val)}
                placeholder={`${t('Filter by Channel')}`}
                options={data?.channels?.map((channel) => ({
                  label: channel,
                  //index must be removed
                  value: `&channel=${channel}`,
                }))}
                value={getValues().channel}
              />
            </div>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={(val) => setValue('service', val)}
                placeholder={`${t('Filter by service')}`}
                options={data?.service?.map((item) => ({
                  label: item,
                  value: `&service=${item}`,
                }))}
                value={getValues().service}
              />
            </div>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={(val) => setValue('status', val)}
                placeholder={`${t('Filter by Status')}`}
                options={
                  isPendingRequests
                    ? [
                        { label: t('Pending'), value: '&status=PENDING' },
                        { label: t('Updated'), value: '&status=UPDATED' },
                      ]
                    : [
                        { label: t('Approved'), value: '&status=APPROVED' },
                        { label: t('Rejected'), value: '&status=REJECTED' },
                      ]
                }
                value={getValues().status}
              />
            </div>
          </div>
          <div className={`${styles.inputsGroup} ${styles.mt30}`}>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={handelChangeGovernorate}
                placeholder={`${t('Filter by governorate')}`}
                options={data?.governorates?.map((governorate) => ({
                  label: governorate?.name,
                  value: `&governorate=${governorate?.name}`,
                }))}
                value={getValues().governorate}
              />
            </div>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={handelChangeCity}
                placeholder={`${t('Filter by city')}`}
                options={citiesList?.map((city) => ({
                  label: city?.name,
                  value: `&city=${city?.name}`,
                }))}
                value={getValues().city}
                disabled={governorate === undefined}
              />
            </div>
            <div className={styles.input}>
              <SelectAntD
                dropdownStyle={{
                  minWidth: 250,
                }}
                onChange={(val) => setValue('neighborhood', val)}
                placeholder={`${t('Filter by area')}`}
                options={neighborhoodsList?.map((neighborhood) => ({
                  label: neighborhood,
                  value: `&neighborhood=${neighborhood}`,
                }))}
                value={getValues().neighborhood}
                disabled={governorate === undefined || city === undefined}
              />
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.controlButtons}>
            <CustomButton text={t('Search')} />
            <Button
              variant="outlined"
              size="large"
              type="submit"
              onClick={() => {
                reset();
                setSearchValue(undefined);
              }}
            >
              <Text
                text={t('Reset')}
                fs={10}
                sx={{ textTransform: 'capitalize', width: 100 }}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
export default FormFilters;
