import { RouterProvider } from 'react-router-dom';
import Router from 'router';
import { pdfjs } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import 'i18n/index';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

function App() {
  return <RouterProvider router={Router} />;
}

export default App;
