import React, { forwardRef, Fragment, useState } from 'react';
import Text from 'components/text';
import SearchIcon from 'assets/images/icons/search.svg';
import { Input, Select, Space } from 'antd';
import { OptionType } from 'components/filters/Filters';
import { t } from 'i18next';

interface SearchProps {
  onTextChange?: (value: string | number) => void;
  searchSelectors: OptionType[];
  onSelectedKey: (
    value: string
  ) => void | React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isNeedSorting?: boolean;
  value?: string;
  width?: number;
}

const Search = forwardRef<unknown, SearchProps>(function Search(
  {
    onChange,
    label = '',
    onTextChange,
    searchSelectors,
    onSelectedKey,
    value,
    width,
  },
  ref
) {
  const [selected, setSelected] = useState(searchSelectors[0].label);

  const changeSelcetor = (value: string) => {
    onSelectedKey(value);
    let selectedObj =
      searchSelectors.find((item) => item.value === value) ??
      searchSelectors[0];
    setSelected(selectedObj.label);
  };

  return (
    <Fragment>
      <Text text={label} fs={10} />
      <Space.Compact>
        <Select
          defaultValue={`${searchSelectors?.[0]?.value ?? ''}`}
          options={searchSelectors}
          onChange={changeSelcetor}
          dropdownStyle={{
            minWidth: 200,
          }}
        />
        <Input
          addonBefore={<img src={SearchIcon} alt="search icon" width={15} />}
          style={{ minWidth: width ? width : 300 }}
          defaultValue=""
          value={value}
          placeholder={`${t('Search with')} ${selected}`}
          onChange={onChange}
          onKeyDown={(e) => {
            const target = e.target as HTMLInputElement;
            if (e.keyCode === 13) {
              onTextChange?.(target.value);
            }
          }}
        />
      </Space.Compact>
    </Fragment>
  );
});
export default Search;
