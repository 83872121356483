import { ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';

import Login from 'pages/auth/login';
import { useAuthContext } from 'contexts/AuthContext';

export default function ProtectedRoute({ children }: { children: ReactNode }) {
  const { userToken } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated && userToken) return children;

  return <Login />;
}
