import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

interface TransferDetailsResponse {
  id: number;
  bank_account: string;
  created_at: string;
  settlement_currency: string;
  bank_name: string;
  masked_bank_account: string;
  transfer_fee_pound: number;
  amount_net_pound: number;
  amount_gross_pound: number;
  amount_refund_pound: number;
  amount_fees_pound: number;
  amount_vat_pound: number;
  trx_ids: number[];
  integration_ids: number[];
  amount_chargeback_pound: number;
  amount_subscription_pound: number;
  amount_deposit_pound: number;
  amount_delivery_fee_pound: number;
  iso_status: string;
  iso_failure_reason: string;
  iso_batch_id: number;
  iso_transaction_id: number;
  iso_settlement_date: string;
  iso_amount: number;
  transfer_report: string;
  status: number;
  amount_balance_transfer_pound: number;
}

interface IUseTransferDetails {
  merchantId?: string;
  transferId?: string;
  fetch: boolean;
}

export function useTransferDetails({
  merchantId,
  transferId,
  fetch,
}: IUseTransferDetails) {
  return useQuery({
    queryKey: merchantKeys.transferDetails(merchantId, transferId),
    queryFn: () =>
      Services.get<TransferDetailsResponse>(
        `merchants/${merchantId}/transfers/${transferId}/`,
        true
      ),
    enabled: fetch,
  });
}
