import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type TransactionsResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: TransactionsType[];
};

export type TransactionsType = {
  id: number;
  order_id: number;
  amount_cents: number;
  integration_id: number;
  gateway_type: string;
  integration_type: string;
  transaction_type: string;
  transaction_status: string;
  is_refund: boolean;
  is_refunded: boolean;
  is_void: boolean;
  is_voided: boolean;
  is_auth: boolean;
  is_capture: boolean;
  is_captured: boolean;
  has_parent_transaction: boolean;
  parent_transaction: unknown;
  child_transactions: unknown[];
  chargeback_status: null | string;
  is_chargeback: boolean;
  created_at: string;
  source_data: { type: string; sub_type: string; pan: string };
  installment_info: InstallmentInfoType;
  accept_fees_cents: number;
  vat_cents: number;
  bank_related: string;
  terminal: unknown;
  api_source: string;
  success: boolean;
  pending: boolean;
  service_name: string;
  amount_in_pounds: string;
  currency: string;
  accept_fees_in_pounds: string;
  vat_in_pounds: string;
  data_message: string;
};

type InstallmentInfoType = {
  items: string[];
  administrative_fees: unknown;
  down_payment: unknown;
  tenure: string;
  finance_amount: unknown;
};

interface IUseTransactions {
  merchantId?: string;
  page: number;
  searchText: string;
  status: string;
  date: { from?: string; to?: string };
  sort: string;
  selectedKey: string;
  gatewayType: string;
  chargebackStatus: string;
  transactionType: string;
}

export function useTransactions(args: IUseTransactions) {
  const {
    merchantId,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
    gatewayType,
    chargebackStatus,
    transactionType,
  } = args;

  const dateFormat = date?.from
    ? `&created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';

  const searchKey = searchText
    ? selectedKey === ''
      ? '&id='
      : `&${selectedKey}=`
    : '';

  return useQuery({
    queryKey: merchantKeys.transactions(args),
    queryFn: () =>
      Services.get<TransactionsResponse>(
        `merchants/${merchantId}/transactions/?page=${page}${searchKey}${searchText}${status}${dateFormat}${sort}${gatewayType}${chargebackStatus}${transactionType}`,
        true
      ),
  });
}
