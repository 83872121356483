import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button/Button';
import Container from 'components/layouts/Container';
import styles from 'styles/auth/index.module.scss';
import Paymob from 'assets/images/logo.svg';
import { useAuthContext } from 'contexts/AuthContext';

function Login() {
  const { userToken, signIn } = useAuthContext();
  let navigate = useNavigate();

  useEffect(() => {
    if (userToken) navigate('/', { replace: true });
  }, [navigate, userToken]);

  const { handleSubmit } = useForm({});

  const handleForm = () => signIn();

  return (
    <main id={styles.main}>
      <Container>
        <form onSubmit={handleSubmit(handleForm)}>
          <div className={styles.brandLogo}>
            <img src={Paymob} alt="paymob logo" />
          </div>
          <div className="row submitBtn">
            <Button text="Login" style={{ width: '100%' }} />
          </div>
        </form>
      </Container>
    </main>
  );
}

export default Login;
