import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';
type ContactPersonAuthoritiesType = OptionType[];
export function useContactPersonAuthorities() {
  return useQuery({
    queryKey: merchantKeys.contactPersonAuthorities(),
    queryFn: () =>
      Services.get<ContactPersonAuthoritiesType>(
        `merchants/contact_person_authorities/`,
        true
      ),
  });
}
