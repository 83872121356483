import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
type BanksType = {
  results: SingleBank[];
  count: number;
};
type SingleBank = {
  is_default: boolean;
  id: number;
  account_name: string;
  bank_name: string;
  account_number: string;
  bank_branch: string;
  iban: string;
  status: string;
  merchant: number;
  currency: string;
  is_internal: boolean;
  is_active: boolean;
  masked_account_number: string;
  bank_swift: string;
  contact_mobile_number: string;
  contact_email: string;
  source_bank: number;
  source_bank_name: string;
  is_locked: boolean;
  saved_for_settlement: boolean;
  is_deleted: boolean;
  created_at: string;
};

interface IUseBanks {
  merchantId?: string;
  page?: number;
  isDefault?: boolean;
}

export function useBanks(args: IUseBanks) {
  const page = args?.page ? `page=${args.page}` : '';
  const isDefault = args?.isDefault ? 'is_default=True' : '';

  const query = useQuery({
    queryKey: merchantKeys.banks(args),
    queryFn: () =>
      Services.get<BanksType>(
        `merchants/${args.merchantId}/bank/?${page}${isDefault}`,
        true
      ),
  });

  return { ...query, data: query?.data! };
}
