import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Container from 'components/layouts/Container';
import Arrow from 'assets/images/icons/left-arrow.png';
import { useBillDetails } from './useBillDetails';
import { Divider } from '@mui/material';

function BillsBalanceDetails() {
  const { merchantId, id } = useParams();
  const { data } = useBillDetails(merchantId!, id!);

  return (
    <div id={styles.transaction_Detail}>
      <Container>
        <header>
          <Link to={`/${merchantId}/bills-balance`}>
            <img src={Arrow} alt="arrow icon" className="details_arrow" />
            <Text
              text="Bills Balance"
              variables={{ 0: `#${data?.id}` }}
              variant={'h3'}
              fs={14}
            />
          </Link>
        </header>

        <div className={styles.details}>
          <header>
            <Text text="Bills Balance details" />
          </header>
          <div className={styles.content}>
            <section>
              <div>
                <Text text="Transaction ID" className={styles.key} fs={11} />
                <Text text={`#${data?.id}`} className={styles.value} fs={12} />
              </div>
              <div>
                <Text text="Transaction date" className={styles.key} fs={11} />
                {data?.created_at && (
                  <Text
                    text={format(
                      parseISO(data?.created_at),
                      'yyyy/MM/dd - hh:mm a'
                    )}
                    className={styles.value}
                    fs={11}
                  />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="Payment method" className={styles.key} fs={11} />
                <Text
                  text={data?.gateway_type!}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Bank" className={styles.key} fs={11} />
                {data?.bank_related ? (
                  <Text text={data?.bank_related} fs={11} />
                ) : (
                  <Text text={'-'} fs={11} />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="Fees" className={styles.key} fs={11} />
                <Text
                  text="CENTS"
                  variables={{ 0: data?.accept_fees_cents }}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Vat" className={styles.key} fs={11} />
                <Text text="CENTS" variables={{ 0: data?.vat_cents }} fs={11} />
              </div>
            </section>

            <section>
              <div>
                <Text text="Order ID" className={styles.key} fs={11} />
                <Text
                  text={data?.order_id ? `#${data?.order_id}` : '-'}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Terminal ID" className={styles.key} fs={11} />
                {data?.terminal ? (
                  <Text text={`#${data?.terminal}`} fs={11} />
                ) : (
                  <Text text={'-'} fs={11} />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="Transaction type" className={styles.key} fs={11} />
                <Text
                  text={data?.transaction_type!}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text text="Chargeback Status" className={styles.key} fs={11} />
                <Text
                  text={data?.chargeback_status || '-'}
                  className={styles.value}
                  fs={11}
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Integration ID" className={styles.key} fs={11} />
                <Text
                  text={data?.integration_id ? `#${data.integration_id}` : '-'}
                  className={styles.value}
                  fs={11}
                />
              </div>
              <div>
                <Text
                  text="Transaction Status"
                  className={styles.key}
                  fs={11}
                />
                <div
                  className={
                    data?.success ? 'status--active' : 'status--inactive'
                  }
                >
                  <Text
                    text={data?.success ? 'SUCCESS' : 'NOT SUCCESS'}
                    fs={8}
                  />
                </div>
              </div>
            </section>

            <Divider variant="middle" />

            <section>
              <div>
                <Text
                  text="Installment Information"
                  className={styles.key}
                  fs={11}
                  sx={{ fontStyle: 'italic' }}
                />
              </div>
            </section>

            <section>
              <div>
                <Text
                  text="Administrative Fees"
                  className={styles.key}
                  fs={11}
                />
                {data?.installment_info?.administrative_fees ? (
                  <Text
                    text="EGP"
                    variables={{
                      0: data?.installment_info?.administrative_fees,
                    }}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text="-" className={styles.value} fs={11} />
                )}
              </div>
              <div>
                <Text text="Down Payment" className={styles.key} fs={11} />
                {data?.installment_info?.down_payment ? (
                  <Text
                    text="EGP"
                    variables={{
                      0: data?.installment_info?.down_payment,
                    }}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text="-" className={styles.value} fs={11} />
                )}
              </div>
            </section>

            <section>
              <div>
                <Text text="Finance Amount" className={styles.key} fs={11} />
                {data?.installment_info?.finance_amount ? (
                  <Text
                    text="EGP"
                    variables={{
                      0: data?.installment_info?.finance_amount,
                    }}
                    className={styles.value}
                    fs={11}
                  />
                ) : (
                  <Text text="-" className={styles.value} fs={11} />
                )}
              </div>
              <div>
                <Text text="Tenure" className={styles.key} fs={11} />
                <Text
                  text={data?.installment_info?.tenure || '-'}
                  className={styles.value}
                  fs={11}
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Items" className={styles.key} fs={11} />
                {!data?.installment_info?.items?.length ? (
                  <Text
                    text={data?.installment_info?.items?.join(', ')!}
                    fs={10}
                  />
                ) : (
                  <Text text="-" className={styles.value} fs={11} />
                )}
              </div>
            </section>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BillsBalanceDetails;
