/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

let current_origin = window.location.origin;
export const msalConfig = {
  auth: {
    clientId: 'f3341fba-1439-47ad-89fb-371212047b02', // This is the ONLY mandatory field that you need to supply.
    authority:
      'https://login.microsoftonline.com/96bf2027-97ea-4dfc-8f5b-7121b770f292', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri:
      current_origin +
      (current_origin.includes('localhost') ? '/paymob-crm' : '/'), // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['User.Read'],
};
