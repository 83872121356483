import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';

type POSVersionsType = OptionType;

export function usePOSVersions(merchantId: string) {
  return useQuery({
    queryKey: merchantKeys.posVersions(merchantId),
    queryFn: () =>
      Services.get<POSVersionsType[]>(
        `merchants/${merchantId}/terminals/pos_versions/`,
        true
      ),
  });
}
