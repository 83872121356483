import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type POSContractResponse = { accept_contract: string };

export function usePOSContract(merchantId = '') {
  return useQuery({
    queryKey: merchantKeys.posContract(merchantId),
    queryFn: () =>
      Services.get<POSContractResponse>(
        `merchants/${merchantId}/terminals/contract/`,
        true
      ),
  });
}
