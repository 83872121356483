export function blobToBase64(blob: Blob, isPDF: boolean) {
  return new Promise<string>((resolve) => {
    if (isPDF) {
      resolve(URL.createObjectURL(blob));
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        resolve(reader.result as string);
      };
    }
  });
}
