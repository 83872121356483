import { useQuery } from '@tanstack/react-query';
import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type UnderwritingFiltersResponse = {
  channels: string[];
  service: string[];
  governorates?: GOVERNORATES[];
};
export type GOVERNORATES = { name?: string; cities?: CITY[] };
export type CITY = {
  name?: string;
  neighborhoods?: string[];
};

export const useUnderwritingFilters = () => {
  return useQuery({
    queryKey: underwritingKeys.filters(),
    queryFn: () =>
      Services.get<UnderwritingFiltersResponse>(
        `available_filters/list/`,
        true
      ),
  });
};
