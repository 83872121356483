import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type OnlineMethodsResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: OnlineMethodsType[];
};

type OnlineMethodsType = {
  id: number;
  gateway_type: string;
  integration_type: string;
  created_at: string;
  is_live: boolean;
  contract_link?: string;
  trx_fee_id?: number;
  trx_fee?: number;
  discount_fee_id?: number;
  discount_fee?: number;
};

interface IUseOnlineMethods {
  merchantId?: string;
  category: string;
  page: number;
}

export function useOnlineMethods({
  merchantId,
  category,
  page,
}: IUseOnlineMethods) {
  return useQuery({
    queryKey: merchantKeys.onlineMethods(merchantId, category, page),
    queryFn: () =>
      Services.get<OnlineMethodsResponse>(
        `merchants/${merchantId}/online_methods/?category=${category}&page=${page}`,
        true
      ),
  });
}
