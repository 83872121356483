import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { isAxiosError } from 'axios';

import { loginRequest } from 'authConfig';
import { axiosPublic } from 'apis/AxiosConfig';
import { showToastError } from 'utils/showToastError';

export default function useAuth() {
  const { instance } = useMsal();
  const [userToken, setUserToken] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  const signIn = () => instance.loginRedirect(loginRequest);

  const signOut = async () => {
    setIsLoading(true);
    try {
      const refreshToken = sessionStorage.getItem('refresh_token');
      sessionStorage.clear();
      setUserToken(null);

      if (refreshToken) {
        await axiosPublic.post('/logout/', { refresh: refreshToken });
      }

      await instance.logoutRedirect();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data : error;
      showToastError(errorMessage);
    } finally {
      window.location.replace('/auth/login');
    }
  };

  return { signIn, signOut, userToken, setUserToken, isLoading, setIsLoading };
}
