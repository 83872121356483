import React, { useEffect, useState, Fragment } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';

import styles from './navbar.module.scss';
import Text from 'components/text';
import Paymob from 'assets/images/logo.svg';
import NavModal from 'components/modals/nav-modal/NavModal';
import Container from 'components/layouts/Container';
import { useDimensions } from 'hooks/useDimensions';
import { useAuthContext } from 'contexts/AuthContext';

function Navbar() {
  const { accounts } = useMsal();
  const { signOut, userToken } = useAuthContext();
  const { i18n } = useTranslation();
  const down1200 = useDimensions({ breakpoint: 'lg' });
  const [language, setLanguage] = useState('EN');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setLanguage(localStorage.getItem('i18nextLng') ?? 'en');
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLanguage = (language: 'en' | 'ar') => {
    i18n.changeLanguage(language);
    setLanguage(language);
    setAnchorEl(null);
  };

  const handleLogout = () => signOut();

  return (
    <nav id={styles.navbar}>
      <Container>
        <div className={styles.content}>
          <div className={styles.brandLogo}>
            <img src={Paymob} alt="paymob logo" />
          </div>
          {down1200 ? (
            <NavModal />
          ) : (
            <div className={styles.data}>
              {userToken && (
                <Fragment>
                  <Text text={accounts?.[0]?.name ?? ''} component="h5" />

                  <div
                    onClick={() => handleLogout()}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Text text={'Logout'} component="h5" />
                  </div>
                </Fragment>
              )}

              <div>
                <button
                  className={styles.languageBtn}
                  type="button"
                  onClick={handleClick}
                  style={{ textTransform: 'uppercase' }}
                  title="change language"
                >
                  <Text text={language} component="h5" />
                </button>
              </div>

              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => handleChangeLanguage('en')}>
                  <Text text="EN" component="h5" />
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('ar')}>
                  <Text text="AR" component="h5" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </Container>
    </nav>
  );
}

export default Navbar;
