import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import * as XLSX from 'xlsx';

import Button from 'components/button/Button';
import Filters from 'components/filters/Filters';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import EmptyTable from 'components/Table/EmptyTable';
import { useVisits } from './useVisits';
import { useVisitsReport } from './useVisitsReport';
import { useTranslation } from 'react-i18next';

function Visits() {
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [date, onDateChange] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const [isSelectedDates, setIsSelectedDates] = useState(false);

  const filters = {
    merchantId,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
  };

  const { data, isLoading } = useVisits(filters);
  const { refetch } = useVisitsReport({ merchantId, status, date });

  const exportRecords = async () => {
    const { isError, data } = await refetch();

    if (isError) return;

    let arr = data?.split('\r\n');
    let newArr = [];

    if (!arr) return;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let splitedStr = element.split(',');
      newArr.push(splitedStr);
    }

    let workbook = XLSX.utils.book_new(),
      worksheet = XLSX.utils.aoa_to_sheet(newArr);
    workbook.SheetNames.push('First');
    workbook.Sheets['First'] = worksheet;
    XLSX.writeFile(workbook, 'visits_records.xlsx');
  };

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    setPage(1);
    let dates = value ?? {};
    onDateChange(dates);
    setIsSelectedDates(Boolean(value) || false);
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value.toString());
  };

  return (
    <div>
      <Filters
        showDate
        inputLabel="Search for visits"
        inputPlaceholder="Search by Agent name"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        selectPlaceholder="Filter by reason for visit"
        statusData={[]}
        searchSelectors={[
          { label: t('VISIT ID'), value: '' },
          // { name: "Type", value: "type" },
        ]}
        onSelectedKey={setSelectedKey}
        onSortChange={FilteredBySort}
        isNeedSorting
      />

      <div style={{ marginBottom: 30, textAlign: 'end' }}>
        <Button
          text="Export visits timeline"
          handleClick={() => exportRecords()}
          disabled={!isSelectedDates}
          title={!isSelectedDates ? 'You must filter by date first' : ''}
        />
      </div>

      <Table
        page={page}
        changePage={setPage}
        count={data?.count ?? 0}
        isLoading={isLoading}
        headers={[
          'VISIT ID',
          'TERMINAL ID',
          'AGENT ID',
          'AGENT ASSIGNED',
          'REASON FOR VISIT',
        ]}
      >
        {data?.results?.map((item) => (
          <StyledTableRow key={item.id}>
            <StyledTableCell component="th" scope="row">
              <Text text={`#${item.id}`} fs={10} />
              <Text
                text={format(parseISO(item.updated_at), 'yyyy/MM/dd - hh:mm a')}
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={`#${item.terminal}`} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item.agent} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item.agent_name ?? '-'} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item.visit_type} fs={10} />
              <Text text={item.visit_reason} fs={10} />
            </StyledTableCell>
          </StyledTableRow>
        ))}

        {data?.results?.length === 0 && <EmptyTable />}
      </Table>
    </div>
  );
}

export default Visits;
