import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Divider, Grid, Skeleton } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Document, Page } from 'react-pdf';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import LegalDocStatus from 'constants/LegalDocStatus';
import ViewDocument from 'components/modals/viewDocument/ViewDocument';
import ExtraDetails from 'pages/main/underwriting/documents/merchantDocs/extraDetails';
import { useDocumentFile } from 'pages/main/underwriting/documents/merchantDocs/useDocuments';
import { useParams } from 'react-router-dom';
import { documentType } from './MerchantDocuments';

export const statusClasses = {
  0: 'status--pending--yellow',
  2: 'status--active',
  3: 'status--inactive',
  default: 'status--pending',
};

interface IMerchantDocsDetailsProps {
  document: documentType | null;
  goBack?: Dispatch<SetStateAction<boolean>>;
}

const DocumentDetails = ({ document, goBack }: IMerchantDocsDetailsProps) => {
  const { merchantId } = useParams();
  const [isEditExtraDetails, setIsEditExtraDetails] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const data = document;
  const isGeneratedContract = data?.document_label === 'Generated Contract';

  const { data: documentFile } = useDocumentFile({
    requestId: data?.id?.toString(),
    documentId: data?.id?.toString(),
    filePath: data?.document_file,
  });

  let detailsContent;

  if (data?.details?.length) {
    detailsContent = Array.from(
      { length: Math.ceil(data.details.length / 2) },
      (_, i) => [data.details[2 * i], data.details[2 * i + 1]]
    ).map((item, i) => (
      <section key={i}>
        <div>
          <Text text={item[0]?.label} fs={11} className={styles.key} />
          <Text
            text={
              item[0]?.detail_type === 'datetime'
                ? format(parseISO(item[0].value), 'MMM dd, yyyy, hh:mm a')
                : item[0]?.value ?? '-'
            }
            fs={11}
            className={styles.value}
            sx={{ textTransform: 'capitalize' }}
          />
        </div>
        {item[1] && (
          <div>
            <Text text={item[1]?.label} fs={11} className={styles.key} />
            <Text
              text={item[1]?.value ?? `-`}
              fs={11}
              className={styles.value}
            />
          </div>
        )}
      </section>
    ));
  }

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const fileSkeleton = (
    <Skeleton
      sx={{ height: 250, width: '60%', mx: 'auto' }}
      animation="wave"
      variant="rectangular"
    />
  );

  return (
    <>
      <ViewDocument
        openModal={openModal}
        onCloseModal={handleCloseModal}
        filePath={data?.document_file ?? ''}
        file={documentFile ?? ''}
        skeleton={fileSkeleton}
      />
      <div className={styles.details}>
        <header>
          <Text text="Document Details" />

          <Box sx={{ justifyContent: 'flex-end' }}>
            <Box
              onClick={() => {
                goBack?.(false);
              }}
            >
              <Text text="back" className="btn" fs={10} />
            </Box>
          </Box>
        </header>

        <div className={styles.content}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: 200,
              maxHeight: 300,
              overflow: 'hidden',
            }}
          >
            {data?.document_file && !documentFile && fileSkeleton}

            {!data?.document_file && (
              <Text text="No document file available" sx={{ my: 'auto' }} />
            )}
            {data?.document_file && documentFile && (
              <>
                {!data?.document_file?.includes('.pdf') && (
                  <Box
                    onClick={handleOpenModal}
                    sx={{ cursor: 'pointer', width: '50%' }}
                  >
                    <img
                      src={documentFile}
                      alt="Document file"
                      height="auto"
                      width="100%"
                    />
                  </Box>
                )}

                {data?.document_file?.includes('.pdf') && (
                  <Box onClick={handleOpenModal} sx={{ cursor: 'pointer' }}>
                    <Document
                      file={documentFile}
                      loading={fileSkeleton}
                      error={
                        <Text text="Something went wrong" sx={{ my: 'auto' }} />
                      }
                    >
                      <Page
                        pageNumber={1}
                        height={400}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        loading={fileSkeleton}
                      />
                    </Document>
                  </Box>
                )}
              </>
            )}
          </Box>

          <section>
            <Divider variant="middle" />
          </section>

          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Text text="Document" fs={11} className={styles.key} />
              <Text
                text={data?.document_label ?? `-`}
                fs={11}
                className={styles.value}
              />
            </Grid>

            <Grid item xs={6}>
              <Text text="Status" fs={11} className={styles.key} />
              <Text
                text={
                  data?.status === 0
                    ? LegalDocStatus[data?.status].split(' ')[0] + ' Approval'
                    : LegalDocStatus[
                        data?.status as keyof typeof LegalDocStatus
                      ]
                }
                className={
                  statusClasses[data?.status as keyof typeof statusClasses] ??
                  statusClasses.default
                }
                sx={{ py: 0.2, ml: -0.5 }}
                fs={10}
              />
            </Grid>

            <Grid item xs={6}>
              <Text text="Expire At" fs={11} className={styles.key} />
              <Text
                text={
                  data?.expiry_at
                    ? format(parseISO(data.expiry_at), 'MMM dd, yyyy, hh:mm a')
                    : `-`
                }
                fs={11}
                className={styles.value}
              />
            </Grid>
            <Grid item xs={6}>
              <Text text="Updated At" fs={11} className={styles.key} />
              <Text
                text={
                  data?.updated_at
                    ? format(parseISO(data.updated_at), 'MMM dd, yyyy, hh:mm a')
                    : `-`
                }
                fs={11}
                className={styles.value}
              />
            </Grid>

            <Grid item xs={6}>
              <Text text="Uploaded By" fs={11} className={styles.key} />
              <Text
                text={data?.uploaded_by ?? `-`}
                fs={11}
                className={styles.value}
              />
            </Grid>
            <Grid item xs={6}>
              <Text text="Reviewed By" fs={11} className={styles.key} />
              <Text
                text={data?.reviewed_by ?? `-`}
                fs={11}
                className={styles.value}
              />
            </Grid>
          </Grid>

          {isGeneratedContract && detailsContent}

          {!!data?.details?.length && !isGeneratedContract && (
            <Box sx={{ '& header': { paddingBlock: '0 !important' } }}>
              <ExtraDetails
                extraDetails={data?.details}
                isEdit={isEditExtraDetails}
                setIsEdit={setIsEditExtraDetails}
                merchantId={merchantId!}
                documentId={data?.id?.toString()!}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentDetails;
