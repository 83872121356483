import { Fragment, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import {
  Skeleton,
  Grid,
  Button as MuiButton,
  Box,
  Divider,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Document, Page } from 'react-pdf';

import Button from 'components/button/Button';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import {
  useApproveBusinessOwner,
  useBusinessOwnerDetails,
  useRejectBusinessOwner,
} from './useBusinessOwner';
import LegalDocStatus from 'constants/LegalDocStatus';
import { downloadFile } from 'utils/downloadFile';
import {
  useDocumentFile,
  useRejectionReasons,
} from '../merchantDocs/useDocuments';
import ViewDocument from 'components/modals/viewDocument/ViewDocument';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const statusClasses = {
  0: 'status--pending--yellow',
  2: 'status--active',
  3: 'status--inactive',
  default: 'status--pending',
};

const rejectReasonsSchemaValidation = z.object({
  reasons: z.array(z.number()).nonempty('Please, select at least one reason'),
});

export type RejectReasonsSchemaType = z.infer<
  typeof rejectReasonsSchemaValidation
>;

interface IBusinessOwnersInfoDetailsProps {
  id: string;
}

const BusinessOwnersInfoDetails = ({ id }: IBusinessOwnersInfoDetailsProps) => {
  const { requestId, merchantId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { data: businessOwner, isFetching } = useBusinessOwnerDetails({
    ownerId: id,
    merchantId,
  });
  const { data: documentFile } = useDocumentFile({
    requestId,
    documentId: id,
    filePath: businessOwner?.signatory_national_id,
  });
  const [showRejectionList, setShowRejectionList] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const { data: rejectionReasons, isFetching: isFetchingRejections } =
    useRejectionReasons();
  const { mutate: approveBusinessOwner, isLoading: isApprovingBusinessOwner } =
    useApproveBusinessOwner({ merchantId, ownerId: id });
  const { mutate: rejectBusinessOwner, isLoading: isRejectingBusinessOwner } =
    useRejectBusinessOwner({ merchantId, ownerId: id });

  const rejectionReasonsList = Object.entries(rejectionReasons?.en ?? {}).map(
    (item) => ({ name: item[1], value: +item[0] })
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty, isValid, errors },
  } = useForm<RejectReasonsSchemaType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(rejectReasonsSchemaValidation),
    defaultValues: {
      reasons: [],
    },
  });

  const handleApproveBusinessOwner = () => approveBusinessOwner();

  const handleRejectBusinessOwner: SubmitHandler<RejectReasonsSchemaType> = (
    value
  ) => {
    rejectBusinessOwner(
      {
        status: 3,
        rejection_reason: value.reasons ?? [],
      },
      { onSuccess: () => handleCancelReject() }
    );
  };

  const handleChangeRejectionReasons = (
    event: SelectChangeEvent<typeof selectedReasons>
  ) => {
    const value = event.target.value as number[];
    setSelectedReasons(value);
  };

  const handleShowRejections = () => setShowRejectionList(true);

  const handleCancelReject = () => {
    reset();
    setSelectedReasons([]);
    setShowRejectionList(false);
  };

  const handleDownload = (filePath: string) => {
    if (!filePath) return;
    const fileName = filePath.split('/').at(-1)?.split('.')[0];
    downloadFile(filePath, fileName ?? 'document', documentFile);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  let statusHeaderContent;

  if (businessOwner?.status === 2) {
    statusHeaderContent = (
      <Box
        sx={{
          backgroundColor: 'var(--clr-lightGreen)',
          color: 'var(--clr-green)',
          display: 'flex',
          p: 2,
          gap: 2,
        }}
      >
        <CheckCircleIcon sx={{ color: 'var(--clr-green)' }} />
        <Text text="This document has been approved successfully" fs={11} />
      </Box>
    );
  } else if (businessOwner?.status === 3) {
    statusHeaderContent = (
      <Box
        sx={{
          backgroundColor: 'var(--clr-lightRed)',
          color: 'var(--clr-red)',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CancelIcon sx={{ color: 'var(--clr-red)' }} />
          <Text
            text={`This document has been rejected: ${
              rejectionReasons?.en[businessOwner?.rejection_reason[0]] ||
              rejectionReasons?.ar[businessOwner?.rejection_reason[0]] ||
              '-'
            }...`}
            fs={11}
          />
        </Box>
      </Box>
    );
  }

  const fileSkeleton = (
    <Skeleton
      sx={{ height: 250, width: '60%', mx: 'auto' }}
      animation="wave"
      variant="rectangular"
    />
  );

  let actionsContent: JSX.Element;

  if (showRejectionList) {
    actionsContent = (
      <form onSubmit={handleSubmit(handleRejectBusinessOwner)}>
        <section>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Text
              text="Please select the reasons for rejection and confirm to proceed"
              fs={11}
            />

            <Controller
              name="reasons"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handleChangeRejectionReasons(e);
                  }}
                  multiple
                  value={selectedReasons}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  sx={{ width: 200, height: 40 }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>reasons</em>;
                    }

                    return rejectionReasonsList
                      .filter((reason) => selected.includes(reason.value))
                      .map((reason) => reason.name)
                      .join(', ');
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  error={Boolean(error)}
                >
                  <MenuItem disabled value="">
                    <em>reasons</em>
                  </MenuItem>
                  {rejectionReasonsList.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </section>
        <section>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              text="Confirm rejection"
              style={{ backgroundColor: 'var(--clr-red)' }}
              disabled={
                isFetchingRejections ||
                !isDirty ||
                !isValid ||
                isRejectingBusinessOwner
              }
              title={
                !isDirty || !isValid
                  ? errors.reasons?.message ||
                    'Please, select at least one reason'
                  : undefined
              }
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={handleCancelReject}
              text="Cancel"
              style={{
                backgroundColor: '#fff',
                color: 'var(--clr-red)',
                fontSize: 12,
              }}
            />
          </Box>
        </section>
      </form>
    );
  } else {
    actionsContent = (
      <section>
        {businessOwner?.status !== 2 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleApproveBusinessOwner}
              text="Approve document"
              style={{ backgroundColor: 'var(--clr-green)' }}
              disabled={isApprovingBusinessOwner}
            />
          </Box>
        )}
        {businessOwner?.status !== 3 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleShowRejections}
              text="Reject document"
              style={{ backgroundColor: 'var(--clr-red)' }}
            />
          </Box>
        )}
      </section>
    );
  }

  return (
    <>
      <ViewDocument
        openModal={openModal}
        onCloseModal={handleCloseModal}
        filePath={businessOwner?.signatory_national_id ?? ''}
        file={documentFile ?? ''}
        skeleton={fileSkeleton}
      />
      <div className={styles.details}>
        {statusHeaderContent}
        <header>
          <Text text="Business Owners Info" />
        </header>
        {isFetching && (
          <Grid container rowSpacing={4} sx={{ p: 5 }}>
            <>
              <Grid item xs={12}>
                {fileSkeleton}
              </Grid>
              {[1, 2, 3, 4].map((val) => (
                <Fragment key={val}>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={200} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={200} />
                  </Grid>
                </Fragment>
              ))}
            </>
          </Grid>
        )}

        {!isFetching && (
          <div className={styles.content}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 300,
                overflow: 'hidden',
              }}
            >
              {businessOwner?.signatory_national_id &&
                !documentFile &&
                fileSkeleton}

              {!businessOwner?.signatory_national_id && (
                <Text text="No document file available" sx={{ my: 'auto' }} />
              )}

              {businessOwner?.signatory_national_id && documentFile && (
                <>
                  {!businessOwner?.signatory_national_id?.includes('.pdf') && (
                    <Box
                      onClick={handleOpenModal}
                      sx={{ cursor: 'pointer', width: '50%' }}
                    >
                      <img
                        src={documentFile}
                        alt="Document file"
                        height="auto"
                        width="100%"
                      />
                    </Box>
                  )}

                  {businessOwner?.signatory_national_id?.includes('.pdf') && (
                    <Box onClick={handleOpenModal} sx={{ cursor: 'pointer' }}>
                      <Document
                        file={documentFile}
                        loading={fileSkeleton}
                        error={
                          <Text
                            text="Something went wrong"
                            sx={{ my: 'auto' }}
                          />
                        }
                      >
                        <Page
                          pageNumber={1}
                          height={600}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          loading={fileSkeleton}
                        />
                      </Document>
                    </Box>
                  )}
                </>
              )}
            </Box>

            <section>
              <Divider variant="middle" />
            </section>

            <section>
              <div>
                <Text text="Full Name" fs={11} className={styles.key} />
                {businessOwner?.signatory_name ||
                businessOwner?.signatory_name_english ? (
                  <Text
                    sx={{ textTransform: 'capitalize' }}
                    text={
                      businessOwner.signatory_name ||
                      businessOwner.signatory_name_english
                    }
                    fs={11}
                    className={styles.value}
                  />
                ) : (
                  <Text
                    text="Name not available"
                    fs={11}
                    className={styles.value}
                  />
                )}
              </div>
              <div>
                <Text text="Status" fs={11} className={styles.key} />
                <Text
                  text={
                    businessOwner?.status === 0
                      ? LegalDocStatus[businessOwner?.status].split(' ')[0]
                      : LegalDocStatus[
                          businessOwner?.status as keyof typeof LegalDocStatus
                        ]
                  }
                  sx={{ py: 0.2, ml: -0.5 }}
                  fs={10}
                  className={
                    statusClasses[
                      businessOwner?.status as keyof typeof statusClasses
                    ] || statusClasses.default
                  }
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Date of Joining" fs={11} className={styles.key} />
                <Text
                  text={
                    businessOwner?.created_at
                      ? format(
                          parseISO(businessOwner.created_at),
                          'MMM dd, yyyy, hh:mm a'
                        )
                      : `-`
                  }
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="Address" fs={11} className={styles.key} />
                <Text
                  text={businessOwner?.address || '-'}
                  fs={11}
                  className={styles.value}
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Mobile Number" fs={11} className={styles.key} />
                <Text
                  text={businessOwner?.phone_number || '-'}
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="Email Address" fs={11} className={styles.key} />
                <Text
                  text={businessOwner?.email_address || '-'}
                  fs={11}
                  className={styles.value}
                />
              </div>
            </section>

            <section>
              <div>
                <Text
                  text="National ID Number"
                  fs={11}
                  className={styles.key}
                />
                <Text
                  text={businessOwner?.national_id || '-'}
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="National ID" fs={11} className={styles.key} />
                <MuiButton
                  onClick={() =>
                    businessOwner?.signatory_national_id &&
                    handleDownload(businessOwner.signatory_national_id)
                  }
                  size="small"
                  disabled={!businessOwner?.signatory_national_id}
                  sx={{ ml: -0.5 }}
                >
                  <Text text="Download" fs={10} />
                </MuiButton>
              </div>
            </section>

            {businessOwner?.passport && (
              <section>
                <div>
                  <Text
                    text="Passport ID Number"
                    fs={11}
                    className={styles.key}
                  />
                  <Text
                    text={businessOwner?.passport || '-'}
                    fs={11}
                    className={styles.value}
                  />
                </div>
                <div>
                  <Text text="Passport ID" fs={11} className={styles.key} />
                  <MuiButton
                    onClick={() =>
                      businessOwner?.passport_id_file &&
                      handleDownload(businessOwner.passport_id_file)
                    }
                    size="small"
                    disabled={!businessOwner?.passport_id_file}
                    sx={{ ml: -0.5 }}
                  >
                    <Text text="Download" fs={10} />
                  </MuiButton>
                </div>
              </section>
            )}

            <section style={{ alignItems: 'flex-start' }}>
              <div>
                <Text
                  text={`${
                    businessOwner?.status === 2
                      ? 'Previous Rejected'
                      : 'Rejection'
                  } Reasons`}
                  fs={11}
                  className={styles.key}
                />
                {businessOwner?.rejection_reason?.map((reasonId) => (
                  <Text
                    key={reasonId}
                    text={
                      rejectionReasons?.en[reasonId] ||
                      rejectionReasons?.ar[reasonId] ||
                      `-`
                    }
                    fs={11}
                    className={styles.value}
                  />
                ))}
              </div>
            </section>

            <section>
              <Divider variant="middle" />
            </section>
            {actionsContent}
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessOwnersInfoDetails;
