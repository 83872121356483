import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

export interface IIntegrationRequestsResponse {
  count: number;
  next: string;
  previous: string;
  results: Result[];
}

export interface Result {
  id: number;
  merchant_id: string;
  username: string;
  company_name: string;
  governorate_en: string;
  governorate_ar: string;
  city_en: string;
  city_ar: string;
  neighborhood_en: string;
  neighborhood_ar: string;
  phone: string;
  verified_phone: boolean;
  status: string;
  reviewed_by: string;
  integration_service: string;
  gateway_type: string;
  integration_type: string;
  integration_rate: string;
  is_psp: boolean;
  created_at: string;
  updated_at: string;
}

interface IUseIntegrationRequests {
  merchantId?: string;
  statuses?: string[];
  enable?: boolean;
}

export interface IBusinessOwner {
  id: number;
  national_id: string;
  signatory_name: string;
  signatory_name_english: string;
  signatory_position: string;
  email_address: string;
  address: string;
  is_primary: boolean;
  status: number;
  uploaded_by: string;
  uploaded_at: string;
  reviewed_by: string;
  reviewed_at: string;
  created_at: string;
  updated_at: string;
}

export interface IMerchantDocument {
  id: number;
  document_label: string;
  status: number;
  created_at: string;
  updated_at: string;
  reviewed_by: string;
}

export interface IIntegrationRequestDetailsResponse {
  id: number;
  merchant_id: string;
  username: string;
  company_name: string;
  governorate_en: string;
  governorate_ar: string;
  city_en: string;
  city_ar: string;
  neighborhood_en: string;
  neighborhood_ar: string;
  phone: string;
  verified_phone: boolean;
  status: string;
  reviewed_by: string;
  integration_service: string;
  gateway_type: string;
  integration_type: string;
  integration_rate: string;
  is_psp: boolean;
  created_at: string;
  updated_at: string;
  merchant_documents: IMerchantDocument[];
  business_owners: IBusinessOwner[];
  bank_name: string;
  bank_account_number: string;
  bank_account_iban: string;
  complex_rates_ids: number[];
  integration_rate_type: string;
  integration_rate_percentage: string;
  integration_rate_fixed: string;
}

export function useIntegrationRequests(args: IUseIntegrationRequests) {
  const statuses =
    args?.statuses?.map((status) => `&status=${status}`).join('&') ?? '';

  const merchantId = args?.merchantId ? `&merchant_id=${args.merchantId}` : '';

  return useInfiniteQuery({
    queryKey: underwritingKeys.integrationRequests(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<IIntegrationRequestsResponse>(
        `integration_requests/?page=${pageParam}${merchantId}${statuses}`,
        true
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
    enabled: args?.enable,
  });
}

export function useIntegrationRequestDetails(requestId?: string) {
  return useQuery({
    queryKey: underwritingKeys.integrationRequestDetails(requestId),
    queryFn: () =>
      Services.get<IIntegrationRequestDetailsResponse>(
        `integration_requests/${requestId}/`,
        true
      ),
    enabled: !!requestId,
  });
}

export function useApproveRequest() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<unknown, Error, string>({
    mutationFn: (requestId: string) =>
      Services.set<unknown, unknown>(
        {},
        `integration_requests/${requestId}/approve/`,
        true
      ),
    onSuccess: () => {
      toast.success('Request Approved');
      navigate('/underwriting/requests/closed', { replace: true });
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.integrationRequest(),
      });
    },
  });
}

export function useRejectRequest() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<unknown, Error, string>({
    mutationFn: (requestId: string) =>
      Services.set<unknown, unknown>(
        {},
        `integration_requests/${requestId}/reject/`,
        true
      ),
    onSuccess: () => {
      toast.success('Request Rejected');
      navigate('/underwriting/requests/closed', { replace: true });
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.integrationRequest(),
      });
    },
  });
}
