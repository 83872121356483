import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type TransfersResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: TransferType[];
};

type TransferType = {
  id: number;
  trx_ids: number[];
  amount_gross_pound: number;
  amount_net_pound: number | null;
  amount_fees_pound: number;
  amount_vat_pound: number;
  bank_account: string;
  status: number;
  amount_balance_transfer_pound: number;
  ach_generated: boolean;
  transfer_pdf: string;
  settlement_currency: string;
};

interface IUseTransfers {
  merchantId?: string;
  page: number;
  searchText: string;
  status: string;
  date: { from?: string; to?: string };
  sort: string;
  selectedKey: string;
}

export function useTransfers(args: IUseTransfers) {
  const { merchantId, page, searchText, status, date, sort, selectedKey } =
    args;

  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';
  const searchKey = selectedKey === '' ? 'id' : selectedKey;

  return useQuery({
    queryKey: merchantKeys.transfers(args),
    queryFn: () =>
      Services.get<TransfersResponse>(
        `merchants/${merchantId}/transfers/?page=${page}&${searchKey}=${searchText}&status=${status}&${dateFormat}${sort}`,
        true
      ),
  });
}
