import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Skeleton } from '@mui/material';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import BankForm from './form';
import { useBanksInfo } from './useLegalBanks';
import { useLegal } from 'pages/main/merchants/marchent/overview/useLegal';

const BankDetails = () => {
  const { merchantId } = useParams();
  const { data: legalDocs } = useLegal(merchantId!);
  const { data, isFetching } = useBanksInfo({
    merchantId,
    legalDocsId: legalDocs?.[0]?.id,
  });
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm((prevShowForm) => !prevShowForm);

  const onSubmit = () => setShowForm(false);

  return (
    <div className={styles.details}>
      <header>
        <Text text="Bank Account Details" />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleShowForm}>
            <Text
              text={showForm ? 'Cancel' : 'Edit details'}
              style={{ color: 'var(--clr-lightBlue)' }}
              fs={10}
            />
          </Button>
        </Box>
      </header>

      {isFetching && (
        <Grid container rowSpacing={4} sx={{ p: 5 }}>
          {[1, 2, 3].map((val) => (
            <Fragment key={val}>
              <Grid item xs={6}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
      {!isFetching && !showForm && (
        <div className={styles.content}>
          <section>
            <div>
              <Text text="Company Bank Name" fs={11} className={styles.key} />
              <Text
                text={data?.company_bank_name || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text text="Company Bank Name 2" fs={11} className={styles.key} />
              <Text
                text={data?.company_bank_name_2 || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
          </section>
          <section>
            <div>
              <Text text="Company Bank Branch" fs={11} className={styles.key} />
              <Text
                text={data?.company_bank_branch || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text
                text="Company Bank Account Name"
                fs={11}
                className={styles.key}
              />
              <Text
                text={data?.company_bank_account_name || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
          </section>
          <section>
            <div>
              <Text
                text="Company Bank Account Number"
                fs={11}
                className={styles.key}
              />
              <Text
                text={data?.company_bank_account_number || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
            <div>
              <Text
                text="Company Bank Swift Code"
                fs={11}
                className={styles.key}
              />
              <Text
                text={data?.company_bank_swift_code || '-'}
                fs={11}
                className={styles.value}
              />
            </div>
          </section>
          <section>
            <div>
              <Text text="IBAN" fs={11} className={styles.key} />
              <Text text={data?.iban || '-'} fs={11} className={styles.value} />
            </div>
          </section>
        </div>
      )}
      {!isFetching && showForm && <BankForm onSubmit={onSubmit} />}
    </div>
  );
};

export default BankDetails;
