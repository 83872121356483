import { ReactNode, createContext, useContext, useEffect } from 'react';
import { isAxiosError } from 'axios';

import useAuth from 'hooks/useAuth';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { axiosPublic } from 'apis/AxiosConfig';
import { showToastError } from 'utils/showToastError';

interface AuthContextType {
  signIn: () => void;
  signOut: () => void;
  userToken: string | null;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  signIn: () => {},
  signOut: () => {},
  userToken: null,
  isLoading: false,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const auth = useAuth();

  useEffect(() => {
    if (account) {
      auth.setIsLoading(true);
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((auth: any) => {
          const accessTokenObj = { 'MS-Access-Token': auth.accessToken };
          return axiosPublic.post('/login/', accessTokenObj);
        })
        .then((res: any) => {
          auth.setUserToken(res?.data.access);
          sessionStorage.setItem('token', res?.data.access);
          sessionStorage.setItem('refresh_token', res?.data.refresh);
          sessionStorage.setItem(
            'lastRefreshToken',
            new Date().getTime().toString()
          );
        })
        .catch((error: Error) => {
          showToastError(isAxiosError(error) ? error.response?.data : error);
          auth.signOut();
        })
        .finally(() => auth.setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, instance]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined)
    console.error('AuthContext was used outside AuthProvider');

  return context;
};
