import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import Filters from 'components/filters/Filters';
import Button from 'components/button/Button';
import EmptyTable from 'components/Table/EmptyTable';
import TransfersStatus from 'constants/TransfersStatus';
import { useTransfers } from './useTransfers';
import { useTransfersRecords } from './useTransfersRecords';
import { useTranslation } from 'react-i18next';
import { showToastError } from 'utils/showToastError';
import { Checkbox } from '@mui/material';
import { useGenerateAchPdf } from './useGenerateAchPdf';
import { downloadFile } from 'utils/downloadFile';
import toast from 'react-hot-toast';
import { Button as MuiButton } from '@mui/material';
import TransferInformations from 'components/modals/merchant-drawer/transfer-informations/TransferInformations';

export const statusClasses = {
  0: 'status--pending',
  1: 'status--active',
  default: 'status--inactive',
};

function Transfers() {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [date, onDateChange] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const [isSelectedDates, setIsSelectedDates] = useState(false);

  const filters = {
    merchantId,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
  };
  const { data, isLoading, refetch: refetchTableData } = useTransfers(filters);

  const { refetch } = useTransfersRecords({ merchantId, status, date });

  const handleGenerateRecords = async () => {
    const { isError, data } = await refetch();

    if (isError || !data) {
      showToastError('Something went wrong!');
      return;
    }

    let arr = data?.split('\r\n');
    let newArr = [];

    if (!arr) return;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let splitedStr = element.split(',');
      newArr.push(splitedStr);
    }

    let workbook = XLSX.utils.book_new(),
      worksheet = XLSX.utils.aoa_to_sheet(newArr);
    workbook.SheetNames.push('First');
    workbook.Sheets['First'] = worksheet;
    XLSX.writeFile(workbook, 'transfers_records.xlsx');
  };

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    setPage(1);
    let dates = value ?? {};
    setIsSelectedDates(Boolean(value) || false);
    onDateChange(dates);
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value.toString());
  };

  //checkbox
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [hasSuccessItems, setHasSuccessItems] = useState<boolean>(false);

  const isSelected = (id: number) => selectedItems.indexOf(id) !== -1;
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedItems = data?.results?.map((n) => n.id)!;
      setSelectedItems(newSelectedItems);
      setHasSuccessItems(
        Boolean(
          data?.results?.filter((item) => +item?.status === 1)?.length !== 0
        )
      );
      return;
    }
    setHasSuccessItems(false);
    setSelectedItems([]);
  };
  const handleClickOnRow = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    setSelectedItems(newSelected);
    setHasSuccessItems(
      Boolean(
        newSelected?.filter(
          (id) => data?.results?.find((item) => item?.id === id)?.status === 1
        )?.length !== 0
      )
    );
  };
  const { mutateAsync: generateAchPdf } = useGenerateAchPdf(merchantId!);
  const handelExportACHDocument = async () => {
    await generateAchPdf({
      data: { transfer_ids: selectedItems },
      merchantId: merchantId!,
    }).catch(() => console.log('export ACH document error'));
    refetchTableData();
    setSelectedItems([]);
  };
  const handelDownloadFile = (filePath: string, fileName: string) => {
    toast.success('Download will start soon...', { id: 'download' });
    downloadFile(filePath, fileName);
  };
  return (
    <div>
      <Filters
        showDate
        inputLabel="Search for transfers"
        inputPlaceholder="Search by transfer ID"
        selectPlaceholder="Filter by status"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        statusData={[
          {
            value: '',
            label: t('All'),
          },
          {
            value: 0,
            label: t('Pending'),
          },
          {
            value: 1,
            label: t('Successful'),
          },
          {
            value: 2,
            label: t('Denied by Bank'),
          },
          {
            value: 3,
            label: t('Rejected for Account number not valid'),
          },
          {
            value: 4,
            label: t('Rejected for a closed account'),
          },
          {
            value: 5,
            label: t('Rejected for account not valid'),
          },
          {
            value: 6,
            label: t('Rejected for account number error'),
          },
          {
            value: 7,
            label: t('Rejected for invalid credit instruction'),
          },
        ]}
        searchSelectors={[{ label: t('TRANSFER ID'), value: '' }]}
        onSelectedKey={setSelectedKey}
        onSortChange={FilteredBySort}
        isNeedSorting
      />

      <div style={{ marginBottom: 30, textAlign: 'end' }}>
        <Button
          text="Export ACH document"
          style={{
            marginInlineEnd: 10,
            backgroundColor: 'var(--clr-mainBlue)',
          }}
          disabled={selectedItems.length === 0 || !hasSuccessItems}
          handleClick={handelExportACHDocument}
        />
        <Button
          text="Export transfers"
          handleClick={() => handleGenerateRecords()}
          // disabled={!isSelectedDates}
          disabled={true}
          // title={!isSelectedDates ? 'You must filter by date first' : ''}
        />
      </div>

      <Table
        page={page}
        changePage={setPage}
        count={data?.count ?? 0}
        isLoading={isLoading}
        checkBox={{
          showCheckBox: true,
          checkBoxAction: handleSelectAll,
          rowCount: data?.results.length!,
          numSelected: selectedItems.length,
          setSelectedItems: setSelectedItems,
        }}
        headers={[
          'TRANSFER ID',
          'TRNX ID',
          'TRANSFER AMOUNT',
          'GROSS AMOUNT',
          'FEES AMOUNT',
          'VAT AMOUNT',
          'RECEIVING ACCOUNT',
          'STATUS',
          'DOCUMENT',
          '',
        ]}
      >
        {data?.results?.map((item) => {
          const isItemSelected = isSelected(item.id);
          return (
            <StyledTableRow key={item.id}>
              <StyledTableCell padding="checkbox" component="th" scope="row">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  onClick={(event) => handleClickOnRow(event, item.id)}
                />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <TransferInformations
                  transferId={item.id + ''}
                  merchantId={merchantId + ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                {item.trx_ids.length > 0 && (
                  <Text text={`#${item.trx_ids?.join(' , #')}`} fs={10} />
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text="EGP"
                  variables={{ 0: item.amount_balance_transfer_pound ?? 0 }}
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text="EGP"
                  variables={{ 0: item.amount_gross_pound ?? 0 }}
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text="EGP"
                  variables={{ 0: item.amount_fees_pound ?? 0 }}
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text
                  text="EGP"
                  variables={{ 0: item.amount_vat_pound ?? 0 }}
                  fs={10}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Text text={item.bank_account} fs={10} />
              </StyledTableCell>
              <StyledTableCell>
                <div
                  className={
                    statusClasses[item?.status as keyof typeof statusClasses] ||
                    statusClasses.default
                  }
                >
                  <Text
                    text={
                      TransfersStatus[
                        item?.status as keyof typeof TransfersStatus
                      ]
                    }
                    fs={8}
                  />
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <MuiButton
                  onClick={() =>
                    handelDownloadFile(
                      item?.transfer_pdf,
                      `Transfer_${item.id}.pdf`
                    )
                  }
                  disabled={!item?.ach_generated}
                >
                  <Text text="Download" fs={10} />
                </MuiButton>
              </StyledTableCell>
              <StyledTableCell
                sx={{ paddingInlineStart: 1, whiteSpace: 'nowrap' }}
              >
                <Link
                  to={`${item.id}`}
                  style={{ color: 'var(--clr-lightBlue)' }}
                >
                  <Text text="View all" fs={10} />
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}

        {data?.results?.length === 0 && <EmptyTable />}
      </Table>
    </div>
  );
}

export default Transfers;
