import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import Filters from 'components/filters/Filters';
import Button from 'components/button/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import EmptyTable from 'components/Table/EmptyTable';
import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
import { useSMSCommunication } from './useSMSCommunication';
import { useSMSCommunicationRecords } from './useSMSCommunicationRecords';
import { useTranslation } from 'react-i18next';
import { showToastError } from 'utils/showToastError';

function SMSCommunication() {
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [date, onDateChange] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const [isSelectedDates, setIsSelectedDates] = useState(false);

  const filters = {
    merchantId,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
  };

  const { data, isLoading } = useSMSCommunication(filters);
  const { refetch } = useSMSCommunicationRecords({ merchantId, status, date });

  const exportRecords = async () => {
    const { isError, data } = await refetch();

    if (isError || !data) {
      showToastError('Something went wrong!');
      return;
    }

    let arr = data?.split('\r\n');
    let newArr = [];

    if (!arr) return;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let splitedStr = element.split(',');
      newArr.push(splitedStr);
    }

    let workbook = XLSX.utils.book_new(),
      worksheet = XLSX.utils.aoa_to_sheet(newArr);
    workbook.SheetNames.push('First');
    workbook.Sheets['First'] = worksheet;
    XLSX.writeFile(workbook, 'communications_records.xlsx');
  };

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    setPage(1);
    let dates = value ?? {};
    setIsSelectedDates(Boolean(value) || false);
    onDateChange(dates);
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value.toString());
  };

  return (
    <div>
      <Filters
        showDate
        inputLabel="Search for communcation"
        inputPlaceholder="Type to search list"
        selectPlaceholder="Filter by status"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        searchSelectors={[
          { label: t('Comm ID'), value: '' },
          { label: t('Activity type'), value: 'activity_type' },
          // { name: "Communication type", value: "communication_type" },
        ]}
        onSelectedKey={setSelectedKey}
        statusData={[
          {
            value: '',
            label: 'All',
          },
          {
            value: 'SENT',
            label: 'SENT',
          },
          {
            value: 'FAILED',
            label: 'FAILED',
          },
        ]}
        onSortChange={FilteredBySort}
        isNeedSorting
      />

      <div style={{ marginBottom: 30, textAlign: 'end' }}>
        <Button
          text="Export SMS Communication"
          handleClick={() => exportRecords()}
          disabled={!isSelectedDates}
          title={!isSelectedDates ? 'You must filter by date first' : ''}
        />
      </div>

      <Table
        page={page}
        changePage={setPage}
        count={data?.count ?? 0}
        isLoading={isLoading}
        headers={[
          'COMM ID',
          'ACTIVITY TYPE',
          'MESSAGE',
          'Date',
          'DELIVERY STATUS',
          '',
        ]}
      >
        {data?.results?.map((item) => (
          <StyledTableRow key={item.id}>
            <StyledTableCell component="th" scope="row">
              #{item.id}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              {item.activity_type}
            </StyledTableCell>
            <StyledTableCell>{item.body}</StyledTableCell>
            <StyledTableCell>
              {item.created_at && (
                <Text
                  text={`${format(parseISO(item.created_at), 'dd MMM yyyy')}`}
                  fs={10}
                />
              )}
            </StyledTableCell>
            <StyledTableCell>
              <div
                className={
                  item.status === 'SENT' ? 'status--active' : 'status--inactive'
                }
              >
                <Text text={item?.status} fs={10} />
              </div>
            </StyledTableCell>
          </StyledTableRow>
        ))}

        {data?.results?.length === 0 && <EmptyTable />}
      </Table>
    </div>
  );
}

export default SMSCommunication;
