import { CSSProperties } from 'react';

const containerStyle: CSSProperties = {
  maxWidth: 1920,
  position: 'relative',
  minWidth: 360,
  marginInline: 'auto',
  flexWrap: 'wrap',
  paddingInline: '2.5%',
};

interface ContainerProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export default function Container({ children, style }: ContainerProps) {
  return (
    <div
      style={{
        ...containerStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
