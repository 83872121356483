import { useLocation } from 'react-router-dom';

import RequestTable from './requestsTable';
import FormFilters, { UNDERWRITING_FILTERS_VALUES } from './formFilters';
import { useEffect, useState } from 'react';
import { filterTransformer } from './requestsTable/filterTransformer';

const RequestsList = () => {
  const location = useLocation();
  const isPendingRequests = location.pathname.split('/')[3] === 'pending';
  const [filters, setFilters] = useState('');
  const [page, setPage] = useState(1);
  const onSubmit = (values: UNDERWRITING_FILTERS_VALUES) => {
    setFilters(filterTransformer(values, isPendingRequests));
  };
  useEffect(() => {
    setPage(1);
    if (isPendingRequests) setFilters('&status=UPDATED&status=PENDING');
    else setFilters('&status=REJECTED&status=APPROVED');
  }, [isPendingRequests]);
  return (
    <>
      <FormFilters isPendingRequests={isPendingRequests} onSubmit={onSubmit} />
      <RequestTable filters={filters} page={page} setPage={setPage} />
    </>
  );
};

export default RequestsList;
