import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import type { TransactionsType as TransactionsResponse } from './useTransactions';

export function useTransactionDetails(merchantId = '', transactionId = '') {
  return useQuery({
    queryKey: merchantKeys.transactionDetails(merchantId, transactionId),
    queryFn: () =>
      Services.get<TransactionsResponse>(
        `merchants/${merchantId}/transactions/${transactionId}/`,
        true
      ),
  });
}
