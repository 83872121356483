import { useParams } from 'react-router-dom';
import { Skeleton, Grid, Button, Box } from '@mui/material';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import { useMerchant } from 'pages/main/merchants/marchent/useMerchant';
import { Fragment } from 'react';
import { useIntegrationRequestDetails } from '../integrationRequests/useIntegrationRequests';
import Content from './Content';

const MerchantDetails = () => {
  const { merchantId, requestId } = useParams();
  const { data: merchant, isFetching } = useMerchant(merchantId!);
  const { data: integrationRequest } = useIntegrationRequestDetails(requestId);

  return (
    <div className={styles.details}>
      <header>
        <Text text="Merchant Details" />
      </header>
      {isFetching ? (
        <Grid container rowSpacing={4} sx={{ p: 5 }}>
          {[1, 2, 3, 4].map((val) => (
            <Fragment key={val}>
              <Grid item xs={6}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={200} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Content merchant={merchant} integrationRequest={integrationRequest} />
      )}
    </div>
  );
};

export default MerchantDetails;
