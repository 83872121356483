import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import Arrow from 'assets/images/icons/left-arrow.png';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Container from 'components/layouts/Container';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import { useBanks } from './useBanks';
import AddBankAccount from 'components/modals/bank-account-details/AddAccount';
import Check from 'assets/images/icons/check.svg';
import Checkbox from 'components/checkbox';
import { useMakeAccountDefault } from 'components/modals/bank-account-details/useBankAccountDetails';
import EditAccount from 'components/modals/bank-account-details/EditAccount';

const statusClassName = {
  Approved: 'status--active',
  Pending: 'status--pending--yellow',
  default: 'status--inactive',
};

function Banks() {
  const { merchantId, id } = useParams();
  const [index, setPage] = useState(1);
  const { isLoading, data: banks } = useBanks({ merchantId, page: index });
  const { mutate: makeAccountDefault, isLoading: makingAccountDefault } =
    useMakeAccountDefault(merchantId);

  return (
    <div id={styles.transaction_Detail}>
      <Container>
        <Box
          component="header"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link to={`/${merchantId}`}>
            <img src={Arrow} alt="arrow icon" className="details_arrow" />
            <Text text="Banks" variables={{ 0: id }} component="h3" fs={14} />
          </Link>
          <AddBankAccount />
        </Box>

        <Box
          className={styles.details}
          style={{ border: 'none' }}
          sx={{ '.MuiTableCell-head': { p: 1.5 } }}
        >
          <Table
            page={index}
            changePage={setPage}
            count={banks?.count}
            isLoading={isLoading}
            headers={[
              'Account Holder',
              'Bank',
              'Source Bank',
              'Branch',
              'Account Number',
              'IBAN',
              'Bank Swift',
              'Currency',
              'Mobile',
              'Email',
              'Active',
              'Status',
              'Default Account',
              'Action',
            ]}
          >
            {banks?.results?.map((item) => (
              <StyledTableRow
                sx={{ '.MuiTableCell-body': { p: 1.5 } }}
                key={item.id}
              >
                <StyledTableCell component="th" scope="row">
                  <Text text={item.account_name || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.bank_name || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.source_bank_name || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.bank_branch || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.account_number || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.iban || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.bank_swift || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text
                    text={item.currency ? item.currency + ' ' : '-'}
                    fs={10}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.contact_mobile_number || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text={item.contact_email || '-'} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  {item.is_active ? (
                    <img
                      src={Check}
                      alt="Check"
                      width={12}
                      height={12}
                      style={{ marginInlineStart: 5, marginBottom: '2px' }}
                    />
                  ) : (
                    '-'
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    className={
                      statusClassName[
                        item.status as keyof typeof statusClassName
                      ] || statusClassName.default
                    }
                    style={{
                      minWidth: '80px',
                      paddingBlock: '3px',
                      paddingInline: 'unset',
                    }}
                  >
                    <Text text={item?.status} fs={8} />
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <Checkbox
                    checked={item.is_default}
                    onChange={() => makeAccountDefault(item.id)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={makingAccountDefault || item.is_default}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <EditAccount
                    bankAccount={item}
                    merchantId={merchantId}
                    subtitle="Edit"
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </Table>
        </Box>
      </Container>
    </div>
  );
}

export default Banks;
