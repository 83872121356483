import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import axios from 'axios';
import { showToastError } from 'utils/showToastError';
import { SalesOwnerSchemaType } from './SalesOwnerForm';

export function useUpdateSalesOwner(merchantId: number) {
  const queryClient = useQueryClient();

  const { mutate: updateSalesOwner, isLoading: isUpdatingSalesOwner } =
    useMutation<unknown, Error, SalesOwnerSchemaType>({
      mutationFn: (data) =>
        Services.patch<SalesOwnerSchemaType, unknown>(
          data,
          `merchants/${merchantId}/sales_owners_edit/`,
          true
        ),
      onSuccess: (data) => {
        toast.success('Sales agent has been updated');
        queryClient.invalidateQueries({
          queryKey: merchantKeys.merchant('' + merchantId),
        });
      },
      onError: (err) => {
        const message = axios.isAxiosError(err)
          ? err?.response?.data
          : { message: err.message };
        showToastError(message);
      },
    });

  return { updateSalesOwner, isUpdatingSalesOwner };
}
