import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import * as XLSX from 'xlsx';

import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Button from 'components/button/Button';
import Filters from 'components/filters/Filters';
import Text from 'components/text';
import Arrow from 'assets/images/icons/arrow.svg';
import EmptyTable from 'components/Table/EmptyTable';
import { useTransactionsReport } from './useTransactionsReport';
import { useTransactions } from './useTransactions';
import { showToastError } from 'utils/showToastError';
import DSelect from 'components/input/DSelect';
import { useTransactionsGatewayTypes } from './useTransactionsGatewayTypes';
import { useTransactionsChargebackStatuses } from './useTransactionsChargebackStatuses';

function Transactions() {
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [date, onDateChange] = useState<{ from?: string; to?: string }>({});
  const [selectedKey, setSelectedKey] = useState('');
  const [isSelectedDates, setIsSelectedDates] = useState(false);
  const [gatewayType, setGatewayType] = useState('');
  const [chargebackStatus, setChargebackStatus] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const { data: gatewayList } = useTransactionsGatewayTypes(merchantId!);
  const { data: chargebackStatuses } = useTransactionsChargebackStatuses(
    merchantId!
  );
  const { refetch } = useTransactionsReport({ merchantId, status, date });

  const chargebackStatusesColumn =
    chargebackStatuses?.reduce<Record<number | string, string>>((acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    }, {}) ?? {};

  const filters = {
    merchantId,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
    gatewayType,
    chargebackStatus,
    transactionType,
  };

  const { data, isLoading } = useTransactions(filters);

  const exportRecords = async () => {
    const { isError, data } = await refetch();

    if (isError || !data) {
      showToastError('Something went wrong!');
      return;
    }

    let arr = data?.split('\\r\\n');
    let newArr = [];

    if (!arr) return;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let splitedStr = element.split(',');
      newArr.push(splitedStr);
    }

    let workbook = XLSX.utils.book_new(),
      worksheet = XLSX.utils.aoa_to_sheet(newArr);
    workbook.SheetNames.push('First');
    workbook.Sheets['First'] = worksheet;
    XLSX.writeFile(
      workbook,
      `transactions_records_${date?.from}--${date?.to}.xlsx`
    );
  };

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    setPage(1);
    let dates = value ?? {};
    setIsSelectedDates(Boolean(value) || false);
    onDateChange(dates);
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value.toString());
  };

  const FilteredByGateway = (value: string | number) => {
    setPage(1);
    setGatewayType(value.toString());
  };

  const FilteredByChargebackStatus = (value: string | number) => {
    setPage(1);
    setChargebackStatus(value.toString());
  };

  const FilteredByTransactionType = (value: string | number) => {
    setPage(1);
    setTransactionType(value.toString());
  };

  const GatewayFilter = useCallback(
    () => (
      <DSelect
        placeholder={t('Filter by Gateway Type')}
        onSelectChange={FilteredByGateway}
        data={[
          { label: t('All'), value: '' },
          ...(gatewayList || []).map((type) => ({
            label: t(type.label),
            value: `&gateway_type=${type.value}`,
          })),
        ]}
      />
    ),
    [gatewayList, t]
  );

  const ChargebackStatusFilter = useCallback(
    () => (
      <DSelect
        placeholder={t('Filter by Chargeback Status')}
        onSelectChange={FilteredByChargebackStatus}
        data={[
          { label: t('All'), value: '' },
          ...(chargebackStatuses || []).map((type) => ({
            label: t(type.label),
            value: `&chargeback_status=${type.value}`,
          })),
        ]}
      />
    ),
    [chargebackStatuses, t]
  );

  const TransactionTypeFilter = useCallback(
    () => (
      <DSelect
        placeholder={t('Filter by Transaction Type')}
        onSelectChange={FilteredByTransactionType}
        data={[
          { label: t('All'), value: '' },
          { label: t('Payment'), value: '&transaction_type=Payment' },
          { label: t('Refund'), value: '&transaction_type=Refund' },
          { label: t('Refunded'), value: '&transaction_type=Refunded' },
          { label: t('Void'), value: '&transaction_type=Void' },
          { label: t('Voided'), value: '&transaction_type=Voided' },
          { label: t('Capture'), value: '&transaction_type=Capture' },
          { label: t('Captured'), value: '&transaction_type=Captured' },
          { label: t('Chargeback'), value: '&transaction_type=Chargeback' },
        ]}
      />
    ),
    [t]
  );

  const extendedFilters = (
    <>
      <TransactionTypeFilter />
      <GatewayFilter />
      <ChargebackStatusFilter />
    </>
  );

  return (
    <div>
      <Filters
        showDate
        inputLabel="Search for transactions"
        inputPlaceholder="Search by transaction ID, phone number or Order ID"
        selectPlaceholder="Filter by Status"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        searchSelectors={[
          { label: t('TRNX ID'), value: '' },
          { label: t('Order ID'), value: 'order_id' },
          { label: t('Integration ID'), value: 'integration_id' },
          { label: t('Terminal ID'), value: 'terminal_id' },
          { label: t('Gateway'), value: 'integration_gateway' },
        ]}
        onSelectedKey={setSelectedKey}
        statusData={[
          { label: t('All'), value: '' },
          { label: t('Success'), value: '&success=true' },
          { label: t('Declined'), value: '&success=false&pending=false' },
          { label: t('Pending'), value: '&pending=true' },
          { label: t('Auth'), value: '&is_auth=true' },
        ]}
        onSortChange={FilteredBySort}
        isNeedSorting
        extraFilters={extendedFilters}
      />

      <div style={{ marginBottom: 30, textAlign: 'end' }}>
        <Button
          text="Export transactions"
          disabled={!isSelectedDates}
          handleClick={() => exportRecords()}
          title={!isSelectedDates ? 'You must filter by date first' : ''}
        />
      </div>

      <Table
        page={page}
        changePage={setPage}
        count={data?.count ?? 0}
        isLoading={isLoading}
        headers={[
          'TRNX ID',
          'AMOUNT',
          'TRNX TYPE',
          'GATEWAY TYPE',
          'ORDER ID',
          'INTEGRATION ID',
          'CHARGEBACK',
          'DATA MESSAGE',
          'STATUS',
        ]}
      >
        {data?.results?.map((item, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell component="th" scope="row">
              <Link to={`${item.id}`}>
                <Text text={`#${item.id}`} fs={10} />
                <Text
                  text={format(
                    parseISO(item.created_at),
                    'yyyy/MM/dd - hh:mm a'
                  )}
                  fs={10}
                />
              </Link>
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={`${(parseFloat(item?.amount_in_pounds) ?? 0).toFixed(
                  2
                )} ${item?.currency ?? 'EGP'}`}
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item?.transaction_type ?? '-'} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={item.gateway_type}
                fs={10}
                className={
                  item.gateway_type.toLowerCase().includes('subscription')
                    ? 'status--active--alternative'
                    : ''
                }
              />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={`#${item.order_id}`} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={`#${item.integration_id}`} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={
                  chargebackStatusesColumn[
                    item?.chargeback_status as keyof typeof chargebackStatusesColumn
                  ] ?? 'None'
                }
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={item?.data_message ?? '-'}
                fs={10}
                sx={{ maxWidth: 270 }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <div
                className={
                  item?.success
                    ? 'status--active'
                    : !item?.success && item.pending
                    ? 'status--pending--yellow'
                    : 'status--inactive'
                }
              >
                <Text
                  text={
                    item?.success
                      ? 'SUCCESS'
                      : !item?.success && item.pending
                      ? 'PENDING'
                      : 'DECLINED'
                  }
                  fs={10}
                />
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <img src={Arrow} alt="arrow icon" />
            </StyledTableCell>
          </StyledTableRow>
        ))}

        {data?.results?.length === 0 && <EmptyTable />}
      </Table>
    </div>
  );
}

export default Transactions;
