import React, { CSSProperties, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';

import { useDimensions } from 'hooks/useDimensions';
import Text from 'components/text';
import UserIcon from 'assets/images/icons/user.png';
import RightChevron from 'assets/images/icons/right-chevron.png';
import './MerchantDrawer.scss';

const BackBtnStyle: CSSProperties = {
  background: 'var(--clr-mainBlue)',
  position: 'fixed',
  insetBlockStart: '3%',
  padding: 16,
  borderRadius: 2,
  cursor: 'pointer',
  width: 74,
};

interface MerchantDrawerProps {
  title: string;
  subtitle?: string | ReactNode;
  children: ReactNode;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
}

function MerchantDrawer({
  title,
  subtitle = 'Edit details',
  children,
  setDrawerOpen,
  isDrawerOpen,
}: MerchantDrawerProps) {
  const down900 = useDimensions();
  const { i18n } = useTranslation();

  return (
    <div>
      {typeof subtitle === 'string' ? (
        <div onClick={() => setDrawerOpen(true)}>
          <Text text={subtitle} fs={10} className="btn" />
        </div>
      ) : (
        subtitle
      )}
      <Drawer
        anchor={i18n.language === 'ar' ? 'left' : 'right'}
        open={isDrawerOpen}
        id="drawer"
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            width: down900 ? '80vw' : '43.750vw',
          },
        }}
      >
        <div
          onClick={() => setDrawerOpen(false)}
          style={{
            ...BackBtnStyle,
            insetInlineEnd: down900 ? '80vw' : '43.750vw',
          }}
        >
          <img src={RightChevron} alt="RightChevron" width={16} />
          <img src={UserIcon} alt="usericon" width={16} />
        </div>

        <header>
          <Text text={title} fs={11} />
          <div style={{ justifyContent: 'flex-end' }}>
            <div onClick={() => setDrawerOpen(false)}>
              <Text text="Cancel" className="btn" fs={10} />
            </div>
          </div>
        </header>

        {children}
      </Drawer>
    </div>
  );
}

export default MerchantDrawer;
