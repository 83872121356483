import { useState } from 'react';
import { useParams } from 'react-router-dom';

import AccountManagerForm from '../account-managers/AccountManagerForm';
import MerchantDetailsForm from '../merchant-details-form/MerchantDetailsForm';
import MerchantDrawer from '../MerchantDrawer';
import '../MerchantDrawer.scss';
import FileUpload from '../FileUpload';
import { useUpdateMerchantImage } from './useUpdateMerchantImage';
import Img from 'assets/images/logo.svg';
import SalesOwnerForm from '../sales-owner/SalesOwnerForm';
import {
  MerchantDetailsType,
  useMerchantLogo,
} from 'pages/main/merchants/marchent/useMerchant';
interface MerchantDetailsProps {
  merchantDetails: MerchantDetailsType;
}
const MerchantDetails = ({ merchantDetails }: MerchantDetailsProps) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { mutate: updateMerchantImage, isLoading: isUpdating } =
    useUpdateMerchantImage();
  const { data: image = Img } = useMerchantLogo(
    merchantId,
    merchantDetails?.logo
  );

  const handleUpdateImage = (img?: File) => {
    if (!img || !merchantDetails?.id) return;

    updateMerchantImage({
      data: { logo: img },
      merchantId: merchantDetails?.id,
    });
  };

  const displayImage = merchantDetails?.logo ? (
    <img
      src={
        merchantDetails?.logo.includes('https') ? merchantDetails?.logo : image
      }
      style={{
        objectFit: 'contain',
      }}
      alt="profile page"
    />
  ) : (
    <img src={Img} alt="profile page" />
  );

  return (
    <MerchantDrawer
      title="Edit merchant details"
      setDrawerOpen={setDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <section className="content">
        <FileUpload
          onFileUpdate={handleUpdateImage}
          uploadBtnText="Upload new photo"
          displayImage={displayImage}
          disabled={isUpdating}
        />
      </section>

      <section className="content">
        <MerchantDetailsForm merchantDetails={merchantDetails} />
        <hr />
      </section>

      <section className="content">
        <SalesOwnerForm merchantDetails={merchantDetails} />
        <hr />
      </section>

      <section className="content">
        <AccountManagerForm merchantDetails={merchantDetails} />
      </section>
    </MerchantDrawer>
  );
};
export default MerchantDetails;
