import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type SMSResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: SMSType[];
};

type SMSType = {
  id: number;
  communication_type: string;
  activity_type: string;
  sender: string;
  receiver: string;
  body: string;
  status: string;
  agent: string;
  created_at: string;
};

interface IUseSMSCommunication {
  merchantId?: string;
  page: number;
  searchText: string;
  status: string;
  date: { from?: string; to?: string };
  sort: string;
  selectedKey: string;
}

export function useSMSCommunication(args: IUseSMSCommunication) {
  const { merchantId, page, searchText, status, date, sort, selectedKey } =
    args;

  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';
  const searchKey = selectedKey === '' ? 'id' : selectedKey;

  return useQuery({
    queryKey: merchantKeys.smsCommunication(args),
    queryFn: () =>
      Services.get<SMSResponse>(
        `merchants/${merchantId}/communication/?page=${page}&${searchKey}=${searchText}&status=${status}&${dateFormat}${sort}`,
        true
      ),
  });
}
