import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Container from 'components/layouts/Container';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Arrow from 'assets/images/icons/left-arrow.png';
import EmptyTable from 'components/Table/EmptyTable';
import { useTransferTransactions } from './useTransferTransactions';
import Button from 'components/button/Button';
import { Box } from '@mui/material';
import { useTransfersPDF } from './useTransfersPDF';
import { toast } from 'react-hot-toast';
import { downloadFile } from 'utils/downloadFile';
import { showToastError } from 'utils/showToastError';

function TransferDetail() {
  const { merchantId, id: transferId } = useParams();
  const [page, setPage] = useState(1);
  // const [transformData, setTransformData] = useState({});
  const { data, isLoading } = useTransferTransactions({
    merchantId,
    page,
    transferId,
  });
  const { refetch } = useTransfersPDF(merchantId, transferId);

  const handleGeneratePDF = async () => {
    const { isError, data } = await refetch();
    if (isError) {
      showToastError('Something went wrong!');
    } else if (!isError && data) {
      toast.success('Download will start soon...', { id: 'download' });
      downloadFile(data?.transfer_pdf, 'file.pdf');
    }
  };

  return (
    <div id={styles.transaction_Detail}>
      <Container>
        <header>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link to={`/${merchantId}/transfers`}>
              <img src={Arrow} alt="arrow icon" className="details_arrow" />
              <Text
                text="Transfers"
                variables={{ 0: transferId }}
                variant={'h3'}
                fs={14}
              />
            </Link>
            <Button
              text="Download PDF"
              handleClick={() => handleGeneratePDF()}
              style={{
                marginInlineEnd: 10,
                backgroundColor: 'var(--clr-mainBlue)',
              }}
            />
          </Box>
        </header>

        <div className={styles.details} style={{ border: 'none' }}>
          <Table
            page={page}
            changePage={setPage}
            count={data?.count ?? 0}
            isLoading={isLoading}
            headers={[
              'TRNX ID',
              // "TRANSFER AMOUNT",
              // "GROSS AMOUNT",
              // "RECEIVING ACCOUNT",
              // "STATUS",
            ]}
          >
            {data?.results?.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" scope="row">
                  <Link to={`/${merchantId}/transactions/${item.id}`}>
                    <Text text={`#${item.id}`} fs={10} />
                  </Link>
                </StyledTableCell>
                {/* <StyledTableCell>
                  <Text
                    text="CENTS"
                    variables={{ 0: transformData?.amount_net_pound }}
                    fs={10}
                  />
                  <Text
                    text={format(
                      new Date(item.created_at),
                      "yyyy/MM/dd - hh:mm a"
                    )}
                    fs={10}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Text text="EGP" variables={{ 0: transformData?.amount_gross_pound }} fs={10} />
                </StyledTableCell>
                <StyledTableCell>
                  <Text
                    text="Account ending"
                    variables={{
                      0: item?.source_data?.pan,
                    }}
                    fs={10}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    className={
                      item.success ? "status--active" : "status--inactive"
                    }
                  >
                    <Text
                      text={item?.success ? "SUCCESS" : "NOT SUCCESS"}
                      fs={10}
                    />
                  </div>
                </StyledTableCell> */}
              </StyledTableRow>
            ))}

            {data?.results?.length === 0 && <EmptyTable />}
          </Table>
        </div>
      </Container>
    </div>
  );
}

export default TransferDetail;
