import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

interface IUseTransfersRecords {
  merchantId?: string;
  status: string;
  date: { from?: string; to?: string };
}

export function useTransfersRecords({
  merchantId,
  status,
  date,
}: IUseTransfersRecords) {
  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';

  return useQuery({
    queryKey: merchantKeys.transfersRecords(merchantId, status, dateFormat),
    queryFn: () =>
      Services.get<string>(
        `merchants/${merchantId}/transfers/export/?status=${status}&${dateFormat}`,
        true
      ),
    enabled: false,
  });
}
