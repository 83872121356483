import { useState } from 'react';

import MerchantDrawer from '../merchant-drawer/MerchantDrawer';
import Button from 'components/button/Button';
import AddBankAccountForm from './AddAccountForm';

const AddAccount = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClose = () => setIsDrawerOpen(false);

  return (
    <MerchantDrawer
      title="Add new bank account"
      subtitle={
        <Button
          text="Add New Bank Account"
          handleClick={() => setIsDrawerOpen(true)}
        />
      }
      setDrawerOpen={setIsDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <AddBankAccountForm onClose={handleClose} />
    </MerchantDrawer>
  );
};
export default AddAccount;
