import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import Tabs from 'components/tabs/Tabs';
import Text from 'components/text';
import Container from './Container';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Img from 'assets/images/logo.svg';
import MerchantStatus from 'components/merchant-status/MerchantStatus';
import {
  useMerchant,
  useMerchantLogo,
} from 'pages/main/merchants/marchent/useMerchant';
import { useBalance } from 'pages/main/merchants/marchent/useBalance';
import MerchantTabs from 'constants/MerchantTabs';
import AcceptBalanceDetails from 'components/modals/merchant-drawer/accept-balance-Details/acceptBalanceDetails';

const MerchantLayout = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams<{ merchantId: string }>();
  const { data: merchantDetails, isError } = useMerchant(merchantId!);
  const { data: merchantLogo = Img } = useMerchantLogo(
    merchantId,
    merchantDetails?.logo
  );
  const { data: balance } = useBalance(merchantId);
  if (isError) navigate('/', { replace: true });

  return (
    <div id={styles.merchant}>
      <div className={styles.header}>
        <Container>
          <header>
            {merchantDetails?.logo ? (
              <img
                src={
                  merchantDetails?.logo.includes('https')
                    ? merchantDetails?.logo
                    : merchantLogo
                }
                style={{
                  objectFit: 'contain',
                }}
                alt="profile page"
              />
            ) : (
              <img src={Img} alt="profile page" />
            )}
            <div style={{ marginInlineEnd: 35 }}>
              <Text text={merchantDetails?.full_name!} component="h3" fs={14} />
              {merchantDetails?.created_at && (
                <Text
                  text="Joined"
                  variables={{
                    0: format(
                      parseISO(merchantDetails?.created_at),
                      'yyyy-MM-dd'
                    ),
                  }}
                  fs={10}
                />
              )}
            </div>

            <MerchantStatus
              isLive={merchantDetails?.is_live!}
              isActive={merchantDetails?.active!}
              merchantId={merchantDetails?.id!}
            />
          </header>
        </Container>
      </div>

      <Container>
        <section className={styles.cards}>
          <div className={styles.card}>
            <Text text="ACCEPT BALANCE" fs={8} />
            {balance?.results?.length ? (
              <AcceptBalanceDetails balances={balance?.results} />
            ) : (
              <Text
                text="EGP"
                variables={{
                  0: Math.floor(
                    balance?.accept_net_amount ?? 0
                  ).toLocaleString(),
                }}
                fs={12}
              />
            )}
          </div>
          <div className={styles.card}>
            <Text text="BILLS BALANCE" fs={8} />
            <Text
              text="EGP"
              variables={{
                0: Math.floor(balance?.bills_net_amount ?? 0).toLocaleString(),
              }}
              fs={12}
            />
          </div>
          <div className={styles.card}>
            <Text text="CHARGED AMOUNT" fs={8} />
            <Text
              text="EGP"
              variables={{
                0: Math.floor(
                  balance?.accept_chargeback_amount ?? 0
                ).toLocaleString(),
              }}
              fs={12}
            />
          </div>
          <div className={styles.card}>
            <Text text="CASHBACK AMOUNT" fs={8} />
            <Text
              text="EGP"
              variables={{
                0: Math.floor(
                  balance?.accept_chargeback_amount ?? 0
                ).toLocaleString(),
              }}
              fs={12}
            />
          </div>
        </section>

        <Tabs data={MerchantTabs} />
        <Outlet />
      </Container>
    </div>
  );
};

export default MerchantLayout;
