const basePath = '/underwriting';

const UnderwritingTabs = [
  {
    name: 'Open requests',
    link: `${basePath}/requests/pending`,
  },
  {
    name: 'Closed requests',
    link: `${basePath}/requests/closed`,
  },
  {
    name: 'Create New Integration Request',
    link: `${basePath}/requests/new`,
  },
];

export default UnderwritingTabs;
