import Button from 'components/button/Button';
import Text from 'components/text';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './FileUploader.module.scss';
interface UploadingFileProps {
  index: number;
  file: File;
}
const UploadingFile = ({ file, index }: UploadingFileProps) => {
  const [loading, setLoading] = useState<number>(0);

  return (
    <div className={styles.filter_container}>
      <div className={styles.row}>
        <Text
          text={`File_${index + 1}.${file.name.split('.').pop()}`}
          fs={11}
        />
        <div className={styles.close}></div>
      </div>
      <progress id="file" value={loading} max="100">
        {' '}
        {loading}%{' '}
      </progress>
      <div className={styles.row}>
        <Text text={`${loading}% done`} fs={8} />
        <Text text={`0KB/sec`} fs={8} />
      </div>
    </div>
  );
};

function FileUploader(): JSX.Element {
  const ref = useRef<any>();
  const inputRef = useRef<any>();
  const [images, setImages] = useState<File[]>([]);

  useEffect(() => {
    // Prevents default behavior of automatically opening the file
    ref?.current?.addEventListener('dragover', function (e: React.DragEvent) {
      e.preventDefault();
    });

    ref?.current?.addEventListener('drop', function (e: React.DragEvent) {
      e.preventDefault();
      const files = getArrayOfFileData([...e?.dataTransfer.items]);
      setImages([...images, ...files]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Returns an array of the file data
  const getArrayOfFileData = (files: DataTransferItem[]) => {
    const fileDataArray: File[] = [];
    files.forEach((file) => {
      if (file.kind === 'file') {
        fileDataArray.push(file.getAsFile() as File);
      }
    });
    return fileDataArray;
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.drop_file} ref={ref}>
        <input
          ref={inputRef}
          type="file"
          id="file-dropzone"
          multiple
          className={styles.hide}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setImages([...images, e.target?.files?.[0] as File])
          }
        />
        <label htmlFor="file-dropzone">
          <Text text="Drag files to upload" fs={10} />
          <Button
            text="Choose file"
            role="button"
            handleClick={() => handleClick()}
          />
        </label>
      </div>
      {images?.length !== 0 ? (
        <div className={styles.lists}>
          <Text text="Uploading" fs={10} component="span" />
          {images?.map((file, index) => (
            <UploadingFile file={file} key={index} index={index} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default FileUploader;
