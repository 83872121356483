import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IUseDimensionsProps {
  direction?: 'down' | 'up';
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const useDimensions = ({
  direction = 'down',
  breakpoint = 'md',
}: IUseDimensionsProps = {}) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints[direction](breakpoint));
};
