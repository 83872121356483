import { CSSProperties, useState } from 'react';
import { FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material';
import Select from 'components/input/Select';
import Input from 'components/input/Input';
import Text from 'components/text';
import Button from 'components/button/Button';
import FileUploader from 'components/file-upload/FileUploader';
import { useDimensions } from 'hooks/useDimensions';

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  backgroundColor: 'var(--clr-light)',
  maxHeight: '80%',
  overflowX: 'auto',
};

const headerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--clr-mainBlue)',
  paddingInline: 33,
  paddingBlock: 18,
};

const contentStyle: CSSProperties = {
  paddingInline: 33,
};

const tagsStyle: CSSProperties = {
  marginBlock: 30,
};

export default function NewService() {
  const down900 = useDimensions();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div style={{ textAlign: 'end' }}>
        <Button text="Request new service" handleClick={() => handleOpen()} />
      </div>

      <Modal open={open} onClose={handleClose} aria-labelledby="New Service">
        <div style={{ ...style, minWidth: down900 ? '90%' : 470 }}>
          <header style={headerStyle}>
            <Text
              text="Cross selling request"
              fs={10}
              style={{ color: 'white' }}
            />
            <div onClick={handleClose}>
              <Text
                text="Cancel"
                fs={10}
                style={{ color: 'white', cursor: 'pointer' }}
              />
            </div>
          </header>
          <div style={contentStyle}>
            <div style={tagsStyle}>
              <Text
                text="Payment Gateway"
                fs={10}
                style={{ marginBottom: 10 }}
              />
              <Select
                placeholder="Select from list"
                data={[
                  { name: 'Card', value: 'Card' },
                  { name: 'Host 2 Host', value: 'Host 2 Host' },
                  { name: 'E-Wallets', value: 'E-Wallets' },
                  { name: 'Valu', value: 'Valu' },
                  { name: 'Sympl', value: 'Sympl' },
                  { name: 'Premium', value: 'Premium' },
                  { name: 'Get-Go', value: 'Get-Go' },
                  { name: 'Souhoola', value: 'Souhoola' },
                  { name: 'Shahry', value: 'Shahry' },
                  { name: 'Forsa', value: 'Forsa' },
                  { name: 'Aman', value: 'Aman' },
                  { name: 'Cash Agg', value: 'Cash Agg' },
                  { name: 'Bills', value: 'Bills' },
                ]}
              />
            </div>
            <div style={tagsStyle}>
              <Text
                text="Payment Channel"
                fs={10}
                style={{ marginBottom: 10 }}
              />
              <Select
                placeholder="Select from list"
                data={[
                  { name: 'Online iframe', value: 'Online iframe' },
                  { name: 'Standalone links', value: 'Standalone links' },
                  { name: 'POS', value: 'POS' },
                  { name: 'Tap on phone', value: 'Tap on phone' },
                ]}
              />
            </div>

            <div style={tagsStyle}>
              <Text text="Settlement type" fs={10} />
              <RadioGroup
                aria-labelledby="settelment-type"
                name="settelment_Type"
              >
                <FormControlLabel value="PSP" control={<Radio />} label="PSP" />
                <FormControlLabel value="AGG" control={<Radio />} label="AGG" />
              </RadioGroup>
            </div>

            <div style={tagsStyle}>
              <Input label="Rate" placeholder="" />
            </div>

            <div style={tagsStyle}>
              <Text text="Terminal ID" fs={10} style={{ marginBottom: 10 }} />
              <Select placeholder="Select from list" data={[]} />
            </div>

            <div style={tagsStyle}>
              <Text
                text="Attach documents"
                fs={10}
                style={{ marginBottom: 10 }}
              />
              <Text
                text="You may upload multiple"
                fs={10}
                style={{ color: '#9F9D9D' }}
              />
              <FileUploader />
            </div>

            <div style={{ textAlign: 'center', marginBottom: 30 }}>
              <Button
                text="Submit new request"
                handleClick={() => handleClose()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
