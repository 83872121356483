import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';

import Text from 'components/text';
import MerchantDrawer from '../MerchantDrawer';
import Container from 'components/layouts/Container';
import { statusClasses } from 'pages/main/merchants/marchent/transfers';
import TransfersStatus from 'constants/TransfersStatus';
import { useTransferDetails } from 'pages/main/merchants/marchent/transfers/useTransferDetails';

interface TransferInformationsProps {
  transferId: string;
  merchantId: string;
}

const TransferInformations = ({
  transferId,
  merchantId,
}: TransferInformationsProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { data } = useTransferDetails({
    merchantId,
    transferId,
    fetch: isDrawerOpen,
  });

  return (
    <MerchantDrawer
      title="Transfer Informations"
      subtitle={`#${transferId}`}
      setDrawerOpen={setDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <Container>
        <Box sx={{ p: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Text text="Transfer ID" fs={11} />
              <Text text={`#${transferId}`} sx={{ color: '#707070' }} fs={11} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Bank Account" fs={11} />
              <Text
                text={data?.bank_account ?? '-'}
                fs={11}
                sx={{ color: '#707070' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Status" fs={11} />
              <Text
                text={
                  TransfersStatus[
                    data?.status as keyof typeof TransfersStatus
                  ] ?? '-'
                }
                fs={10}
                sx={{ py: 0.5, ml: -0.5 }}
                className={
                  statusClasses[data?.status as keyof typeof statusClasses] ??
                  statusClasses.default
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Created at" fs={11} />
              <Text
                text={
                  data?.created_at
                    ? format(parseISO(data.created_at), 'MMM dd, yyyy, hh:mm a')
                    : '-'
                }
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Net Amount" fs={11} />
              <Text
                text={`${(data?.amount_net_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Gross Amount" fs={11} />
              <Text
                text={`${(data?.amount_gross_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Fees Amount" fs={11} />
              <Text
                text={`${(data?.amount_fees_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="VAT Amount" fs={11} />
              <Text
                text={`${(data?.amount_vat_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Subscription Amount" fs={11} />
              <Text
                text={`${(data?.amount_subscription_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Deposit Amount" fs={11} />
              <Text
                text={`${(data?.amount_deposit_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Balance Transfer Amount" fs={11} />
              <Text
                text={`${(data?.amount_balance_transfer_pound ?? 0).toFixed(
                  2
                )} ${data?.settlement_currency ?? 'EGP'}`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Refund Amount" fs={11} />
              <Text
                text={`${(data?.amount_refund_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Chargeback Amount" fs={11} />
              <Text
                text={`${(data?.amount_chargeback_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Delivery Fee Amount" fs={11} />
              <Text
                text={`${(data?.amount_delivery_fee_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Transfer Fee Amount" fs={11} />
              <Text
                text={`${(data?.transfer_fee_pound ?? 0).toFixed(2)} ${
                  data?.settlement_currency ?? 'EGP'
                }`}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Status" fs={11} />
              <Text
                text={data?.iso_status ?? '-'}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Failure Reason" fs={11} />
              <Text
                text={data?.iso_failure_reason ?? '-'}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Batch ID" fs={11} />
              <Text
                text={data?.iso_batch_id ? '#' + data?.iso_batch_id : '-'}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Transaction ID" fs={11} />
              <Text
                text={
                  data?.iso_transaction_id
                    ? '#' + data?.iso_transaction_id
                    : '-'
                }
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Settlement Date" fs={11} />
              <Text
                text={
                  data?.iso_settlement_date
                    ? format(
                        parseISO(data.iso_settlement_date),
                        'MMM dd, yyyy, hh:mm a'
                      )
                    : '-'
                }
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Iso Amount" fs={11} />
              <Text
                text={data?.iso_amount ? data.iso_amount + '' : '-'}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="Integration IDs" fs={11} />
              <Text
                text={
                  data?.integration_ids
                    ? `#${data?.integration_ids?.join(', #')}`
                    : '-'
                }
                variables={{ 0: 0 }}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text text="TRNX IDs" fs={11} />
              <Text
                text={data?.trx_ids ? `#${data?.trx_ids?.join(', #')}` : '-'}
                variables={{ 0: 0 }}
                sx={{ color: '#707070' }}
                fs={11}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MerchantDrawer>
  );
};
export default TransferInformations;
