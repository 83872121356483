import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type TransferPDFType = { transfer_pdf: string };

export function useTransfersPDF(merchantId = '', transferId = '') {
  return useQuery({
    queryKey: merchantKeys.transfersPDF(merchantId, transferId),
    queryFn: () =>
      Services.get<TransferPDFType>(
        `merchants/${merchantId}/transfers/${transferId}/generate_pdf`,
        true
      ),
    enabled: false,
  });
}
