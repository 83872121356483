import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { NewActivitySchemaType } from './NewActivity';

type NewActivityRequestType = {
  data: NewActivitySchemaType;
  merchantId: string;
};

type NewActivityResponseType = {
  id: number;
  title: string;
  agent: string;
  comment: string;
  changelog: unknown[];
  tags: string[];
  created_at: string;
};

export function useCreateActivity() {
  const queryClient = useQueryClient();

  const { mutate: createActivity, isLoading: isCreatingActivity } = useMutation<
    NewActivityResponseType,
    Error,
    NewActivityRequestType
  >({
    mutationFn: ({ data, merchantId }) =>
      Services.set<NewActivitySchemaType, NewActivityResponseType>(
        data,
        `merchants/${merchantId}/timeline/`,
        true
      ),
    onSuccess: () => {
      toast.success('New Activity has been created');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.activities({}).slice(0, 1),
      });
    },
  });

  return { createActivity, isCreatingActivity };
}
