import { Box, Divider, Grid, Skeleton } from '@mui/material';
import {
  UpdateLegalDocRequestType,
  useUpdateLegalDoc,
} from './useUpdateLegalDoc';
import { downloadFile } from 'utils/downloadFile';
import { useLegal } from 'pages/main/merchants/marchent/overview/useLegal';
import DocumentRow from './DocumentRow';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { documentType } from './MerchantDocuments';

interface MerchantDocsFilesProps {
  setShowDocDetails?: Dispatch<SetStateAction<boolean>>;
  viewDocument: Dispatch<SetStateAction<string | null>>;
  documents: { [key: string]: documentType };
  isFetching: boolean;
}

const MerchantDocsFiles = ({
  setShowDocDetails,
  viewDocument,
  documents,
  isFetching,
}: MerchantDocsFilesProps) => {
  const { merchantId } = useParams();

  const { data: legalDocs } = useLegal(merchantId!);
  const { updateLegalDoc, isLoading: isUpdating } = useUpdateLegalDoc(
    merchantId!,
    legalDocs?.[0]?.id
  );

  const handleUpdateFile = (doc: UpdateLegalDocRequestType) =>
    updateLegalDoc(doc);

  const handleDownload = (filePath: string) => {
    if (!filePath) return;
    const fileName = filePath.split('/').at(-1)?.split('.')[0];
    downloadFile(filePath, fileName ?? 'document');
  };

  const handleView = (document: documentType) => {
    setShowDocDetails?.(true);
    viewDocument(document.document_label);
  };

  return (
    <Box mt={2}>
      {isFetching ? (
        <Grid container rowSpacing={4} sx={{ p: 5 }}>
          <>
            {[1, 2, 3].map((val) => (
              <Fragment key={val}>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={200} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={200} />
                </Grid>
              </Fragment>
            ))}
          </>
        </Grid>
      ) : (
        Object.values(documents).map((document, index) => (
          <Fragment key={document?.id || index}>
            <DocumentRow
              title={document.document_label}
              onFileUpdate={(file?: File) =>
                file && handleUpdateFile({ [document.fileKey]: file })
              }
              isUpdating={isUpdating}
              handleDownload={() => handleDownload(document.document_file)}
              disabledUpload={!document.fileKey}
              disabledEditDownload={!document.document_file}
              handleView={() => handleView(document)}
            />
            {index < Object.values(documents).length - 1 && (
              <Divider variant="middle" />
            )}
          </Fragment>
        ))
      )}
    </Box>
  );
};

export default MerchantDocsFiles;
