import axios from 'axios';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

import { Services } from 'apis/services/Services';
import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { showToastError } from 'utils/showToastError';

export interface IUseBankList {
  label: string;
  value: string;
}

export interface IBanksDetails {
  company_bank_account_name?: string;
  company_bank_account_number?: string;
  company_bank_name?: string;
  company_bank_name_2?: string;
  company_bank_branch?: string;
  company_bank_swift_code?: string;
  iban?: string;
}

interface IUseLegalBanks {
  merchantId?: string;
  legalDocsId?: number;
}

export function useBanksInfo({ merchantId, legalDocsId }: IUseLegalBanks) {
  return useQuery({
    queryKey: underwritingKeys.legalBank({ merchantId, legalDocsId }),
    enabled: !!merchantId && !!legalDocsId,
    queryFn: () =>
      Services.get<IBanksDetails>(
        `merchants/${merchantId}/legal_banks_info/${legalDocsId}/`,
        true
      ),
  });
}

export function useEditBankInfo({ merchantId, legalDocsId }: IUseLegalBanks) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IBanksDetails>({
    mutationFn: (data) =>
      Services.patch<IBanksDetails, unknown>(
        data,
        `merchants/${merchantId}/legal_banks_info/${legalDocsId}/`,
        true
      ),
    onSuccess: () => {
      toast.success('Bank details has been updated');
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.legalBank({ merchantId, legalDocsId }),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useBankList({
  merchantId,
}: Omit<IUseLegalBanks, 'legalDocsId'>) {
  return useQuery({
    queryKey: underwritingKeys.legalBankList(),
    enabled: !!merchantId,
    queryFn: () =>
      Services.get<IUseBankList[]>(
        `merchants/${merchantId}/legal_banks_info/company_bank_list/`,
        true
      ),
  });
}
