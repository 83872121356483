import { CSSProperties, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import Text from 'components/text';
import { useDimensions } from 'hooks/useDimensions';
import Check from 'assets/images/icons/check.svg';
import Close from 'assets/images/icons/close.svg';
import { privateURL } from 'apis/AxiosConfig';
import { fetchImageAsBase64 } from 'utils/fetchImageAsBase64';
import { downloadFile } from 'utils/downloadFile';

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'var(--clr-light)',
  boxShadow: '24',
  outline: 'none',
  marginBlock: 100,
};

const headerStyle: CSSProperties = {
  display: 'inline-block',
  position: 'absolute',
  top: '-2%',
  insetInlineEnd: '-5%',
  cursor: 'pointer',
};

const contentStyle = {
  paddingInline: 33,
};
interface ContractProps {
  url: string;
}
export default function Contract({ url }: ContractProps) {
  const [open, setOpen] = useState(false);
  const [imgContract, setImage] = useState('');
  const down900 = useDimensions();

  useEffect(() => {
    if (open) {
      fetchImageAsBase64(privateURL + '/document' + url)?.then(
        (base64String) => {
          setImage(base64String);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpen = () => {
    if (!url?.includes('pdf')) {
      setOpen(true);
    } else {
      downloadFile(url, 'file.pdf');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setImage('');
  };

  return (
    <div style={{ justifyContent: 'flex-end' }}>
      <div
        style={{ color: 'var(--clr-lightBlue)', cursor: 'pointer' }}
        onClick={() => handleOpen()}
      >
        <Text text="View contract" fs={10} />
        <img src={Check} alt="Check" width="10" height="10" />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="View contract"
        sx={{
          overflowY: 'auto',
        }}
      >
        <div style={style}>
          <header style={headerStyle}>
            <div onClick={handleClose}>
              <img src={Close} alt="Close contract" />
            </div>
          </header>
          <div
            style={{
              ...contentStyle,
              width: down900 ? '90%' : 670,
              minWidth: 300,
              minHeight: 500,
            }}
          >
            <img src={imgContract} alt="contract" width="100%" />
          </div>
        </div>
      </Modal>
    </div>
  );
}
