import React from 'react';
import { Box, Divider, SxProps, Theme, Skeleton } from '@mui/material';
import { format, parseISO } from 'date-fns';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import LegalDocStatus from 'constants/LegalDocStatus';
import { useParams } from 'react-router-dom';
import { useDocuments } from './useDocuments';
import GenerateDocumentBtn from '../generateDocumentBtn';

interface IDocument {
  id: number;
  document_label: string;
  status: number;
  created_at: string;
  updated_at: string;
  reviewed_by: null | string;
}

interface IDocumentSec {
  item: IDocument;
  sx?: SxProps<Theme>;
}

const DocumentSec = ({ item, sx }: IDocumentSec) => {
  const isGeneratedContract = item.document_label === 'Generated Contract';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 3,
        px: 5,
        '&:hover': {
          backgroundColor: '#FAFAFA',
          cursor: 'pointer',
        },
        ...sx,
      }}
    >
      <Box>
        <Text text={item?.document_label} className={styles.key} fs={10} />

        {item?.created_at && (
          <Text
            text="Uploaded on"
            variables={{
              0: format(parseISO(item?.created_at), 'dd MMM yyyy'),
            }}
            sx={{ color: '#707070' }}
            fs={10}
          />
        )}
        {!isGeneratedContract && (
          <Text
            text="Reviewed by"
            variables={{ 0: item.reviewed_by ?? '-' }}
            sx={{ color: '#707070' }}
            fs={10}
          />
        )}
      </Box>
      {!isGeneratedContract && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 1,
          }}
        >
          <Text
            text={
              item?.status === 0
                ? LegalDocStatus[item?.status].split(' ')[0] + ' Approval'
                : LegalDocStatus[item?.status as keyof typeof LegalDocStatus]
            }
            fs={8}
            className={
              item?.status === 0
                ? 'status--pending--yellow'
                : item?.status === 2
                ? 'status--active'
                : item?.status === 3
                ? 'status--inactive'
                : 'status--pending'
            }
          />
        </Box>
      )}
    </Box>
  );
};

const MerchantDocsKey = 'MerchantDocs';

interface IMerchantDocsProps {
  expanded: string | false;
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
  selectedContent: { key: string; id?: string };
}

const MerchantDocs = ({
  expanded,
  onExpanded,
  onSelectedContent,
  selectedContent,
}: IMerchantDocsProps) => {
  const { merchantId } = useParams();
  const { data, isFetching } = useDocuments(merchantId);

  const isExpanded = expanded === MerchantDocsKey;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onExpanded(MerchantDocsKey)}
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
    >
      <AccordionSummary
        aria-controls={`MerchantDocs-content`}
        id={`MerchantDocs-header`}
        sx={{ py: 0.5, px: 4, backgroundColor: isExpanded ? '#fff' : '' }}
      >
        <Text text="Merchant Docs" />
      </AccordionSummary>
      <GenerateDocumentBtn sx={{ m: 2 }} />
      <AccordionDetails sx={{ p: 0, maxHeight: 350, overflow: 'auto' }}>
        {isFetching && (
          <Box sx={{ height: 165 }}>
            {[1, 2, 3].map((val) => (
              <Skeleton
                key={val}
                sx={{ mb: 1 }}
                variant="rectangular"
                width="100%"
                height={50}
              />
            ))}
          </Box>
        )}
        {!isFetching &&
          data?.results?.map((item, idx, arr) => (
            <Box
              key={`${item?.id}_documents`}
              onClick={() =>
                onSelectedContent({ key: MerchantDocsKey, id: item?.id + '' })
              }
              sx={{
                backgroundColor:
                  selectedContent.key === MerchantDocsKey &&
                  selectedContent.id === item?.id + ''
                    ? '#F4F9FD'
                    : '',
              }}
            >
              <DocumentSec item={item} />
              {arr.length - 1 !== idx && <Divider variant="middle" />}
            </Box>
          ))}
        {!isFetching && !data?.results?.length && (
          <Box sx={{ p: 2, px: 5 }}>
            <Text text="No documents available" fs={11} />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default MerchantDocs;
