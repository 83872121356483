import { useState } from 'react';
import MerchantDrawer from '../MerchantDrawer';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Text from 'components/text';
import { Box, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import CheckImg from 'assets/images/icons/check.svg';
import CloseImg from 'assets/images/icons/close.svg';
export type AcceptBalanceDetailsType = {
  id: number;
  amount_balance_transfer_pound: number;
  amount_chargeback_pound: number;
  amount_delivery_fee_pound: number;
  amount_deposit_pound: number;
  amount_fees_pound: number;
  amount_gross_pound: number;
  amount_net_pound: number;
  amount_refund_pound: number;
  amount_subscription_pound: number;
  amount_vat_pound: number;
  bank_account: string;
  bank_account_id: number;
  is_latest: boolean;
  last_updated: string;
};
interface AcceptBalanceProps {
  balances: AcceptBalanceDetailsType[];
}
const DataInfo = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box>
      <Text
        text={label}
        sx={{ textTransform: 'capitalize' }}
        className={styles.key}
        fs={11}
      />
      <Text text={value || '-'} sx={{ color: '#707070' }} fs={11} />
    </Box>
  );
};
const AcceptBalanceDetails = ({ balances }: AcceptBalanceProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const data: AcceptBalanceDetailsType = balances[0];
  const keys = Object.keys(data).filter(
    (key) => key !== 'is_latest' && key !== 'last_updated'
  );

  return (
    <MerchantDrawer
      title="Accept Balance Details"
      subtitle="Show Details"
      setDrawerOpen={setDrawerOpen}
      isDrawerOpen={isDrawerOpen}
    >
      <Grid container rowSpacing={4} sx={{ pt: 4, px: 6 }}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          component="header"
          sx={{ mt: 2 }}
        >
          <Text
            text={data?.bank_account}
            fs={12}
            sx={{ fontStyle: 'italic' }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                backgroundColor: data?.is_latest ? '' : 'var(--clr-lightBlue)',
                borderRadius: '100%',
                width: 24,
                height: 24,
              }}
            >
              {data?.is_latest ? (
                <img src={CheckImg} alt="check mark" />
              ) : (
                <img src={CloseImg} alt="close mark" />
              )}
            </Box>
            <Text
              text={'Is Latest'}
              className={styles.key}
              fs={11}
              sx={{ px: 1 }}
            />
          </Box>
        </Grid>
        {keys?.map((key) => (
          <Grid key={key} item xs={12} lg={6}>
            <DataInfo
              label={key?.replaceAll('_', ' ')}
              value={`${data[key as keyof typeof data]}`}
            />
          </Grid>
        ))}
        <Grid item xs={12} lg={6}>
          <DataInfo
            label="Last Updated"
            value={format(
              parseISO(data['last_updated']),
              'MMM dd, yyyy, hh:mm a'
            )}
          />
        </Grid>
      </Grid>
    </MerchantDrawer>
  );
};
export default AcceptBalanceDetails;
