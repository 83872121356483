import { Button as UploadBtn } from '@mui/material';

import Text from 'components/text';
import './MerchantDrawer.scss';
import { ChangeEvent } from 'react';
import { showToastError } from 'utils/showToastError';

interface FileUploadProps {
  onFileUpdate: (file?: File) => void;
  uploadBtnText?: string;
  displayImage?: JSX.Element;
  disabled: boolean;
  isImageOnly?: boolean;
}

const FileUpload = ({
  onFileUpdate,
  uploadBtnText = 'Upload new photo',
  displayImage,
  disabled,
  isImageOnly,
}: FileUploadProps) => {
  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    const validImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
      'image/webp',
    ];
    const validFileTypes = validImageTypes.concat('application/pdf');
    const fileType = file['type'];
    let errorMsg = '';

    if (isImageOnly && !validImageTypes.includes(fileType))
      errorMsg = 'Please select an image.';
    else if (!validFileTypes.includes(fileType))
      errorMsg = 'Please select an image or pdf.';

    if (errorMsg) return;
    else onFileUpdate(file);
  };

  return (
    <div className={`row ${displayImage && 'upload-img'}`}>
      {displayImage}
      <UploadBtn
        size="small"
        variant="text"
        sx={{
          justifyContent: displayImage ? 'flex-start' : 'center',
        }}
        component="label"
        disabled={disabled}
      >
        <input
          hidden
          accept={`image/*${isImageOnly ? '' : ',application/pdf'}`}
          type="file"
          onChange={handleChangeFile}
        />
        <Text
          className="btn"
          text={uploadBtnText}
          fs={10}
          sx={{ color: disabled ? 'var(--clr-gray)' : '' }}
        />
      </UploadBtn>
    </div>
  );
};
export default FileUpload;
