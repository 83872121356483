import { forwardRef } from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { styled } from '@mui/system';

const BpIcon = styled('span')({
  borderRadius: 3,
  width: 18,
  height: 18,
  border: '1px solid #DEDEDE;',
});

const BpCheckedIcon = styled(BpIcon)({
  border: '1px solid #1782FB;',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23137cbd'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const Checkbox = forwardRef<HTMLButtonElement, MuiCheckboxProps>(
  (props, ref) => (
    <MuiCheckbox
      sx={{ '&:hover': { bgcolor: 'transparent' } }}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      ref={ref}
      {...props}
    />
  )
);

export default Checkbox;
