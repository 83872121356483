import { Dispatch, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import EmptyTable from 'components/Table/EmptyTable';
import Table, { StyledTableRow, StyledTableCell } from 'components/Table/Table';
import Text from 'components/text';
import useRequestsTable, { RequestItemType } from './useRequestsTable';
import Check from 'assets/images/icons/check.svg';

interface RequestsTableProps {
  filters?: string;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const statusClassName = {
  approved: 'status--active',
  rejected: 'status--inactive',
  pending: 'status--pending--yellow',
  updated: 'status--updated',
};

const RequestsTable = ({ filters, page, setPage }: RequestsTableProps) => {
  const location = useLocation();
  const { data, isLoading } = useRequestsTable(page, filters);

  const handelRate = (item: RequestItemType) => {
    const rateType = item?.integration_rate_type || '-';
    const fixedRate = item?.integration_rate_fixed ?? '0.0';
    const percentRate = item?.integration_rate_percentage ?? '0.0';

    switch (rateType) {
      case 'Fixed':
        return `${fixedRate} EGP`;
      case 'Percent':
        return `${percentRate}%`;
      case 'Percent & Fixed':
        return `${percentRate}% + ${fixedRate} EGP`;
      default:
        return rateType;
    }
  };

  return (
    <Table
      page={page}
      changePage={setPage}
      count={data?.count ?? 0}
      isLoading={isLoading}
      headers={[
        'MID',
        'NAME',
        'USERNAME',
        'PHONE NO.',
        'GATEWAY',
        'INTEGRATION SERVICE',
        'INTEGRATION TYPE',
        'REVIEWED BY',
        'RATES',
        'STATUS',
      ]}
    >
      {data?.results?.map((item) => (
        <StyledTableRow key={item?.id}>
          <StyledTableCell component="th" scope="row">
            <Link
              to={`/underwriting/${item?.merchant_id}/request/${item.id}`}
              state={{ from: location.pathname }}
            >
              <Text text={`#${item?.merchant_id ?? ''}`} fs={10} />
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            <Link
              to={`/underwriting/${item?.merchant_id}/request/${item.id}`}
              state={{ from: location.pathname }}
            >
              <Text text={item?.company_name ?? ''} component="span" fs={10} />
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            <Text text={item?.username ?? ''} component="span" fs={10} />
          </StyledTableCell>
          <StyledTableCell>
            {item?.phone ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Text text={item?.phone ?? ''} component="span" fs={10} />
                {item?.verified_phone && (
                  <Tooltip title="Verified">
                    <img
                      src={Check}
                      alt="Check"
                      width={12}
                      height={12}
                      style={{ marginInlineStart: 5, marginBottom: '2px' }}
                    />
                  </Tooltip>
                )}
              </Box>
            ) : (
              '-'
            )}
          </StyledTableCell>
          <StyledTableCell>
            <Text
              text={item?.gateway_type?.replace('_', ' ') ?? ''}
              component="span"
              fs={10}
            />
          </StyledTableCell>
          <StyledTableCell>
            <Text
              text={item?.integration_service?.replace('_', ' ') ?? ''}
              component="span"
              fs={10}
            />
          </StyledTableCell>
          <StyledTableCell>
            <Text
              text={item?.integration_type?.replace('_', ' ') ?? ''}
              component="span"
              fs={10}
            />
          </StyledTableCell>
          <StyledTableCell>
            <Text text={item?.reviewed_by ?? ''} component="span" fs={10} />
          </StyledTableCell>
          <StyledTableCell>
            <Text text={handelRate(item)} component="span" fs={10} />
          </StyledTableCell>
          <StyledTableCell>
            <div
              className={
                statusClassName[
                  item?.status
                    ?.toLocaleLowerCase()
                    ?.split(' ')?.[0] as keyof typeof statusClassName
                ]
              }
            >
              <Text text={item?.status ?? ''} component="span" fs={10} />
            </div>
          </StyledTableCell>
        </StyledTableRow>
      ))}

      {data?.results?.length === 0 && <EmptyTable />}
    </Table>
  );
};
export default RequestsTable;
