import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type POSResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: POSType[];
};

type POSType = {
  id: number;
  android_id: string;
  imei: string;
  serial_no: null | string;
  sim_serial: null | string;
  version: null | string;
  active: boolean;
  created_at: string;
  contract_link: null | string;
  integrations: Integration[];
  user_username?: string;
};

type Integration = {
  id: number;
  gateway_type: string;
  created_at: string;
  is_live: boolean;
};

interface IUsePOS {
  merchantId?: string;
  page: number;
  searchText?: string;
  selectedKey?: string;
}

export function usePOS(args: IUsePOS) {
  const { merchantId, page, searchText, selectedKey } = args;
  const searchBy = searchText ? `&${selectedKey}=${searchText}` : '';

  return useQuery({
    queryKey: merchantKeys.pos(args),
    queryFn: () =>
      Services.get<POSResponse>(
        `merchants/${merchantId}/terminals/?page=${page}${searchBy}`,
        true
      ),
  });
}
