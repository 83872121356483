import Text from 'components/text';
import { StyledTableCell, StyledTableRow } from './Table';

export default function EmptyTable() {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={12}>
        <div
          style={{
            paddingBlock: 21,
            textAlign: 'center',
          }}
        >
          <Text text={'No Data to be showen'} component="p" fs={18} />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}
