import { useForm } from 'react-hook-form';
import { getFormDefaultValues } from 'utils/getFormDefaultValues';
import { Box, SxProps, Theme } from '@mui/material';
import Input from 'components/input/Input';
import Select from 'components/input/Select';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import Text from 'components/text';
import Button from 'components/button/Button';
import { Detail } from 'pages/main/underwriting/documents/merchantDocs/useDocuments';

const isNumValid = (value: string) => /^[0-9]+$/gi.test(value);
const isStringValid = (value: string) => /^[\w\-\s]+$/gi.test(value);

interface FormBuilderProps {
  formData: Detail[];
  onSubmit: (values: any) => void;
  sx?: SxProps<Theme>;
  submitText?: string;
  disableSubmit: boolean;
}

const boolOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

const FormBuilder = ({
  formData,
  onSubmit,
  sx,
  submitText,
  disableSubmit,
}: FormBuilderProps) => {
  const defaultValues = getFormDefaultValues(formData);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues,
  });
  console.log('🚀 ~ errors:', errors);

  const RenderInput = ({
    label,
    detail_type,
    keyVal,
  }: {
    label: string;
    detail_type: string;
    keyVal: string;
  }) => {
    switch (detail_type) {
      case 'datetime':
        return (
          <Box>
            <Text text={label ?? ''} fs={10} />
            <DatePicker
              size={'large'}
              defaultValue={dayjs(getValues(keyVal))}
              onChange={(val) => {
                setValue(keyVal, val?.format()!);
              }}
              style={{ width: '100%' }}
            />
          </Box>
        );
      case 'bool':
        return (
          <Select
            {...register(keyVal)}
            label={label}
            data={boolOptions}
            placeholder={label ?? ''}
            sx={{ width: '100%' }}
            defaultValue={getValues(keyVal)}
          />
        );
      default:
        return (
          <Input
            {...register(keyVal, {
              required: `${label} is required`,
              maxLength: {
                value: 50,
                message: `${label} is not valid`,
              },
              validate: (input) => {
                if (detail_type === 'int' && !isNumValid(input)) {
                  return `${label} is not valid`;
                }
                if (detail_type === 'str' && !isStringValid(input)) {
                  return `${label} is not valid`;
                }
                return true;
              },
            })}
            label={label}
            placeholder={label}
            have_error={errors[keyVal]?.message}
            sx={{ width: '100%' }}
          />
        );
    }
  };

  return (
    <form>
      <Box sx={sx}>
        {formData?.map((item) => (
          <Box>
            <RenderInput {...item} keyVal={item?.key} />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          sx={{ mt: 2 }}
          text={submitText || 'Submit'}
          disabled={disableSubmit}
          title={disableSubmit ? 'Submitting...' : ''}
        />
      </Box>
    </form>
  );
};

export default FormBuilder;
