import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Skeleton } from '@mui/material';

import Activity from 'components/cards/activity/Activity';
import Filters from 'components/filters/Filters';
import NewActivity from 'components/modals/newActivity/NewActivity';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import { useGeneral } from './useTimeline';

function General() {
  const { merchantId } = useParams();
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [date, onDateChange] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const { t } = useTranslation();

  const filters = {
    merchantId,
    searchText,
    date,
    sort,
    selectedKey,
  };

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching } =
    useGeneral(filters);

  const FilteredBySearch = (value: string | number) => {
    setSearchText(value.toString());
  };

  const FilteredByStatus = (value: string | number) => {
    setStatus(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    let dates = value ?? {};
    onDateChange(dates);
  };

  const FilteredBySort = (value: string | number) => {
    setSort(value.toString());
  };

  const skeletons = (
    <>
      {[1, 2, 3].map((val) => (
        <Box key={val} sx={{ px: 4.5 }}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" width={170} />
          <Skeleton variant="text" width={200} />
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
    </>
  );

  let loadMoreText = '';
  if (isFetchingNextPage) loadMoreText = 'Loading more...';
  else if (hasNextPage) loadMoreText = 'Load More';

  return (
    <div id={styles.timeline} style={{ width: '100%' }}>
      <Filters
        showDate
        inputLabel="Search General"
        inputPlaceholder="Type to search list"
        selectPlaceholder="Filter by activity"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        searchSelectors={[
          { label: t('Activity ID'), value: '' },
          { label: t('Activity Type'), value: 'type' },
          { label: t('Activity Title'), value: 'title' },
        ]}
        onSelectedKey={setSelectedKey}
        statusData={[]}
        onSortChange={FilteredBySort}
        isNeedSorting
      />

      <section className={styles.account_activity}>
        <header>
          <Text text="Account activity" fs={14} />
          <NewActivity />
        </header>

        {!data && isFetching && skeletons}

        {data?.pages?.map((page) => (
          <Fragment key={page.next}>
            {page?.results?.map((item) => (
              <Activity item={item} key={item.id} />
            ))}
          </Fragment>
        ))}

        {loadMoreText && (
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
            <Button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              <Text
                text={loadMoreText}
                component="p"
                fs={12}
                style={{
                  color: hasNextPage ? 'var(--clr-lightBlue)' : '',
                  textDecorationLine: hasNextPage ? 'underline' : '',
                }}
              />
            </Button>
          </Box>
        )}

        {data?.pages[0]?.results?.length === 0 && (
          <div
            style={{
              paddingBlock: 21,
              textAlign: 'center',
            }}
          >
            <Text text={'No Data to be showen'} component="p" fs={18} />
          </div>
        )}
      </section>
    </div>
  );
}

export default General;
