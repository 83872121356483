import { Button } from '@mui/material';
import Text from 'components/text';

const NewIntegrationRequestKey = 'NewIntegrationRequest';

interface INewIntegrationRequestProps {
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
}

const NewIntegrationRequest = ({
  onExpanded,
  onSelectedContent,
}: INewIntegrationRequestProps) => {
  const handleSelectedContent = () => {
    onExpanded('');
    onSelectedContent({ key: NewIntegrationRequestKey });
  };

  return (
    <Button
      onClick={handleSelectedContent}
      variant="text"
      sx={{ width: '100%' }}
      size="large"
    >
      <Text text="Create New Integration Request" fs={10} />
    </Button>
  );
};

export default NewIntegrationRequest;
