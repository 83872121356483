import { CSSProperties, ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface TextProps {
  text: string;
  variables?: any;
  component?: ElementType;
  variant?: Variant;
  className?: string;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
  fs?: number | string;
}

function Text({
  text,
  variables,
  component,
  variant,
  sx,
  className,
  style,
  fs,
}: TextProps) {
  const { t, i18n } = useTranslation();
  const textStyle: CSSProperties = {
    fontFamily: `var(--ff-${i18n.language === 'ar' ? 'CMedium' : 'GMedium'})`,
    fontSize: `var(--fs-${fs ?? '12'})`,
  };
  return (
    <Typography
      component={component ?? 'h5'}
      style={{ ...textStyle, ...style }}
      className={className ?? ''}
      sx={sx}
      variant={variant}
    >
      {t(text, variables)}
    </Typography>
  );
}

export default Text;
