import { Detail } from 'pages/main/underwriting/documents/merchantDocs/useDocuments';

export const getFormDefaultValues = (
  data: Detail[]
): { [key: string]: string } => {
  return data.reduce(
    (acc, curr, idx) => ({ ...acc, [data[idx].key]: curr.value }),
    {}
  );
};
