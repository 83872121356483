import axios from 'axios';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

import { Services } from 'apis/services/Services';
import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { showToastError } from 'utils/showToastError';
import { IntegrationRequestSchemaFormType } from './details';

interface ListResponse<T> {
  label: string;
  value: T;
}

interface IUseMerchantTypes {
  merchant_types: ListResponse<string>[];
}

interface IUseBanks {
  available_banks: ListResponse<number>[];
}

interface IUseOptions {
  integration_options: ListResponse<number>[];
}

interface IUsePlatforms {
  platforms: ListResponse<string>[];
}

interface IUseRates {
  available_rates: ListResponse<number>[];
}

interface IUseRiskPrevention {
  available_risk_preventions: ListResponse<number>[];
}

interface IUseServices {
  available_services: ListResponse<number>[];
}

interface IIntegrationRequest extends IntegrationRequestSchemaFormType {
  merchant_id: number;
}

export function useCreateIntegrationRequest() {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IIntegrationRequest>({
    mutationFn: (data) =>
      Services.set<IIntegrationRequest, unknown>(
        data,
        `integration_requests/`,
        true
      ),
    onSuccess: () => {
      toast.success('Request has been created');
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.integrationRequest(),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useMerchantTypes() {
  return useQuery({
    queryKey: underwritingKeys.integrationMerchantTypes(),
    queryFn: () =>
      Services.get<IUseMerchantTypes>(
        `integration_requests/merchant_types_list/`,
        true
      ),
  });
}

export function useBanks() {
  return useQuery({
    queryKey: underwritingKeys.integrationBanks(),
    queryFn: () =>
      Services.get<IUseBanks>(`integration_requests/banks_list/`, true),
  });
}

export function useOptions() {
  return useQuery({
    queryKey: underwritingKeys.integrationOptions(),
    queryFn: () =>
      Services.get<IUseOptions>(
        `integration_requests/integration_options/`,
        true
      ),
  });
}

export function usePlatforms() {
  return useQuery({
    queryKey: underwritingKeys.integrationPlatforms(),
    queryFn: () =>
      Services.get<IUsePlatforms>(`integration_requests/platforms_list/`, true),
  });
}

export function useRates() {
  return useQuery({
    queryKey: underwritingKeys.integrationRates(),
    queryFn: () =>
      Services.get<IUseRates>(`integration_requests/rates_list/`, true),
  });
}

export function useRiskPrevention() {
  return useQuery({
    queryKey: underwritingKeys.integrationRiskPrevention(),
    queryFn: () =>
      Services.get<IUseRiskPrevention>(
        `integration_requests/risk_prevention_list/`,
        true
      ),
  });
}

export function useServices() {
  return useQuery({
    queryKey: underwritingKeys.integrationServices(),
    queryFn: () =>
      Services.get<IUseServices>(
        `integration_requests/integration_services/`,
        true
      ),
  });
}
