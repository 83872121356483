import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SxProps, Theme, styled } from '@mui/material/styles';
import {
  Tabs as MuiTabs,
  Tab,
  TabProps,
  TabsProps as MUITabsProps,
} from '@mui/material';
import { useDimensions } from 'hooks/useDimensions';
import { useTranslation } from 'react-i18next';

type AntTabsProps = { variant?: string } & MUITabsProps;

const AntTabs = styled((props: AntTabsProps) => (
  <MuiTabs
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    {...props}
  />
))({
  marginBlock: 30,
  borderBottom: '1px solid #DEDEDE',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 3,
    borderRadius: 3,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '90%',
    width: '100%',
    backgroundColor: '#1782FB',
  },
});

type AntTabProps = { lang?: string } & TabProps;

const AntTab = styled((props: AntTabProps) => <Tab disableRipple {...props} />)(
  ({ theme, lang }) => ({
    textTransform: 'none',
    minWidth: 180,
    paddingBlock: 16,
    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
    },
    color: '#AAAAAA',
    fontFamily: lang === 'ar' ? 'var(--ff-CMedium)' : 'var(--ff-GMedium)',
    fontSize: 'var(--fs-10)',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: 'black',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  })
);

interface TabsProps extends AntTabsProps {
  data: { name: string; link: string }[];
  sx?: SxProps<Theme>;
  isNested?: boolean;
}

export default function Tabs({ data, sx, isNested, ...props }: TabsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const down900 = useDimensions();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let index = data.findIndex((item) =>
      item.link.includes(location?.pathname?.split('/')[isNested ? 3 : 2])
    );
    setValue(index === -1 ? 0 : index);
  }, [data, isNested, location]);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <AntTabs
      variant={down900 ? 'scrollable' : 'standard'}
      value={value}
      onChange={handleChange}
      aria-label="Merchant nav"
      sx={sx}
      {...props}
    >
      {data.map((item) => (
        <AntTab
          key={item.name}
          label={t(item.name)}
          onClick={() => navigate(item.link)}
          lang={i18n.language}
        />
      ))}
    </AntTabs>
  );
}
