import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import {
  Skeleton,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Pagination from '@mui/material/Pagination';
import Text from 'components/text';
import { Dispatch, SetStateAction } from 'react';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#707070',
    border: 0,
    height: 65,
    textAlign: 'start',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 'var(--fs-10)',
    textAlign: 'start',
  },
}));

type StyledTableRowProps = { bgForHeadOnly?: boolean } & TableRowProps;

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ theme, bgForHeadOnly }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: bgForHeadOnly ? 'white' : '#F9FAFC',
    },
    'td, th': {
      border: 0,
      height: 65,
    },
  })
);

const LoadingSkeleton = ({ headers }: { headers: TableProps['headers'] }) => (
  <>
    {[1, 3, 4, 5].map((_, index) => (
      <StyledTableRow key={index}>
        {headers.map((item) => (
          <StyledTableCell key={item}>
            <Skeleton variant="rectangular" width={100} height={5} />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ))}
  </>
);
interface TableProps {
  headers: string[];
  children: React.ReactNode;
  count: number;
  page: number;
  changePage: React.Dispatch<React.SetStateAction<number>>;
  hidePagination?: boolean;
  topHeader?: React.ReactNode;
  bgForHeadOnly?: boolean;
  isLoading?: boolean;
  checkBox?: tableCheckBoxData;
}
type tableCheckBoxData = {
  showCheckBox: boolean;
  rowCount: number;
  numSelected: number;
  checkBoxAction: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
};

function Table({
  headers,
  children,
  hidePagination,
  topHeader,
  bgForHeadOnly,
  isLoading,
  count,
  changePage,
  page,
  checkBox,
}: TableProps) {
  return (
    <TableContainer
      sx={{
        borderRadius: 0.5,
        marginBottom: '20px',
        border: '1px solid #DEDEDE',
      }}
    >
      {topHeader}
      <MuiTable sx={{ minWidth: 700 }} aria-label="Merchants table">
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow
            sx={{
              backgroundColor: !bgForHeadOnly ? 'white' : '#F9FAFC',
            }}
          >
            {checkBox?.showCheckBox && (
              <StyledTableCell padding="checkbox">
                <Checkbox
                  onChange={checkBox?.checkBoxAction}
                  indeterminate={
                    checkBox?.numSelected > 0 &&
                    checkBox?.numSelected < checkBox?.rowCount
                  }
                  checked={
                    checkBox?.rowCount > 0 &&
                    checkBox?.numSelected === checkBox?.rowCount
                  }
                  color="primary"
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </StyledTableCell>
            )}
            {headers.map((item) => (
              <StyledTableCell key={item}>
                <Text text={item} fs={10} />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? <LoadingSkeleton headers={headers} /> : children}
        </TableBody>
      </MuiTable>
      {!hidePagination && (
        <div style={{ textAlign: 'center', marginBlock: '50px' }}>
          <Pagination
            count={Math.ceil(Number(count ?? 10) / 10)}
            onChange={(_, value) => {
              changePage(value);
              checkBox?.setSelectedItems?.([]);
            }}
            defaultPage={page}
            page={page}
            sx={{
              marginBlock: '20px',
              ul: {
                justifyContent: 'center',
              },
              '& .MuiPaginationItem-page': {
                color: '#B7B7C1',
                borderRadius: 0.5,
              },
              '& .Mui-selected': {
                backgroundColor: 'var(--clr-lightBlue) !important',
                color: 'var(--clr-light)',
              },
            }}
          />
          <Text
            text={'Results'}
            variables={{
              0: 1,
              1: count < 10 ? count : 10,
              2: count,
            }}
            fs={10}
            style={{ color: '#B7B7C1' }}
          />
        </div>
      )}
    </TableContainer>
  );
}

export default Table;
