import { useQuery } from '@tanstack/react-query';
import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
type IntegrationRequestsResponse = {
  count: number;
  results: RequestItemType[];
};
export type RequestItemType = {
  id: number;
  merchant_id: string;
  username: string;
  company_name: string;
  governorate_en: string;
  governorate_ar: string;
  city_en: string;
  city_ar: string;
  neighborhood_en: string;
  neighborhood_ar: string;
  phone: string;
  verified_phone: boolean;
  status: string;
  reviewed_by: string;
  integration_service: string;
  gateway_type: string;
  integration_type: string;
  integration_rate: string;
  is_psp: boolean;
  created_at: string;
  updated_at: string;
  integration_rate_percentage: string;
  integration_rate_fixed: string;
  integration_rate_type:
    | 'Percent'
    | 'Fixed'
    | 'Percent & Fixed'
    | 'Tiers'
    | 'Complex';
};
const useRequestsTable = (page: number, filters?: string) => {
  return useQuery({
    queryKey: underwritingKeys.integrationRequests({ filters, page }),
    queryFn: () =>
      Services.get<IntegrationRequestsResponse>(
        `integration_requests/?page=${page}${filters}`,
        true
      ),
    enabled: filters?.includes('status='),
  });
};

export default useRequestsTable;
