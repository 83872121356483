import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';
import { styled } from '@mui/system';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Filters from 'components/filters/Filters';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import Text from 'components/text';
import { useVisitNPS } from './useTimeline';

const StyledGrid = styled(Grid)({
  '& .MuiGrid-item': {
    display: 'flex',
    alignItems: 'flex-center',
    gap: 6,
    color: 'var(--clr-gray)',
    marginBottom: 8,
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  justifyContent: 'flex-start',
  gap: 8,
  backgroundColor: '#fff',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  color: 'var(--clr-lightBlue)',
  marginBottom: -20,
  padding: 0,
  '& .MuiAccordionSummary-content': {
    flexGrow: 0,
  },
  '&:active': {
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.8rem',
    color: 'var(--clr-lightBlue)',
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: '#f6f7fa',
  borderRadius: 1,
  marginTop: 20,
  borderTop: 'none',
  color: 'var(--clr-gray)',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const StyledBoxDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

function VisitNPS() {
  const { merchantId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [selectedKey, setSelectedKey] = useState('visit_id');
  const [date, onDateChange] = useState({});
  const { t } = useTranslation();

  const filters = {
    merchantId,
    searchText,
    selectedKey,
    date,
  };

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching } =
    useVisitNPS(filters);

  const FilteredBySearch = (value: string | number) => {
    setSearchText(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    let dates = value ?? {};
    onDateChange(dates);
  };

  const skeletons = (
    <>
      {[1, 2, 3].map((val) => (
        <Grid
          key={val}
          item
          xs={12}
          className={styles.details}
          style={{ width: '100%' }}
          sx={{ mb: 4 }}
        >
          <Skeleton variant="text" sx={{ height: 60, mt: -1.7 }} />
          <Box sx={{ display: 'flex', gap: 3, mx: 2, mb: 3 }}>
            <Skeleton variant="text" sx={{ height: 30 }} width={120} />
            <Skeleton variant="text" sx={{ height: 30 }} width={70} />
            <Skeleton
              variant="text"
              sx={{ height: 30, ml: 'auto' }}
              width={50}
            />
          </Box>
        </Grid>
      ))}
    </>
  );

  let loadMoreText = '';
  if (isFetchingNextPage) loadMoreText = 'Loading more...';
  else if (hasNextPage) loadMoreText = 'Load More';

  return (
    <div id={styles.timeline} style={{ width: '100%' }}>
      <Filters
        inputLabel="Search Visit NPS"
        inputPlaceholder="Type to search list"
        onSearchChange={FilteredBySearch}
        searchSelectors={[
          { label: t('Visit ID'), value: 'visit_id' },
          { label: t('Agent ID'), value: 'agent_id' },
          { label: t('Terminal ID'), value: 'terminal_id' },
        ]}
        onSelectedKey={setSelectedKey}
        onDateChange={FilteredByDate}
        showDate
      />

      <div id={styles.overview}>
        <Grid container>
          {!data && isFetching && skeletons}

          {data?.pages?.map((page) => (
            <Fragment key={page.next}>
              {page?.results?.map((item) => (
                <Grid
                  key={item.visit_id}
                  item
                  xs={12}
                  className={styles.details}
                  style={{ width: '100%' }}
                  sx={{ mb: 4 }}
                >
                  <Box component="header" sx={{ fontWeight: 'light' }}>
                    <Text
                      text="Visit NPS"
                      fs={11}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                    <div style={{ textAlign: 'end' }}>
                      <Text
                        text={
                          item.created_at
                            ? format(
                                parseISO(item.created_at),
                                'dd/MM/yyyy, hh:mm a'
                              )
                            : '-'
                        }
                        fs={11}
                        sx={{ color: 'var(--clr-gray)' }}
                      />
                    </div>
                  </Box>
                  <Grid container sx={{ p: 3 }}>
                    <Grid item xs={12}>
                      <StyledGrid container flexDirection="column">
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={1.5}>
                            <ContactsOutlinedIcon />
                            <Text
                              text={item.agent_id}
                              fs={11}
                              sx={{ mt: 0.3 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8.5}>
                            <Box
                              sx={{
                                backgroundColor: '#e8f3ff',
                                px: 1.5,
                                borderRadius: 6,
                              }}
                            >
                              <Text
                                text={item.visit_reason}
                                fs={10}
                                sx={{ mt: 0.3 }}
                              />
                            </Box>
                          </Grid>
                          <Grid item alignItems="center">
                            <Box
                              sx={{
                                backgroundColor: '#f2876b',
                                width: 10,
                                height: 10,
                                borderRadius: '100%',
                                mb: 0.3,
                              }}
                            ></Box>
                            <Text text="NPS" fs={11} sx={{ mr: 1 }} />
                            <Text
                              text={item.nps + ''}
                              style={{ fontSize: 24 }}
                            />
                          </Grid>
                        </Grid>
                      </StyledGrid>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{ border: 'none' }}
                      >
                        <StyledAccordionSummary
                          aria-controls={`Documents-content`}
                          id={`Documents-header`}
                        >
                          <Text text="More details" fs={11} sx={{ mb: -0.3 }} />
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          <StyledBoxDetails>
                            <NumbersOutlinedIcon />
                            <Text
                              text={`Visit ID: ${item.visit_id || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <CalendarTodayOutlinedIcon />
                            <Text
                              text={`Created Date: ${
                                item.created_at
                                  ? format(
                                      parseISO(item.created_at),
                                      'dd/MM/yyyy'
                                    )
                                  : '-'
                              }`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <CalendarTodayOutlinedIcon />
                            <Text
                              text={`Updated Date: ${
                                item.updated_at
                                  ? format(
                                      parseISO(item.updated_at),
                                      'dd/MM/yyyy'
                                    )
                                  : '-'
                              }`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <ShortTextOutlinedIcon />
                            <Text
                              text={`Visit Reason: ${item.visit_reason || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <TypeSpecimenOutlinedIcon />
                            <Text
                              text={`Visit Type: ${item.visit_type || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <ContactsOutlinedIcon />
                            <Text
                              text={`Agent ID: ${item.agent_id || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <NumbersOutlinedIcon />
                            <Text
                              text={`Terminal ID: ${item.terminal_id || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <LocationOnOutlinedIcon />
                            <Text
                              text={`Latitude: ${item.latitude || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <LocationOnOutlinedIcon />
                            <Text
                              text={`Longitude: ${item.longitude || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails sx={{ px: 1 }}>
                            <Box
                              sx={{
                                backgroundColor: '#7072e5',
                                width: 10,
                                height: 10,
                                borderRadius: '100%',
                                mb: 0.3,
                              }}
                            ></Box>
                            <Text text="CSAT :" fs={10} variant="caption" />
                            <Text text={item.csat + ''} />
                          </StyledBoxDetails>
                        </StyledAccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Fragment>
          ))}
        </Grid>
      </div>

      {loadMoreText && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            <Text
              text={loadMoreText}
              component="p"
              fs={12}
              style={{
                color: hasNextPage ? 'var(--clr-lightBlue)' : '',
                textDecorationLine: hasNextPage ? 'underline' : '',
              }}
            />
          </Button>
        </Box>
      )}

      {data?.pages[0]?.results?.length === 0 && (
        <div
          style={{
            paddingBlock: 21,
            textAlign: 'center',
          }}
        >
          <Text text={'No Data to be shown'} component="p" fs={18} />
        </div>
      )}
    </div>
  );
}

export default VisitNPS;
