import { forwardRef, Fragment, useState } from 'react';
import Text from 'components/text';
import { Select as SelectAntD } from 'antd';
import { OptionType } from 'components/filters/Filters';

interface DSelectProps {
  data: OptionType[];
  onSelectChange: (value: string | number) => void;
  onChange?: (value: string | number) => void;
  placeholder?: string;
  label?: string;
  have_error?: string;
  disabled?: boolean;
}

const DSelect = forwardRef<any, DSelectProps>(function DSelect(
  {
    onChange = () => {},
    placeholder,
    label,
    data,
    onSelectChange = () => {},
    have_error,
    disabled,
  },
  ref
) {
  const [selectedValue, setSelectedValue] = useState<null | string | number>(
    null
  );

  const handleChange = (value: string | number) => {
    onSelectChange(value);
    onChange(value);
    if (!value) setSelectedValue(null);
    else setSelectedValue(value);
  };

  return (
    <Fragment>
      {label && <Text text={label} fs={10} />}
      <SelectAntD
        dropdownStyle={{
          minWidth: 250,
        }}
        onChange={handleChange}
        placeholder={placeholder}
        options={data}
        ref={ref}
        disabled={disabled}
        value={selectedValue}
      />
      {have_error && (
        <div
          style={{
            fontSize: 12,
            color: 'red',
            marginTop: 2,
          }}
        >
          {have_error}
        </div>
      )}
    </Fragment>
  );
});

export default DSelect;
