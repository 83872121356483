import toast from 'react-hot-toast';
export type ToastError = {
  detail?: string;
  message?: string;
  details?: string[];
};
export const showToastError = (err: string | ToastError) => {
  if (!err) return;
  if (typeof err === 'string') {
    toast.error(err, { id: err });
  } else if (
    err?.detail === 'Authentication credentials were not provided.' ||
    err?.detail === 'Given token not valid for any token type'
  ) {
    toast.error('Your session ended.', { id: 'session ended' });
  } else if (err?.detail) {
    toast.error(err?.detail, { id: stringify(err?.detail) });
  } else if (err?.details) {
    toast.error(err?.details?.[0], { id: stringify(err?.details?.[0]) });
  } else if (err?.message) {
    toast.error(err?.message, { id: stringify(err?.message) });
  } else {
    toast.error('Something went wrong.', { id: 'something went wrong' });
  }
};

const stringify = (msg: string | object) =>
  typeof msg === 'string' ? msg : JSON.stringify(msg);
