import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { showToastError } from 'utils/showToastError';

export interface IBusinessOwnersResponse {
  count: number;
  next: string;
  previous: string;
  results: IBusinessOwnerDetailsResponse[];
}

export interface IBusinessOwnerDetailsResponse {
  id: number;
  national_id: string;
  signatory_name: string;
  signatory_name_english: string;
  signatory_position: string;
  email_address: string;
  address: string;
  is_primary: boolean;
  status: number;
  uploaded_by: string;
  uploaded_at: string;
  reviewed_by: string;
  reviewed_at: string;
  created_at: string;
  updated_at: string;
  signatory_national_id: string;
  expiry_at: string;
  national_id_front: string;
  national_id_back: string;
  passport_id_file: string;
  passport: string;
  phone_number: string;
  rejection_reason: number[];
}

interface IUseBusinessOwners {
  merchantId?: string;
  filters?: string;
}

interface IUseRejectBusinessOwner {
  merchantId?: string;
  ownerId?: string;
}

interface IUseApproveBusinessOwner extends IUseRejectBusinessOwner {}
interface IRejectBusinessOwnerRequest {
  rejection_reason: string | number[];
  status: number;
}

export function useBusinessOwners({ merchantId, filters }: IUseBusinessOwners) {
  return useQuery({
    queryKey: underwritingKeys.businessOwners({ merchantId, filters }),
    queryFn: () =>
      Services.get<IBusinessOwnersResponse>(
        `merchants/${merchantId}/business_owners/?${filters || ''}`,
        true
      ),
  });
}

export function useBusinessOwnerDetails({
  ownerId,
  merchantId,
}: {
  ownerId?: string;
  merchantId?: string;
}) {
  return useQuery({
    queryKey: underwritingKeys.businessOwnerDetails({ ownerId, merchantId }),
    queryFn: () =>
      Services.get<IBusinessOwnerDetailsResponse>(
        `merchants/${merchantId}/business_owners/${ownerId}/`,
        true
      ),
  });
}

export function useRejectBusinessOwner({
  merchantId,
  ownerId,
}: IUseRejectBusinessOwner) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IRejectBusinessOwnerRequest>({
    mutationFn: ({ rejection_reason, status }) =>
      Services.patch<IRejectBusinessOwnerRequest, unknown>(
        { rejection_reason, status },
        `merchants/${merchantId}/business_owners/${ownerId}/`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.businessOwner(merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}

export function useApproveBusinessOwner({
  merchantId,
  ownerId,
}: IUseApproveBusinessOwner) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () =>
      Services.patch<{ status: number }, unknown>(
        { status: 2 },
        `merchants/${merchantId}/business_owners/${ownerId}/`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.businessOwner(merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });
}
