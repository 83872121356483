import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';
type LocationType = OptionType[];

export function useCities(gov: string | string[]) {
  return useQuery({
    queryKey: merchantKeys.cities(gov),
    queryFn: () =>
      Services.get<LocationType>(
        `merchants/cities/?governorate_id=${gov}`,
        true
      ),
  });
}
export function useGovernorates() {
  return useQuery({
    queryKey: merchantKeys.governorates(),
    queryFn: () => Services.get<LocationType>(`merchants/governorates/`, true),
  });
}

export function useNeighborhoods(city: string) {
  return useQuery({
    queryKey: merchantKeys.neighborhoods(city),
    queryFn: () =>
      Services.get<LocationType>(
        `merchants/neighborhoods/?city_id=${city}`,
        true
      ),
  });
}
