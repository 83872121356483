import { Button, Grid } from '@mui/material';
import FileUpload from '../FileUpload';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Text from 'components/text';

interface DocumentRowProps {
  title: string;
  onFileUpdate: (file?: File) => void;
  handleDownload: () => void;
  handleView: () => void;
  disabledUpload: boolean;
  disabledEditDownload: boolean;
  isUpdating: boolean;
}

const DocumentRow = ({
  title,
  onFileUpdate,
  handleDownload,
  disabledUpload,
  disabledEditDownload,
  isUpdating,
  handleView,
}: DocumentRowProps) => {
  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} sm={4}>
        <Text text={title} fs={10} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FileUpload
          onFileUpdate={onFileUpdate}
          uploadBtnText="Upload"
          disabled={isUpdating || disabledUpload}
        />
      </Grid>
      <Grid item xs={12} sm={2} className={styles.center}>
        <Button
          onClick={handleDownload}
          size="small"
          disabled={disabledEditDownload}
        >
          <Text text="Download" fs={10} />
        </Button>
      </Grid>
      <Grid item xs={12} sm={2} className={styles.center}>
        <Button
          onClick={() => handleView()}
          size="small"
          disabled={disabledEditDownload}
        >
          <Text text="View/Edit" fs={10} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DocumentRow;
