const LegalDocStatus = {
  0: "Pending Review",
  1: "Updates Required",
  2: "Approved",
  3: "Rejected",
  4: "Other",
  5: "Initial Approved",
  6: "Paper Not Provided",
};

export default LegalDocStatus;
