import React, { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Text from 'components/text';
import MerchantDocs from './merchantDocs';
import BusinessOwnersInfo from './businessOwnersInfo';

const DocumentsKey = 'Documents';

interface IDocumentsProps {
  expanded: string | false;
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
  selectedContent: { key: string; id?: string };
}

const Documents = ({
  expanded,
  onExpanded,
  onSelectedContent,
  selectedContent,
}: IDocumentsProps) => {
  const [subExpanded, setSubExpanded] = useState<string | false>(false);
  const isExpanded = expanded === DocumentsKey;

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setSubExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onExpanded(DocumentsKey)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`Documents-content`}
        id={`Documents-header`}
        sx={{
          py: 1,
          px: 3,
          backgroundColor: isExpanded ? '#fff' : '',
        }}
      >
        <Text text="Documents" />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <MerchantDocs
          expanded={subExpanded}
          onExpanded={handleAccordionChange}
          onSelectedContent={onSelectedContent}
          selectedContent={selectedContent}
        />
        <BusinessOwnersInfo
          expanded={subExpanded}
          onExpanded={handleAccordionChange}
          onSelectedContent={onSelectedContent}
          selectedContent={selectedContent}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Documents;
