import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import { styled } from '@mui/system';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Filters from 'components/filters/Filters';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import { useTranslation } from 'react-i18next';
import { useSMSReport } from './useTimeline';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import Text from 'components/text';

const StyledGrid = styled(Grid)({
  '& .MuiGrid-item': {
    display: 'flex',
    alignItems: 'flex-center',
    gap: 6,
    color: 'var(--clr-gray)',
    marginBottom: 16,
  },
});

const StyledBoxIcon = styled(Box)({
  backgroundColor: '#4eadff47',
  borderRadius: '50%',
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  justifyContent: 'flex-start',
  gap: 8,
  backgroundColor: '#fff',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  color: 'var(--clr-lightBlue)',
  marginBottom: -20,
  padding: 0,
  '& .MuiAccordionSummary-content': {
    flexGrow: 0,
  },
  '&:active': {
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.8rem',
    color: 'var(--clr-lightBlue)',
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: '#f6f7fa',
  borderRadius: 1,
  marginTop: 20,
  borderTop: 'none',
  color: 'var(--clr-gray)',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const StyledBoxDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

function SMSReport() {
  const { merchantId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [selectedKey, setSelectedKey] = useState('id');
  const [date, onDateChange] = useState({});
  const { t } = useTranslation();

  const filters = {
    merchantId,
    searchText,
    selectedKey,
    date,
  };

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching } =
    useSMSReport(filters);

  const FilteredBySearch = (value: string | number) => {
    setSearchText(value.toString());
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    let dates = value ?? {};
    onDateChange(dates);
  };

  const skeletons = (
    <>
      {[1, 2, 3].map((val) => (
        <Grid
          key={val}
          item
          xs={12}
          className={styles.details}
          style={{ width: '100%' }}
          sx={{ mb: 4 }}
        >
          <Skeleton variant="text" sx={{ height: 60, mt: -1.7 }} />
          <Skeleton variant="text" sx={{ height: 30, mx: 2 }} width={170} />
          <Skeleton variant="text" sx={{ height: 30, mx: 2 }} width={200} />
          <Skeleton
            variant="text"
            sx={{ height: 70, width: '97%', mx: 'auto' }}
          />
        </Grid>
      ))}
    </>
  );

  let loadMoreText = '';
  if (isFetchingNextPage) loadMoreText = 'Loading more...';
  else if (hasNextPage) loadMoreText = 'Load More';

  return (
    <div id={styles.timeline} style={{ width: '100%' }}>
      <Filters
        inputLabel="Search SMS Report"
        inputPlaceholder="Type to search list"
        onSearchChange={FilteredBySearch}
        searchSelectors={[
          { label: t('SMS ID'), value: 'id' },
          { label: t('Phone'), value: 'caller_phone_number' },
        ]}
        onSelectedKey={setSelectedKey}
        onDateChange={FilteredByDate}
        showDate
      />

      <div id={styles.overview}>
        <Grid container>
          {!data && isFetching && skeletons}

          {data?.pages?.map((page) => (
            <Fragment key={page.next}>
              {page?.results?.map((item) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  className={styles.details}
                  style={{ width: '100%' }}
                  sx={{ mb: 4 }}
                >
                  <Box component="header" sx={{ fontWeight: 'light' }}>
                    <Text
                      text="SMS Report"
                      fs={11}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                    <div style={{ textAlign: 'end' }}>
                      <Text
                        text={
                          item.date_time
                            ? format(
                                parseISO(item.date_time),
                                'dd/MM/yyyy, hh:mm a'
                              )
                            : '-'
                        }
                        fs={11}
                        sx={{ color: 'var(--clr-gray)' }}
                      />
                    </div>
                  </Box>
                  <Grid container sx={{ p: 3 }}>
                    <Grid item xs={12}>
                      <StyledGrid container flexDirection="column">
                        <Grid item>
                          <PersonOutlineOutlinedIcon />
                          <Text
                            text={item.agent_name}
                            fs={11}
                            sx={{ mt: 0.3 }}
                          />
                        </Grid>
                        <Grid item>
                          <LocalPhoneOutlinedIcon />
                          <Text
                            text={item.caller_phone_number}
                            fs={11}
                            sx={{ mt: 0.3 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            backgroundColor: '#e5f3ff',
                            borderRadius: 1,
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <StyledBoxIcon>
                            <EmailIcon sx={{ color: 'var(--clr-lightBlue)' }} />
                          </StyledBoxIcon>

                          <Text text={item.message_content} fs={11} />
                        </Grid>
                      </StyledGrid>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{ border: 'none' }}
                      >
                        <StyledAccordionSummary
                          aria-controls={`Documents-content`}
                          id={`Documents-header`}
                        >
                          <Text text="More details" fs={11} sx={{ mb: -0.3 }} />
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          <StyledBoxDetails>
                            <NumbersOutlinedIcon />
                            <Text
                              text={`SMS ID: ${item.id || '-'}`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                          <StyledBoxDetails>
                            <LinkIcon />
                            <Text
                              text={`Call Recording Link: ${
                                item.call_recording_link || '-'
                              }`}
                              fs={10}
                              variant="caption"
                            />
                          </StyledBoxDetails>
                        </StyledAccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Fragment>
          ))}
        </Grid>
      </div>

      {loadMoreText && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            <Text
              text={loadMoreText}
              component="p"
              fs={12}
              style={{
                color: hasNextPage ? 'var(--clr-lightBlue)' : '',
                textDecorationLine: hasNextPage ? 'underline' : '',
              }}
            />
          </Button>
        </Box>
      )}

      {data?.pages[0]?.results?.length === 0 && (
        <div
          style={{
            paddingBlock: 21,
            textAlign: 'center',
          }}
        >
          <Text text={'No Data to be shown'} component="p" fs={18} />
        </div>
      )}
    </div>
  );
}

export default SMSReport;
