import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';

type SalesOwnerType = OptionType;

export function useSalesOwner() {
  const { isLoading, data, error, isError, isFetching } = useQuery({
    queryKey: merchantKeys.salesOwner(),
    queryFn: () =>
      Services.get<SalesOwnerType[]>(`merchants/sales_owners/`, true),
  });

  return { isLoading, data, error, isError, isFetching };
}
