import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Text from 'components/text';
import Container from 'components/layouts/Container';
import styles from 'styles/main/merchants/index.module.scss';
import Tabs from 'components/tabs/Tabs';
import UnderwritingTabs from 'constants/UnderwritingTabs';

const RequestsLayout = () => {
  return (
    <div id={styles.merchants}>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <header>
            <Text text="Underwriting" />
          </header>
          <Tabs data={UnderwritingTabs} isNested sx={{ m: 0 }} />
        </Box>
        <Outlet />
      </Container>
    </div>
  );
};

export default RequestsLayout;
