import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import Button from 'components/button/Button';
import Filters from 'components/filters/Filters';
import EmptyTable from 'components/Table/EmptyTable';
import Table, { StyledTableCell, StyledTableRow } from 'components/Table/Table';
import Text from 'components/text';
import { useBills } from './useBills';
import { useTranslation } from 'react-i18next';

function BillsBalance() {
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<string | number>('');
  const [searchText, setSearchText] = useState<string | number>('');
  const [sort, setSort] = useState<string | number>('');
  const [date, onDateChange] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const [isSelectedDates, setIsSelectedDates] = useState(false);

  const filters = {
    merchantId: merchantId!,
    page,
    searchText,
    status,
    date,
    sort,
    selectedKey,
  };
  const { data, isLoading } = useBills(filters);

  const FilteredBySearch = (value: string | number) => {
    setPage(1);
    setSearchText(value);
  };

  const FilteredByStatus = (value: string | number) => {
    setPage(1);
    setStatus(value);
  };

  const FilteredByDate = (value: null | { from?: string; to?: string }) => {
    setPage(1);
    let dates = value ?? {};
    onDateChange(dates);
    setIsSelectedDates(Boolean(value) ?? false);
  };

  const FilteredBySort = (value: string | number) => {
    setPage(1);
    setSort(value);
  };

  return (
    <div>
      <Filters
        showDate
        inputLabel="Search for bills balance"
        inputPlaceholder="Search by Transfer ID, Transaction ID or amount"
        selectPlaceholder="Filter by status"
        onSearchChange={FilteredBySearch}
        onStatusChange={FilteredByStatus}
        onDateChange={FilteredByDate}
        searchSelectors={[
          { label: 'TRX ID', value: '' },
          // { name: "Transfer", value: "transfer_id" },
          // { name: "Order", value: "order_id" },
        ]}
        statusData={[
          { label: t('All'), value: '' },
          { label: t('Success'), value: 'success=True&' },
          { label: t('Declined'), value: 'success=False&' },
          { label: t('Pending'), value: 'pending=True&' },
        ]}
        onSelectedKey={setSelectedKey}
        onSortChange={FilteredBySort}
        isNeedSorting
      />

      <div style={{ marginBottom: 30, textAlign: 'end' }}>
        <Button text="Export bills balance details" />
      </div>

      <Table
        page={page}
        changePage={setPage}
        count={data?.count!}
        isLoading={isLoading}
        headers={[
          'TRNX ID',
          'MERCHANT ID',
          'INTEGRATION ID',
          'SOURCE',
          'AMOUNT',
          'MERCH. COMMISSION CENTS',
          'STATUS',
        ]}
      >
        {data?.results?.map((item) => (
          <StyledTableRow key={item.id}>
            <StyledTableCell component="th" scope="row">
              <Link to={`${item.id}`}>
                <Text text={`#${item.id}`} fs={10} />
                <Text
                  text={format(
                    parseISO(item.created_at),
                    'yyyy/MM/dd - hh:mm a'
                  )}
                  fs={10}
                />
              </Link>
            </StyledTableCell>
            <StyledTableCell>
              <Text text={merchantId!} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={`#${item?.integration_id}`} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text text={item?.service_name} fs={10} />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={'CENTS'}
                variables={{
                  0: item?.amount_cents,
                }}
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Text
                text={'CENTS'}
                variables={{
                  0: item?.accept_fees_cents,
                }}
                fs={10}
              />
            </StyledTableCell>
            <StyledTableCell>
              {!item?.pending ? (
                <div
                  className={
                    item?.success ? 'status--active' : 'status--inactive'
                  }
                >
                  <Text
                    text={item?.success ? 'SUCCESS' : 'NOT SUCCESS'}
                    fs={10}
                  />
                </div>
              ) : (
                <div className={'status--pending'}>
                  <Text text={'PENDING'} fs={8} />
                </div>
              )}
            </StyledTableCell>
          </StyledTableRow>
        ))}

        {!data?.results?.length && <EmptyTable />}
      </Table>
    </div>
  );
}

export default BillsBalance;
