import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';

import Tabs from 'components/tabs/Tabs';
import TimeLineTabs from 'constants/TimeLineTabs';

export default function TimeLine() {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Tabs
          data={TimeLineTabs}
          orientation="vertical"
          sx={{
            borderLeft: 1,
            borderBottom: 0,
            borderColor: 'divider',
            '.MuiTabs-indicator': {
              left: 0,
            },
            '& .MuiButtonBase-root': {
              display: 'flex',
              alignItems: 'flex-start',
              marginInlineStart: 2,
            },
          }}
          isNested
        />
      </Grid>
      <Grid item xs={10}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
