import { useMutation, useQueryClient } from '@tanstack/react-query';
import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import toast from 'react-hot-toast';

export function useDocumentStamp(merchant_id: string) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, string>({
    mutationFn: () =>
      Services.set<{ merchant_id: string }, unknown>(
        { merchant_id },
        `merchants/${merchant_id}/merchant_documents/stamp_docs/`,
        true
      ),
    onSuccess: () => {
      toast.success('Available Documents Stamped Successfully');
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.documentStamp(merchant_id),
      });
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.integrationRequestDetail(),
      });
    },
  });
}
