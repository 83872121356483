import React from 'react';
import { Box } from '@mui/material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Text from 'components/text';

const MerchantInformationKey = 'MerchantInformation';
const MerchantDetailsKey = 'MerchantDetails';
const BankDetailsKey = 'BankDetails';

interface IMerchantInformationProps {
  expanded: string | false;
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
  selectedContent: { key: string; id?: string };
}

const MerchantInformation = ({
  expanded,
  onExpanded,
  onSelectedContent,
  selectedContent,
}: IMerchantInformationProps) => {
  const isExpanded = expanded === MerchantInformationKey;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onExpanded(MerchantInformationKey)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`MerchantInformation-content`}
        id={`MerchantInformation-header`}
        sx={{
          py: 1,
          px: 3,
          backgroundColor: isExpanded ? '#fff' : '',
        }}
      >
        <Text text="Merchant Information" />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box
          onClick={() => onSelectedContent({ key: MerchantDetailsKey })}
          sx={{
            p: 2,
            px: 4,
            '&:hover': {
              backgroundColor: '#FAFAFA',
              cursor: 'pointer',
            },
            backgroundColor:
              selectedContent.key === MerchantDetailsKey ? '#F4F9FD' : '',
          }}
        >
          <Text text="Merchant details" fs={11} />
        </Box>
        <Box
          onClick={() => onSelectedContent({ key: BankDetailsKey })}
          sx={{
            p: 2,
            px: 4,
            '&:hover': {
              backgroundColor: '#FAFAFA',
              cursor: 'pointer',
            },
            backgroundColor:
              selectedContent.key === BankDetailsKey ? '#F4F9FD' : '',
          }}
        >
          <Text text="Bank account details" fs={11} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MerchantInformation;
