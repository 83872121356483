const MerchantTabs = [
  {
    name: "Profile and info",
    link: "",
  },
  {
    name: "Timeline",
    link: "timeline",
  },
  {
    name: "Services",
    link: "services",
  },
  {
    name: "Transactions",
    link: "transactions",
  },
  {
    name: "Transfers",
    link: "transfers",
  },
  {
    name: "SMS communication",
    link: "sms-communication",
  },
  {
    name: "Visits",
    link: "visits",
  },
  {
    name: "Bills balance",
    link: "bills-balance",
  },
];

export default MerchantTabs;