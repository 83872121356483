import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Input from 'components/input/Input';
import Select from 'components/input/Select';
import { removeEmptyFields } from 'utils/removeEmptyFields';
import '../MerchantDrawer.scss';

import { useUpdateMerchantDetails } from './useUpdateMerchantDetails';
import { Button as MUIButton, List, ListItem, Grid } from '@mui/material';
import Text from 'components/text';
import Button from 'components/button/Button';
import { MerchantDetailsType } from 'pages/main/merchants/marchent/useMerchant';
import { useContactPersonAuthorities } from './useContactPersonAuthorities';
import { useCities, useNeighborhoods, useGovernorates } from './useLocation';
import { OptionType } from 'components/filters/Filters';
import { useSalesAgentMCC } from './useSalesAgentMCC';
import { useMerchantCategoryCode } from './useMerchantCategoryCode';
interface MerchantDetailsFormProps {
  merchantDetails: MerchantDetailsType;
}
type FormValuesType = {
  company_emails: string[];
  the_governorate?: string[];
  city?: string;
  company_name?: string;
  neighborhood?: string[];
  company_registration_address?: string;
  name?: string;
  contact_person_authorities?: OptionType[];
  email?: string;
  merchant_category_code?: string;
  sales_agent_mcc?: string;
  pf_merchant_name?: string;
  pos_merchant_name?: string;
};
const defaultValues: FormValuesType = {
  company_emails: [],
  the_governorate: undefined,
  city: undefined,
  company_name: undefined,
  neighborhood: undefined,
  company_registration_address: undefined,
  name: undefined,
  contact_person_authorities: undefined,
  email: undefined,
  merchant_category_code: undefined,
  sales_agent_mcc: undefined,
  pf_merchant_name: undefined,
  pos_merchant_name: undefined,
};
function MerchantDetailsForm({ merchantDetails }: MerchantDetailsFormProps) {
  const { mutate: updateMerchantDetails } = useUpdateMerchantDetails();
  const { data: contact_person_authorities = [] } =
    useContactPersonAuthorities();
  const { data: governorates = [] } = useGovernorates();
  const { data: merchantCategoryCode } = useMerchantCategoryCode();
  const { data: salesAgentMCC } = useSalesAgentMCC();

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_emails' as never,
  });

  const governorate = watch('the_governorate');
  const city = watch('city');

  const { data: cities } = useCities(governorate!);
  const { data: neighborhoods } = useNeighborhoods(city!);

  useEffect(() => {
    reset({
      name: merchantDetails?.full_name ?? '',
      company_name: merchantDetails?.company_name ?? '',
      company_registration_address:
        merchantDetails?.legal_documents?.company_registration_address ?? '',
      email: merchantDetails?.user?.email ?? '',
      company_emails: merchantDetails?.company_emails
        ? Object.values(merchantDetails?.company_emails)
        : [],
      merchant_category_code:
        merchantDetails?.legal_documents?.merchant_category_code || '',
      sales_agent_mcc: merchantDetails?.legal_documents?.sales_agent_mcc || '',
      pf_merchant_name:
        merchantDetails?.legal_documents?.pf_merchant_name || '',
      pos_merchant_name:
        merchantDetails?.legal_documents?.pos_merchant_name || '',
    });
  }, [merchantDetails, reset]);

  const handleForm = (values: FormValuesType) => {
    const {
      company_name,
      the_governorate,
      city,
      neighborhood,
      company_registration_address,
      company_emails,
      merchant_category_code,
      sales_agent_mcc,
      pf_merchant_name,
      pos_merchant_name,
    } = values;

    let data: MerchantDetailsType = {
      company_name,
      ...(company_emails?.length && { company_emails }),
      legal_documents: {
        neighborhood,
        the_governorate,
        city,
        company_registration_address,
        merchant_category_code,
        sales_agent_mcc,
        pf_merchant_name,
        pos_merchant_name,
      },
    };
    data = removeEmptyFields(data);
    updateMerchantDetails({ data, merchantId: merchantDetails?.id! });
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ mb: 2, px: '10px' }}
      >
        <Grid item xs={12} lg={6}>
          <Input
            {...register('name', {
              required: 'Name is required',
            })}
            disabled
            have_error={errors.name?.message}
            label="Full name"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            {...register('contact_person_authorities')}
            value={getValues('contact_person_authorities')}
            label="Authority of merchant"
            placeholder="Authority of merchant"
            disabled
            data={contact_person_authorities}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Input
            {...register('email', {
              required: 'Email is required',
            })}
            disabled
            have_error={errors.email?.message}
            label="Email address"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Input
            {...register('company_name')}
            have_error={errors.company_name?.message}
            label="Company name"
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Input
            {...register('company_registration_address')}
            label="Company address"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            {...register('the_governorate')}
            label="Select governorate"
            data={governorates}
            placeholder="Governorate"
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Select
            {...register('city')}
            label="Select city"
            data={cities || []}
            placeholder="City"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            {...register('neighborhood')}
            label="Select neighborhood"
            data={neighborhoods || []}
            placeholder="Neighborhood"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            {...register('merchant_category_code')}
            label="Select Merchant Category Code"
            data={merchantCategoryCode?.available_services || []}
            placeholder="Merchant Category Code"
            sx={{ width: '100%' }}
            defaultValue={
              merchantDetails?.legal_documents?.merchant_category_code
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Select
            {...register('sales_agent_mcc')}
            label="Select Sales Agent MCC"
            data={salesAgentMCC?.available_services || []}
            placeholder="Sales Agent MCC"
            sx={{ width: '100%' }}
            defaultValue={merchantDetails?.legal_documents?.sales_agent_mcc}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Input
            {...register('pf_merchant_name', {
              required: merchantDetails?.legal_documents?.pf_merchant_name
                ?.length
                ? 'PF Merchant Name is required'
                : false,
              maxLength: {
                value: 9,
                message: "PF Merchant Name can't be more than 9 characters.",
              },
            })}
            have_error={errors.pf_merchant_name?.message}
            label="PF Merchant Name"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Input
            {...register('pos_merchant_name', {
              required: merchantDetails?.legal_documents?.pos_merchant_name
                ?.length
                ? 'POS Merchant Name is required'
                : false,
              maxLength: {
                value: 150,
                message: "POS Merchant Name can't be more than 150 characters.",
              },
            })}
            have_error={errors.pos_merchant_name?.message}
            label="POS Merchant Name"
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', mb: 0, mt: 4 }}
      >
        <Text
          text="Company Emails"
          fs={10}
          sx={{ marginInlineStart: '10px' }}
        />
        <MUIButton
          onClick={() => append('')}
          size="small"
          sx={{ ml: 1, mt: -0.3 }}
        >
          <Text text="Add Email" fs={10} />
        </MUIButton>
      </Grid>

      <List dense={true} sx={{ pt: 0 }}>
        {fields.map((item, index) => (
          <ListItem key={index} sx={{ px: 1.5 }}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sm={12} md={10}>
                <Input
                  {...register(`company_emails.${index}`, {
                    required: 'Company Email is required',
                    pattern: {
                      value: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/g,
                      message: 'Invalid Company Email',
                    },
                  })}
                  sx={{ minWidth: 'unset', width: '95%' }}
                  have_error={errors.company_emails?.[index]?.message}
                />
              </Grid>
              <Grid item sm={12} md={2}>
                <MUIButton onClick={() => remove(index)} color="error">
                  <Text text="Delete" fs={10} />
                </MUIButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>

      <div className="row submitBtn">
        <Button text="Save changes" />
      </div>
    </form>
  );
}

export default MerchantDetailsForm;
