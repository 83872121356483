import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { underwritingKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { fetchImageAsBase64 } from 'utils/fetchImageAsBase64';
import { privateURL } from 'apis/AxiosConfig';
import { showToastError } from 'utils/showToastError';

export interface IDocumentsResponse {
  count: number;
  next: null;
  previous: null;
  results: IDocumentDetailsResponse[];
}

export interface IDocumentDetailsResponse {
  id: number;
  document_label: string;
  status: number;
  created_at: string;
  updated_at: string;
  reviewed_by: string;
  details: Detail[];
  uploaded_by: string;
  expiry_at: string;
  document_file: string;
  rejection_reasons: Array<string[]>;
  comment: string;
}

export interface Detail {
  id: number;
  key: string;
  label: string;
  detail_type: string;
  value: string;
  created_at: string;
  updated_at: string;
}

interface IUseDocumentFile {
  requestId?: string;
  documentId?: string;
  filePath?: string;
}

export interface IUpdateDocumentResponse {
  details: Detail[];
}

interface IApproveDocumentRequest {
  requestId: string;
  documentId: string;
}

interface IRejectDocumentRequest {
  requestId: string;
  documentId: string;
  status: string | number[];
  comment?: string;
}

export interface IRejectionReasons {
  en: { [key: string]: string };
  ar: { [key: string]: string };
}

export function useDocuments(merchantId?: string) {
  return useQuery({
    queryKey: underwritingKeys.documents(merchantId),
    queryFn: () =>
      Services.get<IDocumentsResponse>(
        `merchants/${merchantId}/merchant_documents/`,
        true
      ),
  });
}

export function useDocumentDetails({
  merchantId,
  documentId,
}: {
  merchantId?: string;
  documentId?: string;
}) {
  return useQuery({
    queryKey: underwritingKeys.documentDetails({ merchantId, documentId }),
    queryFn: () =>
      Services.get<IDocumentDetailsResponse>(
        `merchants/${merchantId}/merchant_documents/${documentId}/`,
        true
      ),
  });
}

export function useDocumentFile({
  requestId,
  documentId,
  filePath,
}: IUseDocumentFile) {
  return useQuery<string, Error>({
    queryFn: () => fetchImageAsBase64(`${privateURL}/document${filePath}`),
    enabled: !!filePath,
    queryKey: underwritingKeys.documentFile({
      requestId,
      documentId,
      filePath,
    }),
  });
}

export function useUpdateDocument({
  merchantId,
  documentId,
}: {
  merchantId?: string;
  documentId?: string;
}) {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, Pick<Detail, 'key' | 'value' | 'id'>[]>({
    mutationFn: (details) =>
      Promise.allSettled(
        details.map((detail) =>
          Services.patch<{ value: string }, unknown>(
            { value: detail.value },
            `merchants/${merchantId}/merchant_document_extra_details/${detail.id}/`
          )
        )
      ),
    onSuccess: () => {
      toast.success('Update successfully');
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: underwritingKeys.documentDetails({
            merchantId,
            documentId,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: underwritingKeys.businessOwner(merchantId),
        }),
        queryClient.invalidateQueries({
          queryKey: underwritingKeys.documents(merchantId),
        }),
      ]);
    },
    onError: (err) => {
      showToastError(err.message);
    },
  });
}

export function useApproveDocument(merchantId?: string) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IApproveDocumentRequest>({
    mutationFn: ({ requestId, documentId }) =>
      Services.set<unknown, unknown>(
        {},
        `integration_requests/${requestId}/document/${documentId}/approve/`,
        true
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.documents(merchantId),
      });
    },
  });
}

export function useRejectDocument(merchantId?: string) {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, IRejectDocumentRequest>({
    mutationFn: ({ requestId, documentId, status, comment }) =>
      Services.set<Pick<IRejectDocumentRequest, 'status' | 'comment'>, unknown>(
        { status, comment },
        `integration_requests/${requestId}/document/${documentId}/reject/`,
        true
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: underwritingKeys.documents(merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data || err?.message
        : { message: err.message };

      showToastError(message);
    },
  });
}

export function useRejectionReasons() {
  return useQuery({
    queryKey: underwritingKeys.rejectionReasons(),
    queryFn: () =>
      Services.get<IRejectionReasons>(
        `available_filters/rejection-reasons/`,
        true
      ),
  });
}
