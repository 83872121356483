import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type GatewayTypesResponse = { label: string; value: string }[];

export function useTransactionsGatewayTypes(merchantId: string) {
  return useQuery({
    queryKey: merchantKeys.transactionsGatewayTypes(merchantId),
    queryFn: () =>
      Services.get<GatewayTypesResponse>(
        `merchants/${merchantId}/transactions/gateway_types/`,
        true
      ),
  });
}
