import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
import { OptionType } from 'components/filters/Filters';

export type useSalesAgentMCCType = { available_services: OptionType[] };

export function useSalesAgentMCC() {
  return useQuery({
    queryKey: merchantKeys.salesAgentMCC(),
    queryFn: () =>
      Services.get<useSalesAgentMCCType>(
        `merchants/sales_agent_mcc_list/`,
        true
      ),
  });
}
