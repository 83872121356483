import { format } from 'date-fns';
import { UNDERWRITING_FILTERS_VALUES } from '../formFilters';

export const filterTransformer = (
  values: UNDERWRITING_FILTERS_VALUES,
  isPendingRequests: boolean
): string => {
  const status = handelStatus(values?.status as string, isPendingRequests);

  const date = handelDate(values?.date);

  const merchant_id = values?.merchant_id
    ? `&merchant_id=${encodeURIComponent(values?.merchant_id)}`
    : '';

  const company_name = values?.company_name
    ? `&company_name=${encodeURIComponent(values?.company_name)}`
    : '';

  const channel = values?.channel ?? '';

  const service = values?.service ?? '';

  const governorate = values?.governorate ?? '';

  const city = values?.city ?? '';

  const neighborhood = values?.neighborhood ?? '';

  const newFilters =
    status +
    service +
    date +
    merchant_id +
    company_name +
    channel +
    governorate +
    city +
    neighborhood;

  return newFilters;
};

const handelDate = (date: any): string => {
  if (date?.length) {
    return `&created_at_after=${format(
      date?.[0],
      'yyyy-MM-dd'
    )}&created_at_before=${format(date?.[1], 'yyyy-MM-dd')}`;
  }
  return '';
};

const handelStatus = (status: string, isPendingRequests: boolean): string => {
  if (status?.length > 0) {
    return status;
  }
  return isPendingRequests
    ? '&status=UPDATED&status=PENDING'
    : '&status=REJECTED&status=APPROVED';
};
