import { MouseEvent, forwardRef } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Text from 'components/text';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    padding: '6px 8px',
  },
}));

const CustomMuiButton = styled(MuiButton)<MuiButtonProps>(
  ({ theme, disabled, style }) => ({
    minWidth: 200,
    backgroundColor: 'var(--clr-lightBlue)',
    border: 0,
    borderRadius: 4,
    padding: 10,
    opacity: disabled ? 0.5 : 1,
    textTransform: 'capitalize',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'var(--clr-mainBlue)',
      filter: 'brightness(1.3)',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
    },
    ...style,
  })
) as typeof MuiButton;

interface ButtonProps extends MuiButtonProps {
  title?: string;
  text?: string;
  extra?: string | JSX.Element | boolean;
  handleClick?: (e: MouseEvent<HTMLElement>) => void;
}

// span element is used as a wrapper to solve MUI tooltip issue with disabled attribute enabled

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ title = '', text, handleClick, ...props }, ref) => (
    <StyledTooltip title={title} placement="top" arrow>
      {props.disabled && title ? (
        <span>
          <CustomMuiButton
            ref={ref}
            type="submit"
            onClick={handleClick}
            {...props}
          >
            <div>
              {props.extra}
              <Text
                text={text ?? ''}
                fs={props.style?.fontSize ?? 10}
                style={{ color: props.style?.color ?? 'var(--clr-light)' }}
              />
            </div>
          </CustomMuiButton>
        </span>
      ) : (
        <CustomMuiButton
          ref={ref}
          type="submit"
          onClick={handleClick}
          {...props}
        >
          <div>
            {props.extra}
            <Text
              text={text ?? ''}
              fs={props.style?.fontSize ?? 10}
              style={{ color: props.style?.color ?? 'var(--clr-light)' }}
            />
          </div>
        </CustomMuiButton>
      )}
    </StyledTooltip>
  )
);

export default Button;
