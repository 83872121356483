import { Fragment, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  SelectChangeEvent,
  Skeleton,
  TextareaAutosize,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { format, parseISO } from 'date-fns';
import { Document, Page } from 'react-pdf';

import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Button from 'components/button/Button';
import {
  useRejectionReasons,
  useApproveDocument,
  useRejectDocument,
  useDocumentFile,
} from './useDocuments';
import { useParams } from 'react-router-dom';
import { useDocumentDetails } from './useDocuments';
import LegalDocStatus from 'constants/LegalDocStatus';
import ViewDocument from 'components/modals/viewDocument/ViewDocument';
import ExtraDetails from './extraDetails';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const statusClasses = {
  0: 'status--pending--yellow',
  2: 'status--active',
  3: 'status--inactive',
  default: 'status--pending',
};

const rejectReasonsSchemaValidation = z.object({
  reasons: z.array(z.number()).nonempty('Please, select at least one reason'),
  comment: z
    .string()
    .max(200, { message: 'Please, keep your comment within 200 characters' })
    .refine((value) => !/(<([^>]+)>)/gi.test(value), {
      message: 'Invalid characters',
    })
    .optional(),
});

export type RejectReasonsSchemaType = z.infer<
  typeof rejectReasonsSchemaValidation
>;

interface IMerchantDocsDetailsProps {
  id: string;
}

const MerchantDocsDetails = ({ id }: IMerchantDocsDetailsProps) => {
  const { requestId, merchantId } = useParams();
  const [showRejectionList, setShowRejectionList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEditExtraDetails, setIsEditExtraDetails] = useState<boolean>(false);
  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const { data, isFetching: isFetchingDocument } = useDocumentDetails({
    merchantId,
    documentId: id,
  });
  const { data: documentFile } = useDocumentFile({
    requestId,
    documentId: id,
    filePath: data?.document_file,
  });

  const { data: rejectionReasons, isFetching: isFetchingRejections } =
    useRejectionReasons();
  const { mutate: approveDocument, isLoading: isApprovingDocument } =
    useApproveDocument(merchantId);
  const { mutate: rejectDocument, isLoading: isRejectingDocument } =
    useRejectDocument(merchantId);
  const isGeneratedContract = data?.document_label === 'Generated Contract';

  const rejectionReasonsList = Object.entries(rejectionReasons?.en ?? {}).map(
    (item) => ({ name: item[1], value: +item[0] })
  );

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty, isValid, errors },
  } = useForm<RejectReasonsSchemaType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(rejectReasonsSchemaValidation),
    defaultValues: {
      reasons: [],
      comment: '',
    },
  });

  const handleApproveDocument = () => {
    if (!requestId || !id) return;
    approveDocument({ requestId, documentId: id });
  };

  const handleRejectDocument: SubmitHandler<RejectReasonsSchemaType> = (
    value
  ) => {
    if (!requestId || !id) return;

    rejectDocument(
      {
        requestId,
        documentId: id,
        status: value.reasons ?? [],
        ...(value.comment && { comment: value.comment }),
      },
      { onSuccess: () => handleCancelReject() }
    );
  };

  const handleChangeRejectionReasons = (
    event: SelectChangeEvent<typeof selectedReasons>
  ) => {
    const value = event.target.value as number[];
    setSelectedReasons(value);
  };

  const handleShowRejections = () => setShowRejectionList(true);

  const handleCancelReject = () => {
    reset();
    setSelectedReasons([]);
    setShowRejectionList(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  let detailsContent;

  if (data?.details?.length) {
    detailsContent = Array.from(
      { length: Math.ceil(data.details.length / 2) },
      (_, i) => [data.details[2 * i], data.details[2 * i + 1]]
    ).map((item, i) => (
      <section key={i}>
        <div>
          <Text text={item[0]?.label} fs={11} className={styles.key} />
          <Text
            text={
              item[0]?.detail_type === 'datetime'
                ? format(parseISO(item[0].value), 'MMM dd, yyyy, hh:mm a')
                : item[0]?.value ?? '-'
            }
            fs={11}
            className={styles.value}
            sx={{ textTransform: 'capitalize' }}
          />
        </div>
        {item[1] && (
          <div>
            <Text text={item[1]?.label} fs={11} className={styles.key} />
            <Text
              text={item[1]?.value ?? `-`}
              fs={11}
              className={styles.value}
            />
          </div>
        )}
      </section>
    ));
  }

  let actionsContent: JSX.Element;

  if (showRejectionList) {
    actionsContent = (
      <form onSubmit={handleSubmit(handleRejectDocument)}>
        <section>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Text
              text="Please select the reasons for rejection and confirm to proceed"
              fs={11}
            />

            <Controller
              name="reasons"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handleChangeRejectionReasons(e);
                  }}
                  multiple
                  value={selectedReasons}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  sx={{ width: 200, height: 40 }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>reasons</em>;
                    }

                    return rejectionReasonsList
                      .filter((reason) => selected.includes(reason.value))
                      .map((reason) => reason.name)
                      .join(', ');
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  error={Boolean(error)}
                >
                  <MenuItem disabled value="">
                    <em>reasons</em>
                  </MenuItem>
                  {rejectionReasonsList.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <Controller
              name="comment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder="Write comment - optional"
                  multiline
                  error={Boolean(error)}
                  helperText={error?.message || ''}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: { style: { minHeight: 50, width: 550 } },
                  }}
                />
              )}
            />
          </Box>
        </section>
        <section>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              text="Confirm rejection"
              style={{ backgroundColor: 'var(--clr-red)' }}
              disabled={
                isFetchingRejections ||
                !isDirty ||
                !isValid ||
                isRejectingDocument
              }
              title={
                !isDirty || !isValid
                  ? errors.reasons?.message ||
                    errors.comment?.message ||
                    'Please, select at least one reason'
                  : undefined
              }
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={handleCancelReject}
              text="Cancel"
              style={{
                backgroundColor: '#fff',
                color: 'var(--clr-red)',
                fontSize: 12,
              }}
            />
          </Box>
        </section>
      </form>
    );
  } else {
    actionsContent = (
      <section>
        {!(data?.status === 2) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleApproveDocument}
              text="Approve document"
              style={{ backgroundColor: 'var(--clr-green)' }}
              disabled={isApprovingDocument}
            />
          </Box>
        )}
        {!(data?.status === 3) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleShowRejections}
              text="Reject document"
              style={{ backgroundColor: 'var(--clr-red)' }}
            />
          </Box>
        )}
      </section>
    );
  }

  let statusHeaderContent;

  if (data?.status === 2) {
    statusHeaderContent = (
      <Box
        sx={{
          backgroundColor: 'var(--clr-lightGreen)',
          color: 'var(--clr-green)',
          display: 'flex',
          p: 2,
          gap: 2,
        }}
      >
        <CheckCircleIcon sx={{ color: 'var(--clr-green)' }} />
        <Text text="This document has been approved successfully" fs={11} />
      </Box>
    );
  } else if (data?.status === 3) {
    statusHeaderContent = (
      <Box
        sx={{
          backgroundColor: 'var(--clr-lightRed)',
          color: 'var(--clr-red)',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CancelIcon sx={{ color: 'var(--clr-red)' }} />
          <Text
            text={`This document has been rejected: ${
              data?.rejection_reasons[0]?.[0] ||
              data?.rejection_reasons[0]?.[1] ||
              '-'
            }...`}
            fs={11}
          />
        </Box>
      </Box>
    );
  }

  const fileSkeleton = (
    <Skeleton
      sx={{ height: 250, width: '60%', mx: 'auto' }}
      animation="wave"
      variant="rectangular"
    />
  );

  return (
    <>
      <ViewDocument
        openModal={openModal}
        onCloseModal={handleCloseModal}
        filePath={data?.document_file ?? ''}
        file={documentFile ?? ''}
        skeleton={fileSkeleton}
      />
      <div className={styles.details}>
        {statusHeaderContent}
        <header>
          <Text text="Document Details" />
        </header>
        {isFetchingDocument && (
          <Grid container rowSpacing={4} sx={{ p: 5 }}>
            <>
              <Grid item xs={12}>
                {fileSkeleton}
              </Grid>
              {[1, 2, 3].map((val) => (
                <Fragment key={val}>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={200} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={200} />
                  </Grid>
                </Fragment>
              ))}
            </>
          </Grid>
        )}

        {!isFetchingDocument && (
          <div className={styles.content}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 300,
                overflow: 'hidden',
              }}
            >
              {data?.document_file && !documentFile && fileSkeleton}

              {!data?.document_file && (
                <Text text="No document file available" sx={{ my: 'auto' }} />
              )}

              {data?.document_file && documentFile && (
                <>
                  {!data?.document_file?.includes('.pdf') && (
                    <Box
                      onClick={handleOpenModal}
                      sx={{ cursor: 'pointer', width: '50%' }}
                    >
                      <img
                        src={documentFile}
                        alt="Document file"
                        height="auto"
                        width="100%"
                      />
                    </Box>
                  )}

                  {data?.document_file?.includes('.pdf') && (
                    <Box onClick={handleOpenModal} sx={{ cursor: 'pointer' }}>
                      <Document
                        file={documentFile}
                        loading={fileSkeleton}
                        error={
                          <Text
                            text="Something went wrong"
                            sx={{ my: 'auto' }}
                          />
                        }
                      >
                        <Page
                          pageNumber={1}
                          height={600}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          loading={fileSkeleton}
                        />
                      </Document>
                    </Box>
                  )}
                </>
              )}
            </Box>

            <section>
              <Divider variant="middle" />
            </section>

            <section>
              <div>
                <Text text="Document" fs={11} className={styles.key} />
                <Text
                  text={data?.document_label ?? `-`}
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="Status" fs={11} className={styles.key} />
                <Text
                  text={
                    data?.status === 0
                      ? LegalDocStatus[data?.status].split(' ')[0] + ' Approval'
                      : LegalDocStatus[
                          data?.status as keyof typeof LegalDocStatus
                        ]
                  }
                  className={
                    statusClasses[data?.status as keyof typeof statusClasses] ??
                    statusClasses.default
                  }
                  sx={{ py: 0.2, ml: -0.5 }}
                  fs={10}
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Expire At" fs={11} className={styles.key} />
                <Text
                  text={
                    data?.expiry_at
                      ? format(
                          parseISO(data.expiry_at),
                          'MMM dd, yyyy, hh:mm a'
                        )
                      : `-`
                  }
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="Updated At" fs={11} className={styles.key} />
                <Text
                  text={
                    data?.updated_at
                      ? format(
                          parseISO(data.updated_at),
                          'MMM dd, yyyy, hh:mm a'
                        )
                      : `-`
                  }
                  fs={11}
                  className={styles.value}
                />
              </div>
            </section>

            <section>
              <div>
                <Text text="Uploaded By" fs={11} className={styles.key} />
                <Text
                  text={data?.uploaded_by ?? `-`}
                  fs={11}
                  className={styles.value}
                />
              </div>
              <div>
                <Text text="Reviewed By" fs={11} className={styles.key} />
                <Text
                  text={data?.reviewed_by ?? `-`}
                  fs={11}
                  className={styles.value}
                />
              </div>
            </section>

            <section style={{ alignItems: 'flex-start' }}>
              <div>
                <Text
                  text={`${
                    data?.status === 2 ? 'Previous Rejected' : 'Rejection'
                  } Reasons`}
                  fs={11}
                  className={styles.key}
                />
                {data?.rejection_reasons?.map((reason, idx) => (
                  <Text
                    key={idx}
                    text={reason?.[0] || reason?.[1] || `-`}
                    fs={11}
                    className={styles.value}
                  />
                ))}
              </div>
              <div>
                <Text
                  text={`${
                    data?.status === 2 ? 'Previous Rejected' : 'Rejection'
                  } Comment`}
                  fs={11}
                  className={styles.key}
                />
                <Text
                  sx={{
                    width: '100%',
                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  text={data?.comment || `-`}
                  fs={11}
                  className={styles.value}
                />
              </div>
            </section>
            {isGeneratedContract && detailsContent}

            {!!data?.details?.length && !isGeneratedContract && (
              <Box sx={{ '& header': { paddingBlock: '10px !important' } }}>
                <ExtraDetails
                  extraDetails={data?.details}
                  isEdit={isEditExtraDetails}
                  setIsEdit={setIsEditExtraDetails}
                  merchantId={merchantId!}
                  documentId={id!}
                />
              </Box>
            )}

            {!isEditExtraDetails && (
              <Fragment>
                <section>
                  <Divider variant="middle" />
                </section>
                {actionsContent}
              </Fragment>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MerchantDocsDetails;
