import axios from 'axios';
import { memoizedRefreshToken } from 'utils/refreshToken';
import { showToastError } from 'utils/showToastError';
import { memoizedVerifyToken } from 'utils/verifyToken';

export const DomainUrl = 'https://salesapp.paymobsolutions.com';

export const publicURL = `${DomainUrl}/api/v1.0/internal/staff_users`;
export const privateURL = `${DomainUrl}/api/v1.0/crm`;
export const DocumentViewURL = `${privateURL}/document`;

export const axiosPublic = axios.create({ baseURL: publicURL });
export const axiosPrivate = axios.create({ baseURL: privateURL });

axiosPrivate.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    const lastRefreshToken = parseInt(
      sessionStorage.getItem('lastRefreshToken') ?? '0'
    );

    if (
      !lastRefreshToken ||
      new Date().getTime() - lastRefreshToken > 10 * 60 * 1000
    ) {
      try {
        if (token) await memoizedVerifyToken(token);
      } catch (error) {
        const accessToken = await memoizedRefreshToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => {
    if (response.config.url?.includes('export'))
      return JSON.stringify(response?.data);

    return response?.data;
  },
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 429) {
      showToastError({ message: error.response.data?.message });
    }

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      const accessToken = await memoizedRefreshToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
      return axiosPrivate(config);
    } else if (error?.response?.status === 401 && config?.sent) {
      sessionStorage.clear();
      window.location.replace('/auth/login');
    }

    return Promise.reject(error);
  }
);
