/**
 * Removes empty fields from an object, including nested objects recursively.
 *
 * @param {Object} obj - The object to remove empty fields from.
 * @returns {Object} - A new object with empty fields removed.
 */
export const removeEmptyFields = (obj: any): typeof obj => {
  if (Array.isArray(obj)) {
    return obj
      .filter(
        (v) =>
          v &&
          !(Array.isArray(v) && v.length === 0) &&
          !(v.constructor === Object && Object.keys(v).length === 0)
      )
      .map((v) => (typeof v === 'object' ? removeEmptyFields(v) : v));
  }

  obj = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      const isEmptyArray = Array.isArray(v) && v.length === 0;
      const isEmptyObject =
        typeof v === 'object' &&
        !Array.isArray(v) &&
        Object.keys(v!).length === 0;
      return v && !isEmptyArray && !isEmptyObject;
    })
  );

  Object.entries(obj).forEach(([k, v]) => {
    if (v === Object(v)) {
      obj[k] = removeEmptyFields(v);
    }
  });

  return Object.keys(obj).length === 0 || obj === null ? undefined : obj;
};
