import { Box, SxProps, Theme } from '@mui/material';
import Button from 'components/button/Button';
import { useDocumentStamp } from './useDocumentStamp';
import { useParams } from 'react-router-dom';
import { useDocuments } from '../merchantDocs/useDocuments';
import { useBusinessOwners } from '../businessOwnersInfo/useBusinessOwner';

interface GenerateDocumentBtnProps {
  sx?: SxProps<Theme>;
}
const GenerateDocumentBtn = ({ sx }: GenerateDocumentBtnProps) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { data: merchantDocs } = useDocuments(merchantId);
  const { data: businessOwnersDocs } = useBusinessOwners({ merchantId });
  const { mutate, isLoading: isStamping } = useDocumentStamp(merchantId!);
  const isDocumentsAvailable =
    (businessOwnersDocs?.results &&
      businessOwnersDocs.results.some(
        (document) => !!document?.signatory_national_id
      )) ||
    (merchantDocs?.results &&
      merchantDocs.results.some(
        (document) =>
          !!document?.document_file &&
          document?.document_label !== 'Generated Contract'
      ));

  const tooltipMsg = isStamping
    ? 'Loading documents...'
    : !isDocumentsAvailable
    ? 'No documents available'
    : undefined;

  const handelGenerateDocument = () => {
    mutate(merchantId!);
  };
  return (
    <Box sx={sx}>
      <Button
        text="Generate Document"
        disabled={isStamping || !isDocumentsAvailable}
        title={tooltipMsg}
        onClick={handelGenerateDocument}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};
export default GenerateDocumentBtn;
