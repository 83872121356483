import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Services } from 'apis/services/Services';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import axios from 'axios';
import { showToastError } from 'utils/showToastError';

export type UpdateLegalDocRequestType = { [x: string]: File };

export function useUpdateLegalDoc(merchantId: string, legalId: number) {
  const queryClient = useQueryClient();

  const { mutate: updateLegalDoc, isLoading } = useMutation<
    unknown,
    Error,
    UpdateLegalDocRequestType
  >({
    mutationFn: (doc) =>
      Services.patchForm<UpdateLegalDocRequestType, unknown>(
        doc,
        `merchants/${merchantId}/legal/${legalId}/`,
        true
      ),
    onSuccess: (data) => {
      toast.success('File has been uploaded');
      queryClient.invalidateQueries({
        queryKey: merchantKeys.legal('' + merchantId),
      });
    },
    onError: (err) => {
      const message = axios.isAxiosError(err)
        ? err?.response?.data
        : { message: err.message };
      showToastError(message);
    },
  });

  return { isLoading, updateLegalDoc };
}
