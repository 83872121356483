import { useQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';
type ServicesType = {
  results: SingleService[];
};
type SingleService = {
  gateway_type: string;
  is_live: boolean;
};
export function useServices(merchantId: string) {
  const query = useQuery({
    queryKey: merchantKeys.services(merchantId),
    queryFn: () =>
      Services.get<ServicesType>(`merchants/${merchantId}/services/`, true),
  });

  return { ...query, data: query?.data?.results! };
}
