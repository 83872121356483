import { useInfiniteQuery } from '@tanstack/react-query';
import { merchantKeys } from 'apis/react-query/queryKeyFactory';
import { Services } from 'apis/services/Services';

type MainResponse<T> = {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
};

export type GeneralType = {
  id: number;
  title: string;
  agent: string;
  comment: string;
  changelog: string[];
  tags: string[];
  created_at: string;
};

export interface IUseGeneral {
  merchantId?: string;
  searchText: string;
  date: { from?: string; to?: string };
  sort: string;
  selectedKey: string;
}

export type SMSReportType = {
  id: number;
  agent_name: string;
  caller_phone_number: string;
  message_content: string;
  date_time: string;
  call_recording_link: string;
};

export interface IUseSMSReport {
  merchantId?: string;
  searchText: string;
  selectedKey: string;
  date: { from?: string; to?: string };
}

export type CallsReportType = {
  call_recording_link: string;
  caller_phone_number: string;
  date_time: string;
  agent_name: string;
  queue: string;
  call_direction: string;
  governorate: string;
  customer_type: string;
  call_category: string;
  tag: string;
  sub_tag: string;
  comment: string;
  conversation_url: string;
  record_status: string;
  wrapup_code: string;
  merchant_id: string;
};

export interface IUseCallsReport {
  merchantId?: string;
  searchText: string;
  selectedKey: string;
  date: { from?: string; to?: string };
}

export type CallCenterNPSType = {
  call_recording_link: string;
  customer_mobile: string;
  agent_name: string;
  queue_name: string;
  csat: number;
  nps: number;
  government: string;
  customer_type: string;
  category: string;
  tag: string;
  sub_tag: string;
  comment: string;
  conversation_start_date: string;
  survey_date: string;
  conversation_url: string;
  record_status: string;
  wrapup_code: string;
  merchant_id: string;
  communication_direction: string;
  customer_industry: string;
};

export interface IUseCallCenterNPS {
  merchantId?: string;
  searchText: string;
  selectedKey: string;
  date: { from?: string; to?: string };
}

export type VisitNPSType = {
  visit_pk: number;
  visit_id: number;
  created_at: string;
  updated_at: string;
  visit_reason: string;
  visit_type: string;
  agent_id: string;
  terminal_id: string;
  latitude: string;
  longitude: string;
  csat: number;
  nps: number;
  merchant: string;
};

export interface IUseVisitNPS {
  merchantId?: string;
  searchText: string;
  selectedKey: string;
  date: { from?: string; to?: string };
}

export function useGeneral(args: IUseGeneral) {
  const { merchantId, searchText, date, sort, selectedKey } = args;

  const dateFormat = date?.from
    ? `created_at_after=${date?.from}&created_at_before=${date?.to}`
    : '';
  const searchKey = selectedKey === '' ? 'id' : selectedKey;

  return useInfiniteQuery({
    queryKey: merchantKeys.activities(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<MainResponse<GeneralType>>(
        `merchants/${merchantId}/timeline/?page=${pageParam}&${searchKey}=${searchText}&${dateFormat}${sort}`,
        true
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
  });
}

export function useSMSReport(args: IUseSMSReport) {
  const { merchantId, searchText, selectedKey, date } = args;

  const dateFormat = date?.from
    ? `&date_time_after=${date?.from}&date_time_before=${date?.to}`
    : '';
  const searchBy = searchText ? `&${selectedKey}=${searchText}` : '';

  return useInfiniteQuery({
    queryKey: merchantKeys.smsReport(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<MainResponse<SMSReportType>>(
        `merchants/${merchantId}/genesys/sms_reports/?page=${pageParam}${searchBy}${dateFormat}`
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
  });
}

export function useCallsReport(args: IUseCallsReport) {
  const { merchantId, searchText, selectedKey, date } = args;

  const dateFormat = date?.from
    ? `&date_time_after=${date?.from}&date_time_before=${date?.to}`
    : '';
  const searchBy = searchText ? `&${selectedKey}=${searchText}` : '';

  return useInfiniteQuery({
    queryKey: merchantKeys.callsReport(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<MainResponse<CallsReportType>>(
        `merchants/${merchantId}/genesys/call-reports/?page=${pageParam}${searchBy}${dateFormat}`
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
  });
}

export function useCallCenterNPS(args: IUseCallCenterNPS) {
  const { merchantId, searchText, selectedKey, date } = args;

  const dateFormat = date?.from
    ? `&survey_date_after=${date?.from}&survey_date_before=${date?.to}`
    : '';
  const searchBy = searchText ? `&${selectedKey}=${searchText}` : '';

  return useInfiniteQuery({
    queryKey: merchantKeys.callCenterNPS(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<MainResponse<CallCenterNPSType>>(
        `merchants/${merchantId}/genesys/call-center-nps/?page=${pageParam}${searchBy}${dateFormat}`
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
  });
}

export function useVisitNPS(args: IUseVisitNPS) {
  const { merchantId, searchText, selectedKey, date } = args;

  const dateFormat = date?.from
    ? `&date_time_after=${date?.from}&date_time_before=${date?.to}`
    : '';
  const searchBy = searchText ? `&${selectedKey}=${searchText}` : '';

  return useInfiniteQuery({
    queryKey: merchantKeys.visitNPS(args),
    queryFn: ({ pageParam = 1 }) =>
      Services.get<MainResponse<VisitNPSType>>(
        `merchants/${merchantId}/genesys/visit-nps/?page=${pageParam}${searchBy}${dateFormat}`
      ),
    getNextPageParam: (lastPage) => {
      const pageNumber = lastPage?.next?.match(/page=(\d+)/)?.[1];
      return pageNumber ?? undefined;
    },
  });
}
