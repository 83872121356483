import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box, Grid } from '@mui/material';

import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { useBankList, useBanksInfo, useEditBankInfo } from './useLegalBanks';
import { useLegal } from 'pages/main/merchants/marchent/overview/useLegal';
import Select from 'components/input/Select';
import Text from 'components/text';

const invalidCharacterCheck = (value: string) => /^[\w\-\s]+$/gi.test(value);
const invalidCharacterCheckMsg = 'Special characters are not allowed';

const bankFormSchemaValidation = z.object({
  company_bank_name: z
    .string()
    .max(50, 'Maximum 50 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Bank name is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg)
    .optional()
    .or(z.literal('')),
  company_bank_name_2: z.string().min(1, { message: 'Required' }),
  company_bank_branch: z
    .string()
    .max(50, 'Maximum 50 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Bank branch is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg)
    .optional()
    .or(z.literal('')),
  company_bank_account_name: z
    .string()
    .max(70, 'Maximum 70 characters are allowed')
    .refine((value) => value.trim().length > 1, 'Account name is required')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg),
  company_bank_account_number: z
    .string()
    .max(20, 'Maximum 20 characters are allowed')
    .refine((value) => value.trim().length, 'Account number is required')
    .refine((value) => !!Number(value), 'Please, enter a valid account number'),
  company_bank_swift_code: z
    .string()
    .max(11, 'Maximum 11 characters are allowed')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg)
    .optional()
    .or(z.literal('')),
  iban: z
    .string()
    .max(34, 'Maximum 34 characters are allowed')
    .refine(invalidCharacterCheck, invalidCharacterCheckMsg)
    .optional()
    .or(z.literal('')),
});

export type BankFormSchemaFormType = z.infer<typeof bankFormSchemaValidation>;

interface IBankForm {
  onSubmit: () => void;
}

const BankForm = ({ onSubmit }: IBankForm) => {
  const { merchantId } = useParams();
  const { data: legalDocs } = useLegal(merchantId!);
  const { data: bankData } = useBanksInfo({
    merchantId,
    legalDocsId: legalDocs?.[0]?.id,
  });
  const { data: bankList = [] } = useBankList({ merchantId });
  const { mutate: editBankInfo, isLoading: editingBankInfo } = useEditBankInfo({
    merchantId,
    legalDocsId: legalDocs?.[0]?.id,
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<BankFormSchemaFormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(bankFormSchemaValidation),
    defaultValues: {
      company_bank_name: bankData?.company_bank_name ?? '',
      company_bank_name_2: bankData?.company_bank_name_2 ?? '',
      company_bank_branch: bankData?.company_bank_branch ?? '',
      company_bank_account_name: bankData?.company_bank_account_name ?? '',
      company_bank_account_number: bankData?.company_bank_account_number ?? '',
      company_bank_swift_code: bankData?.company_bank_swift_code ?? '',
      iban: bankData?.iban ?? '',
    },
  });

  const handleForm: SubmitHandler<BankFormSchemaFormType> = (values) => {
    editBankInfo(values, {
      onSuccess: () => {
        reset();
        onSubmit();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={4}
        alignItems="center"
        sx={{ py: 4, px: 5, '& .MuiFormControl-root': { width: '100%' } }}
      >
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Company Bank Name" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_name_2"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                label="Select Company Bank Name 2"
                data={bankList}
                placeholder="type"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_branch"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Company Bank Branch" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_account_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="Company Bank Account Name"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_account_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="Company Bank Account Number"
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="company_bank_swift_code"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="Company Bank Swift Code" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="iban"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                label={
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Text text="IBAN" fs={10} />
                    <Text
                      text="(optional)"
                      fs={10}
                      sx={{ color: 'var(--clr-gray)' }}
                    />
                  </Box>
                }
                have_error={error?.message ?? ''}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
          <Button text="Save Changes" disabled={editingBankInfo || !isValid} />
        </Grid>
      </Grid>
    </form>
  );
};

export default BankForm;
