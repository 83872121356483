import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/accordion';
import Text from 'components/text';
import { useBusinessOwners } from './useBusinessOwner';
import LegalDocStatus from 'constants/LegalDocStatus';
import { statusClasses } from './details';

const BusinessOwnersInfoKey = 'BusinessOwnersInfo';

interface IBusinessOwnersInfoProps {
  expanded: string | false;
  onExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  onSelectedContent: (content: { key: string; id?: string }) => void;
  selectedContent: { key: string; id?: string };
}

const BusinessOwnersInfo = ({
  expanded,
  onExpanded,
  onSelectedContent,
  selectedContent,
}: IBusinessOwnersInfoProps) => {
  const { merchantId } = useParams();
  const { data, isFetching } = useBusinessOwners({ merchantId });
  const isExpanded = expanded === BusinessOwnersInfoKey;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={onExpanded(BusinessOwnersInfoKey)}
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
    >
      <AccordionSummary
        aria-controls={`BusinessOwnersInfo-content`}
        id={`BusinessOwnersInfo-header`}
        sx={{ py: 0.5, px: 4, backgroundColor: isExpanded ? '#fff' : '' }}
      >
        <Text text="Business Owners Info" />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, maxHeight: 180, overflow: 'auto' }}>
        {!isFetching &&
          data?.results?.map((owner) => (
            <Box
              key={owner.id}
              onClick={() =>
                onSelectedContent({
                  key: BusinessOwnersInfoKey,
                  id: owner.id + '',
                })
              }
              sx={{
                p: 2,
                px: 5,
                '&:hover': {
                  backgroundColor: '#FAFAFA',
                  cursor: 'pointer',
                },
                backgroundColor:
                  selectedContent.key === BusinessOwnersInfoKey &&
                  selectedContent.id === owner.id + ''
                    ? '#F4F9FD'
                    : '',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {owner?.signatory_name || owner?.signatory_name_english ? (
                <Text
                  sx={{ textTransform: 'capitalize' }}
                  text={owner.signatory_name || owner.signatory_name_english}
                  fs={11}
                />
              ) : (
                <Text text="Name not available" fs={11} />
              )}

              <Text
                text={
                  owner?.status === 0
                    ? LegalDocStatus[owner?.status].split(' ')[0] + ' Approval'
                    : LegalDocStatus[
                        owner?.status as keyof typeof LegalDocStatus
                      ]
                }
                fs={8}
                className={
                  statusClasses[owner?.status as keyof typeof statusClasses] ||
                  statusClasses.default
                }
              />
            </Box>
          ))}
        {!isFetching && !data?.results?.length && (
          <Box sx={{ p: 2, px: 5 }}>
            <Text text="No business owners available" fs={11} />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default BusinessOwnersInfo;
