import { Box, Divider, Grid } from '@mui/material';
import FormBuilder from 'components/form-builder/formBuilder';
import Text from 'components/text';
import styles from 'styles/main/merchants/merchant/index.module.scss';
import Button from 'components/button/Button';
import { Dispatch, SetStateAction } from 'react';
import { format, parseISO } from 'date-fns';
import { Detail, useUpdateDocument } from '../useDocuments';

interface ExtraDetailsProps {
  extraDetails: Detail[];
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  merchantId: string;
  documentId: string;
}

const ExtraDetails = ({
  extraDetails,
  isEdit,
  setIsEdit,
  documentId,
  merchantId,
}: ExtraDetailsProps) => {
  const handelValue = (item: Detail): string => {
    if (item?.detail_type === 'datetime')
      return format(parseISO(item?.value as string), 'dd MMM yyyy');
    return (item?.value as string) || '-';
  };

  const { mutate, isLoading } = useUpdateDocument({ documentId, merchantId });

  const handelUpdateDetails = (values: { [key: string]: string }) => {
    const data = extraDetails?.map((item) => ({
      key: item.key,
      value: values[item.key],
      id: item.id,
    }));
    mutate(data, {
      onSuccess: () => {
        setIsEdit(false);
      },
    });
  };

  return (
    <Box>
      <header>
        <Text text="Extra Details" />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            text={isEdit ? 'View Details' : 'Edit'}
            onClick={() => setIsEdit(!isEdit)}
          />
        </Box>
      </header>
      <Box sx={{ px: 1.5, pt: 3 }}>
        {isEdit ? (
          <FormBuilder
            formData={extraDetails}
            onSubmit={handelUpdateDetails}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 2,
            }}
            submitText="Update"
            disableSubmit={isLoading}
          />
        ) : (
          <Grid container>
            {extraDetails?.map((item) => (
              <Grid key={item.id} item xs={12} md={6}>
                <Text text={item.label ?? ''} fs={11} className={styles.key} />
                <Text
                  text={handelValue(item)}
                  fs={11}
                  className={styles.value}
                  sx={{ wordBreak: 'break-word' }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ExtraDetails;
