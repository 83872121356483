import { axiosPublic } from 'apis/AxiosConfig';
import pMemoize from 'p-memoize';
import ExpiryMap from 'expiry-map';

const verifyToken = async (token: string) => {
  const accessTokenObj = { token };
  await axiosPublic.post('/token/verify/', accessTokenObj);
};

const cache = new ExpiryMap(10000);
export const memoizedVerifyToken = pMemoize(verifyToken, { cache });
