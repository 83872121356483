import { useForm } from 'react-hook-form';

import Select from 'components/input/Select';
import '../MerchantDrawer.scss';
import { useUpdateAccountManager } from './useUpdateAccountManager';
import { useAccountManagers } from './useAccountManagers';
import Button from 'components/button/Button';
import { MerchantDetailsType } from 'pages/main/merchants/marchent/useMerchant';
interface AccountManagerFormType {
  merchantDetails: MerchantDetailsType;
}
function AccountManagerForm({ merchantDetails }: AccountManagerFormType) {
  const { data: account_managers = [] } = useAccountManagers();
  const { mutate: updateAccountManager } = useUpdateAccountManager();

  const accountManager = account_managers?.find(
    (item) => item.value === merchantDetails?.account_manager
  )?.label;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      account_manager: accountManager ?? '',
    },
  });

  const handleForm = (values: { account_manager: string }) => {
    const { account_manager } = values;
    let data = { account_manager };
    updateAccountManager({ data, merchantId: merchantDetails?.id! });
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <div className="row">
        <div>
          <Select
            {...register('account_manager')}
            label="Account manager"
            data={account_managers}
            placeholder="Account manager"
          />
        </div>
      </div>
      <div className="row submitBtn">
        <Button text="Save" />
      </div>
    </form>
  );
}

export default AccountManagerForm;
